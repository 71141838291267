import * as Utils from '../utils.js';
import * as units from "../units.js";
export default class FanSensor{

     constructor(name,position,direction,parent,rayLength,scene,candle,isvisible,root)
     {
        this.name = name;
        this.position = position;
        this.direction = direction;
        this.parent = parent;
        this.rayLength = rayLength;
        this.scene = scene;
        this.rayHelper = null;
        this.ray=null;
        this.candle=candle;
        this.rayvisible = isvisible;
        this.initSensor();
        this.root = root;
        scene.registerBeforeRender(()=> {

            let fanvalue = false;
            if(this.rayHelper !== null && this.rayvisible)
                this.rayHelper.hide(); 
            if(this.root && this.root.fanComponent){
                fanvalue = this.root.fanComponent.isFanOn;
                // console.log(this.root.fanComponent.isFanOn+"      "+fanvalue);
                if(fanvalue)    
                    this.castRay();
            }
            else
                fanvalue = false;
            // if(document.getElementById("fanAnim") !== null)
            //     fanvalue = document.getElementById("fanAnim").checked;
        });
     }

     setCandleForSensor(candle)
     {
        this.candle = candle;
     }
     vecToLocal(vector, mesh){
        var m = mesh.getWorldMatrix();
        var v = BABYLON.Vector3.TransformCoordinates(vector, m);
		return v;		 
    }
     initSensor()
     {
        this.sensorBox = BABYLON.MeshBuilder.CreateBox(`DS_${this.name}`,{ size: 1 },this.scene);
        this.sensorBox.isPickable = false;
        this.sensorBox.visibility = 0;
        this.sensorBox.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
        this.sensorBox.position = this.position;
        this.sensorBox.parent = this.parent;
     }
     castRay(){       
        let origin = this.sensorBox.getAbsolutePosition();
	    let forward = this.direction// new BABYLON.Vector3(1,0,0);		
	    forward = this.vecToLocal(forward, this.sensorBox);
	    let direction = forward.subtract(origin);
	    direction = BABYLON.Vector3.Normalize(direction);
	    this.ray   = new BABYLON.Ray(origin, direction, this.rayLength);
        if(this.rayvisible)
            this.rayHelper = BABYLON.RayHelper.CreateAndShow(this.ray,this.scene, new BABYLON.Color3(1,.5,1))//new BABYLON.RayHelper(this.ray);		
          else
          {  
            if(this.rayHelper !== null)   
                this.rayHelper.hide(); 
          }
        let hit = this.scene.pickWithRay(this.ray,this.predicate);
        if(hit.pickedMesh && this.root.fanComponent.isFanOn){
                  
            if(hit.pickedMesh.name.includes("candle") ||  hit.pickedMesh.tag === Utils.EffectsTags.flame)
            {
                for(let i=0;i<this.candle.length;i++)
                {
                     if(this.candle[i].cylinderHolder.name === hit.pickedMesh.name)
                     {
                         if(this.candle[i].flame.isEnabled())
                         {
                            let dis =  BABYLON.Vector3.Distance(this.parent.absolutePosition,this.candle[i].cylinderHolder.position);    
                            this.candle[i].flame.setEnabled(false);
                            this.candle[i].flameWrrpaer.tag = "candleoff"; 
                            this.candle[i].flameWrrpaer.setEnabled(false);
                            let iscollide=false;
                            if (this.candle[i].cylinderHolder.intersectsMesh(this.parent, true)) {
                                iscollide = true;
                              } else {
                                iscollide = false;
                              }
                            let candleblowoffEvent = new CustomEvent(Utils.events.candleBlowOff,{detail:{id:this.candle[i].name,position:this.candle[i].cylinderHolder.position,candleFlame:false,
                                distance:Number(units.getValueByUnitType(dis,units.UnitTypes.mm)),collide:iscollide}});
                            document.dispatchEvent(candleblowoffEvent);
                            break;
                         }
                     }
                }
                // console.log(hit.pickedMesh.name);
            }
	    }
    }
    predicate(mesh){
        if(mesh.name.includes("candle") || mesh.name.includes("obstacle")||  mesh.tag === Utils.EffectsTags.flame)
        {
            return true;
        }
        return false;
    }
}