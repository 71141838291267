export default class SceneManager{
    constructor(){
          this.sceneState        = {firefighting:1,maze:2,demo:3};                          
          this.currentSceneState = 0;
          window["setSceneState"] = this.setSceneState.bind(this);
    }
    setSceneState(scenestate)
    {
         this.currentSceneState = scenestate;
    }
}