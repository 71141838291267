import * as BABYLON from "babylonjs";
import * as units from "../units.js";
import { LightComponent } from "../Components/LightComponent.js";

// @name ---- string
// @position ---- vector3
// @direction ---- vector3
// @parent ---- Mesh
// @rayLength ---- number
// @samplingRateMs ---- number
// @isVisibleRay ---- boolean
// @onFire --- (result:{})=> void
// @scene ---- active scene

export default class DistanceSensorGenerator2 {
  constructor(
    name,
    position,
    direction,
    parent,
    rayLength,
    samplingRateMs,
    isVisibleRay,
    onFire,
    scene,
  ) {
    this.name      = name;
    this.position  = position;
    this.direction = direction;
    this.noRays    = 3;
    this.parent = parent;
    this.rayLength = rayLength;
    this.samplingRateMs = samplingRateMs;
    this.isVisibleRay = isVisibleRay;
    this.onFire = onFire;
    this.scene = scene;
    this.sensorValue={};
    
    this.lightIndicator = new LightComponent(name,{diameter:.08},new BABYLON.Color3(1,0,0),
                          new BABYLON.Vector3(position.x-.22,position.y,position.z-.02),this.parent,this.scene);
     const dir = [-.02,0,.02];
     this.raydir=[];
     this.rayHelper=[];
     this.ray=[];
     this.sensordata = {data:[]};

     for(let i=0;i<this.noRays;i++)
     {
        this.raydir[i] = new BABYLON.Vector3(this.direction.x,dir[i],0);  
        this.sensordata.data.push({
            name     : undefined,
            distance : undefined,
            state    : 0,
        })                        
     }

     

     
     this.init();
  }

  init() {
    this.sensorBox = BABYLON.MeshBuilder.CreateBox( `DS_${this.name}`,{ size: 1 },this.scene);
    this.sensorBox.visibility = 0.0;
    this.sensorBox.isPickable = false;
    this.sensorBox.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
    this.sensorBox.position = this.position;
    BABYLON.SceneLoader.ImportMesh("","/models/sensors/","Ultrasonic_1.gltf",this.scene,(meshes, particleSystems, skeletons) => {
        let rootMesh = null;
        for (let i = 0; i < meshes.length; i++) {
          meshes[i].isPickable = false;
          if (meshes[i].name === "__root__") 
          rootMesh = meshes[i];
        }
        rootMesh.parent = this.sensorBox;
        rootMesh.position.x -= 0.5;
        rootMesh.rotation = new BABYLON.Vector3(0,1.57,4.71);
        rootMesh.scaling.multiplyInPlace(new BABYLON.Vector3(2, 2, 2));
      }
    );
    this.rayCount = 0;
    this.sensorBox.parent = this.parent;
    this.scene.registerBeforeRender(()=> {
      this.callRayCasting();
    });
    
  }
  vecToLocal(vector, mesh) {
    var m = mesh.getWorldMatrix();
    var v = BABYLON.Vector3.TransformCoordinates(vector, m);
    return v;
  }
  callRayCasting() {

      // for(let i=0;i<this.noRays;i++)
      // {
      //   if(this.rayHelper[i] !== undefined && this.isVisibleRay)
      //             this.rayHelper[i].hide();
      // }
       for(let i=0;i<this.noRays;i++)
       {
              let origin  = this.sensorBox.getAbsolutePosition();
              let forward = this.raydir[i];
              forward     = this.vecToLocal(forward, this.sensorBox);
              let direction = forward.subtract(origin);
              direction = BABYLON.Vector3.Normalize(direction);
            if(this.rayHelper[i] !== undefined && this.isVisibleRay)
                this.rayHelper[i].hide();

            this.ray[i]  = new BABYLON.Ray(origin, direction, this.rayLength);
            let distanceHit = this.scene.pickWithRay(this.ray[i]);
            resultObj.state =  `Activated (${++this.rayCount})`;
            
              if(this.isVisibleRay)
                  this.rayHelper[i] = BABYLON.RayHelper.CreateAndShow(this.ray[i],this.scene, new BABYLON.Color3(1,1,0))//new BABYLON.RayHelper(this.ray);		
              else
              {
                  if(this.rayHelper[i] !== undefined)
                    this.rayHelper[i].hide();
              }
            if (distanceHit.pickedMesh){

              this.sensordata.data[i].name      =  distanceHit.pickedMesh.name;
              this.sensordata.data[i].distance  =  Number(units.getValueByUnitType(distanceHit.distance,units.UnitTypes.mm));
              this.sensordata.data[i].state     =  resultObj.state;
              
              // if (distanceHit.pickedMesh.material)
              //     distanceHit.pickedMesh.material.diffuseColor = BABYLON.Color3.Red();
            }
            else
            {
                this.sensordata.data[i].distance  = resultObj.direction = Infinity;
            }


        }

      this.onFire(resultObj);      
      this.getNearsetObject();
  }
  getNearsetObject()
  {
      
      this.sensordata.data.sort((a,b) =>
        a.distance - b.distance
      );
      this.sensorValue.name      =  resultObj.name      =  this.sensordata.data[0].name;
      this.sensorValue.distance  =  resultObj.direction =  this.sensordata.data[0].distance;
      this.sensorValue.state     =  resultObj.state;
      // console.log(this.sensordata.data);
   }
}

let resultObj = {
  name : undefined,
  state: `Activated (${0})`,
  direction:0,
};
