import * as BABYLON from "babylonjs";
import * as units from "../units.js";
import { LightComponent } from "../Components/LightComponent.js";

// @name ---- string
// @position ---- vector3
// @direction ---- vector3
// @parent ---- Mesh
// @rayLength ---- number
// @samplingRateMs ---- number
// @isVisibleRay ---- boolean
// @onFire --- (result:{})=> void
// @scene ---- active scene

export default class DistanceSensorGenerator {
  constructor(
    name,
    position,
    direction,
    parent,
    rayLength,
    samplingRateMs,
    isVisibleRay,
    onFire,
    scene,
  ) {
    this.name = name;
    this.position = position;
    this.direction = direction;
    this.parent = parent;
    this.rayLength = rayLength;
    this.samplingRateMs = samplingRateMs;
    this.isVisibleRay = isVisibleRay;
    this.onFire = onFire;
    this.scene = scene;
    this.sensorValue={};
    
    this.lightIndicator = new LightComponent(name,{diameter:.08},new BABYLON.Color3(1,0,0),
                          new BABYLON.Vector3(position.x-.22,position.y,position.z-.02),this.parent,this.scene);
    this.init();
  }

  init() {
    this.sensorBox = BABYLON.MeshBuilder.CreateBox( `DS_${this.name}`,{ size: 1 },this.scene);
    this.sensorBox.visibility = 0.0;
    this.sensorBox.isPickable = false;
    this.sensorBox.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
    this.sensorBox.position = this.position;
    BABYLON.SceneLoader.ImportMesh("","/models/sensors/","Ultrasonic_1.gltf",this.scene,(meshes, particleSystems, skeletons) => {
        let rootMesh = null;
        for (let i = 0; i < meshes.length; i++) {
          meshes[i].isPickable = false;
          if (meshes[i].name === "__root__") 
          rootMesh = meshes[i];
        }
        rootMesh.parent = this.sensorBox;
        rootMesh.position.x -= 0.5;
        rootMesh.rotation = new BABYLON.Vector3(0, 1.57, 4.71);
        rootMesh.scaling.multiplyInPlace(new BABYLON.Vector3(2, 2, 2));
      }
    );
    this.rayCount = 0;
    this.sensorBox.parent = this.parent;
    this.scene.registerBeforeRender(()=> {
      this.callRayCasting();
    });
    
  }
  vecToLocal(vector, mesh) {
    var m = mesh.getWorldMatrix();
    var v = BABYLON.Vector3.TransformCoordinates(vector, m);
    return v;
  }
  callRayCasting() {
    
        let origin  = this.sensorBox.getAbsolutePosition();
        let forward = this.direction;
        forward     = this.vecToLocal(forward, this.sensorBox);
        let direction = forward.subtract(origin);
        direction = BABYLON.Vector3.Normalize(direction);
      if(this.rayHelper!== undefined && this.isVisibleRay)
          this.rayHelper.hide();

      this.ray  = new BABYLON.Ray(origin, direction, this.rayLength);
      let distanceHit = this.scene.pickWithRay(this.ray);
      resultObj.state =  `Activated (${++this.rayCount})`;
      
        if(this.isVisibleRay)
             this.rayHelper = BABYLON.RayHelper.CreateAndShow(this.ray,this.scene, new BABYLON.Color3(1,1,0))//new BABYLON.RayHelper(this.ray);		
        else
        {
            if(this.rayHelper !== undefined)
              this.rayHelper.hide();
        }
      if (distanceHit.pickedMesh) {
        this.sensorValue.name      =  resultObj.name      =  distanceHit.pickedMesh.name;
        this.sensorValue.distance  =  resultObj.direction =  Number(units.getValueByUnitType(distanceHit.distance,units.UnitTypes.mm));
        this.sensorValue.state     =  resultObj.state;
        
        // if (distanceHit.pickedMesh.material)
        //     distanceHit.pickedMesh.material.diffuseColor = BABYLON.Color3.Red();
      }
      else
        resultObj.direction = Infinity;
        this.onFire(resultObj);      
        console.log(this.sensorValue);
  }
}
let resultObj = {
  name : undefined,
  state: `Activated (${0})`,
  direction:0,
};
