import * as Utils from "./utils.js";
export default class Common{

     constructor(game)
     {

        this.game          = game;
        this.scene         = null;
        this.arcCam        = null; 
        this.animFrameRate = 60;
        
     }
     createScene(name) {
        this.scene = new BABYLON.Scene(this.game.engine);
        this.scene.name = name;
        // this.scene.imageProcessingConfiguration.exposure = 0.6; //0.6;
        // this.scene.imageProcessingConfiguration.contrast = 1.8; //1.6;
        // this.scene.imageProcessingConfiguration.toneMappingEnabled = true;
        // this.scene.imageProcessingConfiguration.vignetteEnabled = true;
        this.scene.clearColor = BABYLON.Color3.FromHexString(Utils.backGroundColor);
    
        // this.scene.imageProcessingConfiguration.colorCurvesEnabled = true;
        // this.scene.imageProcessingConfiguration.colorCurves = new BABYLON.ColorCurves();
        // this.scene.imageProcessingConfiguration.colorCurves.globalSaturation = 0;
        this.setLight();
        return this.scene;
     }
     createCamera(scene) {
        this.arcCam = new BABYLON.ArcRotateCamera("ArcCamera",0,BABYLON.Angle.FromDegrees(45).radians(),18,new BABYLON.Vector3(0, 4, 0),scene);
        this.arcCam.maxZ = 1000;
        this.arcCam.useBouncingBehavior = false;
        this.arcCam.useAutoRotationBehavior = false;
        this.arcCam.inputs.clear();
        
        this.setView();
        
        return this.arcCam;
      }
      setLight() {
        this.hemiLight = new BABYLON.HemisphericLight("HemiLight",new BABYLON.Vector3(0,20,0),this.scene);
        this.hemiLight.intensity = 1;
        this.hemiLight.diffuse = new BABYLON.Color3(1, 1, 1);
        this.hemiLight.specular = new BABYLON.Color3(1, 1, 1);
        this.hemiLight.groundColor = new BABYLON.Color3(1, 1, 1);

        this.spotlight = new BABYLON.SpotLight("spotLight", new BABYLON.Vector3(0,0,1.5), new BABYLON.Vector3(0,0,-1), BABYLON.Angle.FromDegrees(360).radians(),1, this.scene);
        this.spotlight.diffuse = new BABYLON.Color3(1, 1, 0);
        this.spotlight.intensity = 20;
        this.spotlight.range = 2;
        
        // this.spotlight.renderPriority = 150;    
        this.spotlight.falloffType = BABYLON.Light.FALLOFF_STANDARD;
        
      //   let dirLight = new BABYLON.DirectionalLight("DirectionalLight",new BABYLON.Vector3(10,10,10),this.scene);
      //   dirLight.position = new BABYLON.Vector3(3, 9, 3);
      //   dirLight.intensity=2;
      }

      setView(){
            this.arcCam.lockedTarget = new BABYLON.Vector3(0,1,0);
            this.arcCam.lowerRadiusLimit = 3;
            this.arcCam.upperRadiusLimit = 40;
            this.arcCam.radius = this.arcCam.lowerRadiusLimit;
            this.arcCam.alpha           = BABYLON.Angle.FromDegrees(90).radians();
            this.arcCam.beta            = BABYLON.Angle.FromDegrees(0).radians();
            this.arcCam.lowerbetaLimit  = BABYLON.Angle.FromDegrees(45).radians();
            this.arcCam.upperBetaLimit  = BABYLON.Angle.FromDegrees(70).radians();
            this.arcCam.inputs.addPointers();  
            this.arcCam.inputs.addMouseWheel();  
            this.arcCam.wheelPrecision = 15;
            this.arcCam.attachControl(this.game.canvas, true);  
            
            let tout = setTimeout(() => {
              clearTimeout(tout);
              this.zoomCamAnim();
            }, 2000);
            
      } 
      initCamBetaAnim(){
            let animationBox = new BABYLON.Animation("betanimation", "beta",this.animFrameRate ,BABYLON.Animation.ANIMATIONTYPE_FLOAT,BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
            let keys = [];
            keys.push({
                frame: 0,
                value: 0
            });
            keys.push({
                frame: 80,
                value: BABYLON.Angle.FromDegrees(60).radians()
            });
            keys.push({
              frame: 120,
              value: BABYLON.Angle.FromDegrees(60).radians()
          });
            animationBox.setKeys(keys);
            this.arcCam.animations.push(animationBox);
            let tout =setTimeout(async () => {
              clearTimeout(tout);
              let anim = this.scene.beginAnimation(this.arcCam,0,120,false);
              // console.log("before");
              await anim.waitAsync();
              // console.log("after");
         });
      }
      zoomCamAnim(){

            if(this.arcCam){
              let animationBox2 = new BABYLON.Animation("radiusanimation", "radius",this.animFrameRate ,BABYLON.Animation.ANIMATIONTYPE_FLOAT,BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
              let keys2 = [];
              keys2.push({
                  frame: 0,
                  value:this.arcCam.lowerRadiusLimit,
              });
              keys2.push({
                  frame: 120,
                  value: 40
              });
              animationBox2.setKeys(keys2);
              this.arcCam.animations.push(animationBox2);
              let tout =setTimeout(async () => {
                clearTimeout(tout);
                let anim = this.scene.beginAnimation(this.arcCam,0,120,false);
                await anim.waitAsync();
                if(this.arcCam){
                  this.arcCam.animations.splice(keys2,1);
                  this.initCamBetaAnim();
                 }
              });
            }
      }
      release(){
         this.scene.stopAllAnimations();
         this.arcCam.dispose();
         this.spotlight.dispose();
         this.hemiLight.dispose();
      }

    


}