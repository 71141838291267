import * as BABYLON from "babylonjs";
import * as Utils from "../utils.js";
import * as CANNON from "cannon";
import {getRandomNumber} from "../Effects/FlameEffectGenerator.js";

/**
 * Generate Flame Effect in the scene
 * @param name : string  defines Obstacle name
 * @param position: vector2 defines the flameEffect position X AND Z
 * @param width: number  defines Obstacle width
 * @param obstacleAngle: number  defines Y axis angle in radians
 * @param obstacleSrc : string defines image path
 * @param worldManager : worldManager
 * @param scene : scene active scene
 */

export default class ObstacleGenerator {
  constructor(
    name,
    position,
    // width,
    size,
    obstacleAngle,
    obstacleSrc,
    worldManager,
    scene,
    boardEvent,
    bodyMat,
  ) {
    this.name = name;
    this.position = position;
    // this.width = width;
    this.size = size;    
    this.obstacleAngle = obstacleAngle;
    this.obstacleSrc = obstacleSrc;
    this.worldManager = worldManager;
    this.scene = scene;
    this.boardEvent = boardEvent;
    this.adPlane =null;
    this.woodPart;
    this.cubeBody;
    this.bodyMat = bodyMat;
    this.isCollide = false;
    // console.log("INNNNNNNNNN");
    this.init();
   
  }

  init() {
    // let height = this.width*1.6;
    // let depth  = this.width*.7;
    // let woodHeight = this.width*.5;
    // let adHeight = height - woodHeight;
    // console.log(this.size);
    // Step 1 -- Create Physics Cube
    let cubeSize  = new BABYLON.Vector3(this.size.width, this.size.height,this.size.depth);
    let cubeShape = new CANNON.Box(new CANNON.Vec3(cubeSize.x, cubeSize.y, cubeSize.z));
    this.cubeBody = new CANNON.Body({ mass:10, shape: cubeShape }); // Step 2 -- Build the body
    // this.cubeBody.material = this.bodyMat;
    this.cubeBody.linearDamping=.2;
    this.cubeBody.angularDamping=.2;
    this.cubeBody.angularVelocity.set(0,0,0);//.2
    this.cubeBody.addShape(cubeShape);
    this.cubeBody.position.set(this.position.x, cubeSize.y, this.position.y);
    this.cubeBody.quaternion.setFromEuler(0, this.obstacleAngle, 0, "XYZ");
    this.worldManager.world.add(this.cubeBody);
    this.cubeBody.addEventListener("collide", (e)=>{
      if(e.body.name === "robotbody"){
        // let  impulse = new CANNON.Vec3(getRandomNumber(this.cubeBody.mass*1.5,this.cubeBody.mass*1.8),this.cubeBody.mass*1.8,getRandomNumber(this.cubeBody.mass*1.5,this.cubeBody.mass*1.8));
        e.body.velocity = new CANNON.Vec3(Math.round(e.body.velocity.x),Math.round(e.body.velocity.y),Math.round(e.body.velocity.z));
        // let val = this.cubeBody.mass
        let val = Math.round(Math.sqrt(e.body.velocity.x * e.body.velocity.x + e.body.velocity.y * e.body.velocity.y+e.body.velocity.z * e.body.velocity.z));
        let impulse = new CANNON.Vec3(0,val*2,0);
        if(Math.abs(e.body.velocity.x)>0)
           impulse.x = e.body.velocity.x>0?val:-val;
        else{   
            let flag = parseInt(getRandomNumber(0,2));
            impulse.x =  val * (flag===0?(-getRandomNumber(1.1,1.3)):(getRandomNumber(1.1,1.3)));
        }

        if(Math.abs(e.body.velocity.z)>0)
           impulse.z = e.body.velocity.z>0?val:-val;
        else{   
            let flag = parseInt(getRandomNumber(0,2));
            impulse.z = val * (flag===0?(-getRandomNumber(1.1,1.3)):(getRandomNumber(1.1,1.3)));
        }
        this.cubeBody.applyImpulse(impulse,this.cubeBody.position);
        this.cubeBody.angularVelocity =  impulse.mult(.3,impulse);
        this.fireCollisionEvent();
      }
      if(e.body.name === "physicground"){
          if(Math.abs(this.cubeBody.quaternion.x!==0) || Math.abs(this.cubeBody.quaternion.z!==0)){
            let boardCollideEvent = new CustomEvent(Utils.events.boardDown,{detail:{name:this.name}});
            document.dispatchEvent(boardCollideEvent);
          }
      }
    });
    // Step 2 -- Create Virtual Cube

    const meshSize = { width:this.size.width*2, height:this.size.height*2, depth: this.size.depth*2.2};
    this.vCube = BABYLON.MeshBuilder.CreateBox(this.name,{ width:meshSize.width, height:meshSize.height, depth: meshSize.depth},this.scene);
    
    this.vCube.position =  this.cubeBody.position;//new BABYLON.Vector3.Zero();
    let boxMat = new BABYLON.StandardMaterial("boxMat", this.scene);
    this.vCube.material = boxMat;
    this.vCube.visibility = 0;
    this.worldManager.boxes.push({pCube: this.cubeBody,vCube: this.vCube});
    
    // //create wood cube
    this.woodPart = BABYLON.MeshBuilder.CreateBox(`${this.name}_wood`,{ width:meshSize.width, height:meshSize.height*.2, depth: meshSize.depth},this.scene);
    this.woodPart.parent = this.vCube;
    this.woodPart.position = new BABYLON.Vector3(0,-meshSize.height*.5,0);
    //create woodPart material
    let plateBoxMat = new BABYLON.StandardMaterial(`${this.name}woodMat`,this.scene);
    plateBoxMat.diffuseTexture = new BABYLON.Texture("/textures/wood.jpg",this.scene); 
    plateBoxMat.emissiveColor = new BABYLON.Color3.White();
    // //assgin material
    this.woodPart.material = plateBoxMat;


    // //create adPlane
    this.adPlane = BABYLON.MeshBuilder.CreatePlane(`${this.name}_AD`,{ width:meshSize.width, height:meshSize.height*.8,depth: meshSize.depth,sideOrientation: BABYLON.Mesh.DOUBLESIDE},this.scene);
    this.adPlane.parent = this.vCube;
    let adPlaneMat = new BABYLON.StandardMaterial(`${this.name}adPlaneMat`,this.scene);
    adPlaneMat.diffuseTexture = new BABYLON.Texture(this.obstacleSrc,this.scene); 
    adPlaneMat.emissiveColor = new BABYLON.Color3.White();
    adPlaneMat.backFaceCulling=true;
    this.adPlane.material = adPlaneMat;


    
    this.vCube.actionManager = new BABYLON.ActionManager(this.scene);
    //     let tout = setTimeout(() => {
    //         this.vCube.actionManager.registerAction(new BABYLON.ExecuteCodeAction(
    //         {
    //           trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
    //           parameter: {mesh:this.scene.getMeshByName("pickedGround"),usePreciseIntersection:true}
    //         },
    //         () => {
                
    //               // if( this.robotInterFace !== null && this.robotInterFace.boardEvent !== null)
    //               //     document.dispatchEvent(this.robotInterFace.boardEvent);
                
    //         }
    //       )
    //     );
    //     clearTimeout(tout);
    // }, 5000);
  }
  
  // checkCollisionRobot()
  // {
  //       this.vCube.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
  //             parameter: {mesh:this.scene.getMeshByName("robotcollidebox"),usePreciseIntersection:true}},() => {
  //                 // this.fireCollisionEvent();
  //             }
  //       ));
        
  // }
  fireCollisionEvent(){
    // let  boardrobotEvent = new Event(Utils.events.boardRobotCollision);
     let boardrobotEvent = new CustomEvent(Utils.events.boardRobotCollision,{detail:{name:this.name}});
     document.dispatchEvent(boardrobotEvent);
  }
  removeBoardFromScene()
  {
      this.scene.removeMesh(this.adPlane);
      this.adPlane.dispose();

      this.scene.removeMesh(this.woodPart);
      this.woodPart.dispose();

      this.scene.removeMesh(this.vCube);
      this.vCube.dispose();

      if(this.worldManager && this.worldManager.world)
        this.worldManager.world.remove(this.cubeBody);
  }
}
