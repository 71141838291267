export default class BallonAnim{

    constructor(scene) {
        this.scene     = scene;
        this.ballonObj = undefined;
        this.frameRate=30;
    }
    setBallonObj(_obj)
    {
        this.ballonObj = _obj;
        this.ballonObj.setEnabled(false);
    }
    setBallonPos(pos)
    {
        this.ballonObj.setEnabled(true);
        console.log(pos);
        this.ballonObj.position = new BABYLON.Vector3(pos.x, 1, pos.z);
        this.ballonObj.rotation = new BABYLON.Vector3(0, 0, 0);
        this.ballonAnim();    
        
    }
    ballonAnim()
    {
          let animation = new BABYLON.Animation("ballonanimation", "position",this.frameRate ,BABYLON.Animation.ANIMATIONTYPE_VECTOR3,BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
            let keys = [];
            keys.push({
                frame: 0,
                value: this.ballonObj.position
            });
            keys.push({
                frame: 120,
                value: new BABYLON.Vector3(this.ballonObj.position.x,this.ballonObj.position.y+10,this.ballonObj.position.z)
            });
            animation.setKeys(keys);
            this.ballonObj.animations.push(animation);

        

            let rotanimation = new BABYLON.Animation("ballonanimation", "rotation.x",this.frameRate ,BABYLON.Animation.ANIMATIONTYPE_FLOAT,BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
                let rotkeys = [];
                rotkeys.push({
                    frame: 0,
                    value: 0
                });
                rotkeys.push({
                    frame: 40,
                    value: Math.PI
                });
                rotkeys.push({
                    frame: 80,
                    value: 0
                });
                rotkeys.push({
                    frame: 120,
                    value:-Math.PI
                });
                rotanimation.setKeys(rotkeys);
        this.ballonObj.animations.push(rotanimation);
        
        
        let tout = setTimeout(async () => {
            clearTimeout(tout);
            let anim = this.scene.beginAnimation(this.ballonObj,0,120,false);
            await anim.waitAsync();
            this.ballonObj.setEnabled(false);
            console.log("end");
        });

    }

}