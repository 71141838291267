import * as BABYLON from 'babylonjs';
import * as CANNON from 'cannon';
import { getValueByUnitType, UnitTypes } from './units.js';
import * as Utils from './utils.js';

export default class WorldManager {
    constructor(scene,texture_path) {
        this.scene=scene;
        this.world; //Physical World
        this.gravity = new BABYLON.Vector3(0, Utils.GRAVITY, 0); //World Gravity
        this.boxes = [];
        this.id = 0;
        //Materials
        this.alphaMat=new BABYLON.StandardMaterial("alpha_Mat",this.scene);
        this.alphaMat.alpha=.6;
        this.fixedTimeStep = 1/60.0; // seconds
        this.maxSubSteps = 4;
        this.lastTime    = undefined;
        this.curentTime  = new Date().getTime();
        this.groundMat              =  new BABYLON.StandardMaterial("myMat", this.scene);
        // this.groundMat.diffuseColor = BABYLON.Color3.FromHexString("#706654");
        this.groundMat.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        const tex      = new BABYLON.Texture(texture_path, this.scene);
        tex.hasAlpha=true;
        this.groundMat.diffuseTexture = tex;
        this.groundMat.freeze();
        
        let faceUV = new Array(6);
        for (let i = 0; i < 6; i++) {
            faceUV[i] = new BABYLON.Vector4(0, 0, 0, 0);
        }
        faceUV[0] = new BABYLON.Vector4(0,0,1,1);
        let options = {
            width: 1,
            height: 1,
            depth: 1,
            faceUV: faceUV
        };
        this.vGround            = BABYLON.MeshBuilder.CreateBox("",options,this.scene);
        this.vGround.isPickable = false;
        this.vGround.material   =  this.groundMat;
        this.vGround.setEnabled(false);
        this.vGround.freezeWorldMatrix();
        this.createPhysicsWorld();
    }   

    createPhysicsWorld () { //first Load

        this.world = new CANNON.World();
        this.world.gravity.set(0, this.gravity.y, 0);
        
        this.world.broadphase = new CANNON.NaiveBroadphase();
        this.world.defaultContactMaterial.friction = .001;
        this.world.defaultContactMaterial.restitution =.1;
        this.world.solver.iterations = 40;
        this.world.quatNormalizeSkip = 0;
        this.world.quatNormalizeFast = true;
        this.world.solver.tolerance = .0001;
        this.world.defaultContactMaterial.contactEquationStiffness  = 1e9; //1e9 5e6
        this.world.defaultContactMaterial.contactEquationRelaxation = 5;
        let groundMaterial = new CANNON.Material("physicgroundMaterial");
        groundMaterial.friction     = .3;
        groundMaterial.restitution  = .3;
        let wheelMaterial = new CANNON.Material("wheelMaterial");
        const wheelGroundContactMaterial = window["wheelGroundContactMaterial"] = new CANNON.ContactMaterial(wheelMaterial, groundMaterial, {
            friction: .3,
            restitution:.3,
            // contactEquationStiffness: 1e7,
            // contactEquationRelaxation:10,
            // frictionEquationStiffness: 1e7,
            // frictionEquationRelaxation:10
        });
        // We must add the contact materials to the world
        this.world.addContactMaterial(wheelGroundContactMaterial);

        // this.world.addEventListener('beginContact', () => {
        //     console.log('contact!!!')
        // })

        // this.world.addEventListener('endContact', () => {
        //     console.log('end contact!!!')
        // })
        // this.calculateArea();
    }
    createGroundTile (groundName, groundPosition, groundSizeV3,visibleValue) { //Physical & NormalGrounds
        let groundRot = new BABYLON.Vector3(0,0,0); // Ground Angle
        // Step 1 -- Create Physics Ground
        let groundSize = new CANNON.Vec3(groundSizeV3.x/2, groundSizeV3.y/2, groundSizeV3.z/2);// Step 1 -- Make Shape
        let BoxShape   = new CANNON.Box(groundSize);
        let pGround = new CANNON.Body({mass: 0, shape: BoxShape}); // Step 2 -- Build the body
            pGround.addShape(BoxShape);
            pGround.name = "physicground";
            switch(groundName){
                case "Left":
                        pGround.position.set(groundPosition.x, groundPosition.y, groundPosition.z+.3);
                    break;
                 case "Right": 
                        pGround.position.set(groundPosition.x, groundPosition.y, groundPosition.z-.3);
                    break;
                case "Forward": 
                        pGround.position.set(groundPosition.x-.3, groundPosition.y, groundPosition.z);
                    break;
                case "BackWard":
                        pGround.position.set(groundPosition.x+.3, groundPosition.y, groundPosition.z);
                    break
                case "Ground":
                        pGround.position.set(groundPosition.x, groundPosition.y, groundPosition.z);
                    break;
            }
            pGround.quaternion.setFromEuler(groundRot.x,groundRot.y, groundRot.z,"XYZ");
            this.world.add(pGround);            
            // Step 2 -- Create Virtual Ground
            let tmpGround =  this.vGround.clone(groundName+"_v"); //  BABYLON.MeshBuilder.CreateBox(groundName+"_v", {height: groundSizeV3.y, width:groundSizeV3.x, depth:groundSizeV3.z}, this.scene);
            tmpGround.scaling = new BABYLON.Vector3(groundSizeV3.x,groundSizeV3.y*3,groundSizeV3.z)
            tmpGround.position = new BABYLON.Vector3(groundPosition.x,groundPosition.y+1.5, groundPosition.z);
            // tmpGround.rotationQuaternion = new BABYLON.Quaternion.FromEulerAngles(groundRot.x,groundRot.y, groundRot.z);
            tmpGround.visibility = visibleValue;
            tmpGround.isPickable=true;
            
            let mat = this.groundMat.clone(groundName+"mat");
            tmpGround.material = mat;
            
            if(groundName.includes("Left"))
                tmpGround.material.diffuseTexture.wAng = BABYLON.Angle.FromDegrees(180).radians();
            if(groundName.includes("Right")){
                tmpGround.material.diffuseTexture.wAng = BABYLON.Angle.FromDegrees(180).radians();
                tmpGround.rotation.y =  BABYLON.Angle.FromDegrees(180).radians();
            }
            if(groundName.includes("Forward")){
                tmpGround.material.diffuseTexture.wAng = BABYLON.Angle.FromDegrees(180).radians();
                tmpGround.scaling = new BABYLON.Vector3(groundSizeV3.z,groundSizeV3.y*3,groundSizeV3.x)
                tmpGround.rotation.y =  BABYLON.Angle.FromDegrees(270).radians();
            }
            if(groundName.includes("BackWard")){
                tmpGround.material.diffuseTexture.wAng = BABYLON.Angle.FromDegrees(180).radians();
                tmpGround.scaling = new BABYLON.Vector3(groundSizeV3.z,groundSizeV3.y*3,groundSizeV3.x)
                tmpGround.rotation.y =  BABYLON.Angle.FromDegrees(-270).radians();
            }
            tmpGround.material.freeze();
            tmpGround.freezeWorldMatrix();
            tmpGround.setEnabled(true);
            this.vGround.setEnabled(false);
        // Step 5 -- Add To Physical World
        return {
            pBody : pGround,
            vBody : tmpGround,
        };
    }
    createCub (name,position){
    // Step 1 -- Create Physics Cube
        let cubeSize = new BABYLON.Vector3(1, 1, 1); 
        let cubeShape = new CANNON.Box(new CANNON.Vec3(cubeSize.x, cubeSize.y, cubeSize.z));
        let cubeBody = new CANNON.Body({mass: 1, shape: cubeShape}); // Step 2 -- Build the body
            cubeBody.addShape(cubeShape);
            cubeBody.position.set(position.x, position.y, position.z);
            cubeBody.quaternion.setFromEuler(0, 0, 0,"XYZ");

            this.world.add(cubeBody);
    // Step 2 -- Create Virtual Cube
        let vCube = BABYLON.MeshBuilder.CreateBox(name, {width: cubeSize.x  * 2, height: cubeSize.y * 2, depth: cubeSize.z * 2}, this.scene);
            vCube.position = new BABYLON.Vector3.Zero();
        let boxMat = new BABYLON.StandardMaterial("boxMat", this.scene);
            vCube.material = boxMat;
        this.boxes.push(
            {
                pCube: cubeBody,
                vCube: vCube,
            }
        )
        return {
            p: cubeBody,
            v: vCube,
        }
    }
    createBalls () {
        const cubeShape = new CANNON.Sphere(0.25);
        const cubeBody = new CANNON.Body({ mass: 1, shape: cubeShape }); // Step 2 -- Build the body
        cubeBody.addShape(cubeShape);
        cubeBody.position.set(this.carPosition.x, 5, this.carPosition.z);
        this.world.add(cubeBody);
        const sphere = BABYLON.MeshBuilder.CreateSphere("sphere" + this.id,{ diameter: 0.25 * 2 },this.scene);
        this.boxes.push({pCube: cubeBody,vCube: sphere,});
        this.id++;
        if(this.id < Utils.ballsCount){
            setTimeout(() => {
                this.createBalls();
            }, 2000);
        }
    }
    createObjectMaterial(){
        let objMaterial = new CANNON.Material("cannonobj_mat");
        objMaterial.friction = .3;
        objMaterial.restitution=.3;
        return objMaterial;
    }
    updateWorld(frameRate){
        this.curentTime = new Date().getTime();
        if(this.lastTime !== undefined){
            let delta =  (this.curentTime - this.lastTime) / 1000;
            if(frameRate<30)
               frameRate =30;

            frameRate = frameRate.toFixed(1);
            this.fixedTimeStep = 1/frameRate;
            this.world.step(this.fixedTimeStep,delta,this.maxSubSteps);
        }
        this.lastTime = this.curentTime;
        
    }
    calculateArea(){
        let size=.5
        let tmp         = BABYLON.MeshBuilder.CreateBox("", {size:size},this.scene);
        tmp.position    = new BABYLON.Vector3(-18,0,0);
        tmp.isPickable = false;
        
        let tmp1        = tmp.clone("1");
        tmp1.position   = new BABYLON.Vector3(18,0,0);
        tmp1.isPickable = false;

        const myPoints = [tmp1.position,tmp.position];
        const lines = BABYLON.MeshBuilder.CreateLines("lines", {points: myPoints});
        lines.isPickable = false;
        let vDis = BABYLON.Vector3.Distance(tmp.position,tmp1.position);    
        console.log("!!! vdistance!!! "+getValueByUnitType(vDis,UnitTypes.mm)+"mm");



        let tmp2         = tmp.clone("2");
        tmp2.position    = new BABYLON.Vector3(0,0,12);
        tmp2.isPickable  = false;

        let tmp3        = tmp.clone("3");
        tmp3.position   = new BABYLON.Vector3(0,0,-12);
        tmp3.isPickable = false;

        const myPoints2   = [tmp2.position,tmp3.position];
        const lines2      = BABYLON.MeshBuilder.CreateLines("lines", {points: myPoints2});
        lines2.isPickable = false;
        let hDis = BABYLON.Vector3.Distance(tmp2.position,tmp3.position);    
        console.log("!!! hdistance!!! "+getValueByUnitType(hDis,UnitTypes.mm)+"mm");


        
        let centertmp         = tmp.clone("4");
        centertmp.position    = new BABYLON.Vector3(0,0,0);

        let centertmp2        = tmp.clone("4");
        centertmp2.position    = new BABYLON.Vector3(14,0,0);


        // let groundcube         = BABYLON.MeshBuilder.CreateBox("", {width: 20, height: .2, depth: 20},this.scene);
        // groundcube.position    = new BABYLON.Vector3(0,-.2,0);

        // let mat = new BABYLON.PBRMaterial("mymat",this.scene);
        // mat.albedoTexture = new BABYLON.Texture("./textures/Ground/Sand_003_COLOR.jpg", this.scene);
        // mat.bumpTexture = new BABYLON.Texture("textures/Ground/Sand_003_NORM.jpg", this.scene);        
        // mat.ambientTexture  = new BABYLON.Texture("textures/Ground/Sand_003_OCC.jpg", this.scene);        
        // mat.metallicTexture  = new BABYLON.Texture("textures/Ground/Sand_003_ROUGH.jpg", this.scene);        
        // groundcube.material = mat;



    }



}