
export default class MyRobotWrapper
{
     constructor()
     {
        this.loaderManager = null;
        this.createMyRobot(this);   
        window['createMyRobot'] = this.createMyRobot.bind(this);
     }  
     createMyRobot() 
     {
        return this;
     }
     setLoaderManager(loadmanager)
     {
       this.loaderManager = loadmanager;
     } 
     getleftMotorPower()
     {
         if(this.loaderManager.loaded)
         {
            console.log("!! LeftPower is!!! "+this.loaderManager.carManager.leftEnginePower);
         }
     }
   //   getleftEngineSpeed()
   //   {
   //       if(this.loaderManager.loaded)
   //       {
   //          console.log("!! LeftSpeed is!!! "+this.loaderManager.carManager.leftEngineSpeed);
   //       }
   //   }
     getrightMotorPower()
     {
         if(this.loaderManager.loaded)
         {
            console.log("!! RightPower is!!! "+this.loaderManager.carManager.rightEnginePower);
         }
     }
   //   getrightEngineSpeed()
   //   {
   //       if(this.loaderManager.loaded)
   //       {
   //          console.log("!! RightSpeed is!!! "+this.loaderManager.carManager.rightEngineSpeed);
   //       }
   //   }
     applyRoboEnginesPowers(leftMotor,rightMotor)
     {
      //   this.lInput.value = leftMotor;
      //   this.rInput.value = rightMotor;
      //   document.getElementById("leftEngineInput").value= this.lInput.value;
      //   document.getElementById("rightEngineInput").value= this.rInput.value;
        this.loaderManager.carManager.applyEnginesPowers(leftMotor,rightMotor);
     }
     applyRoboEnginesBrake()
     {
        this.loaderManager.carManager.stopEngines();
     }
     switchFanAnim(fanSwitch)
     {
        if(document.getElementById("fanAnim"))
            document.getElementById("fanAnim").checked = fanSwitch;
            
         console.log(this.loaderManager.carManager.fanComponent+"    !!! fan!!!!  ");
         this.loaderManager.carManager.fanComponent.runFanAnimation(fanSwitch);
     }
     getColorSensorValue()
     {
        let leftsensor = this.loaderManager.carManager.leftcolorSensor.sensorValue;
        let rightsensor = this.loaderManager.carManager.rightcolorSensor.sensorValue;
        return {"left":leftsensor,"right":rightsensor};
     }
     getFlameSenseorValue() 
     {
         let flameSsensor = this.loaderManager.carManager.flameSensor.sensorValue;
         return flameSsensor;
     }
     getDistanceSenseorValue() 
     {
        //  let distacneSsensor = {"distancesensor":{state:this.loaderManager.inputs.disState,name:this.loaderManager.inputs.dis_name.value,direction:this.loaderManager.inputs.dis_dir.value}};
        let distanceSsensor = this.loaderManager.carManager.distanceSensor.sensorValue;
        return distanceSsensor;
     }
     getRobotPosition() 
     {
        let robot = this.loaderManager.carManager.chassisBody;
        let pos = {x:robot.position.x,y:robot.position.y,z:robot.position.z};
        return pos;
     }
     getRobotVelocity() 
     {
        let velocity = this.loaderManager.carManager.getRobotVelocity();
        return velocity;
     }
     
     getAllSensorValue()
     {  
         let sensorval={
               "flamesensor"     : this.loaderManager.carManager.flameSensor.sensorValue,
                "distancesensor" : this.loaderManager.carManager.distanceSensor.sensorValue,
                "irsensor":{
                    "left" :this.loaderManager.carManager.leftcolorSensor.sensorValue,
                    "right":this.loaderManager.carManager.rightcolorSensor.sensorValue
                },
                "robot":{
                    "position": {"x":this.getRobotPosition().x,"y":this.getRobotPosition().y,"z":this.getRobotPosition().z},
                    "velocity": this.getRobotVelocity() 
                }
         }
        //  console.log(val);
        return sensorval;

     }


     turnOnRobotLight()
     {
      this.loaderManager.carManager.turnOnRobotLight();  
      
     }
     turnOffRobotLight()
     {
      this.loaderManager.carManager.turnOffRobotLight();  
      
     }
     showRayCast()
     {
         this.loaderManager.carManager.leftcolorSensor.isVisibleRay=true;
         this.loaderManager.carManager.rightcolorSensor.isVisibleRay=true;
         this.loaderManager.carManager.distanceSensor.isVisibleRay=true;
         this.loaderManager.carManager.flameSensor.isVisibleRay=true;
         this.loaderManager.carManager.fanSensor.rayvisible=true;

     }
     hideRayCast()
     {
         this.loaderManager.carManager.leftcolorSensor.isVisibleRay=false;
         this.loaderManager.carManager.rightcolorSensor.isVisibleRay=false;
         this.loaderManager.carManager.distanceSensor.isVisibleRay=false;
         this.loaderManager.carManager.flameSensor.isVisibleRay=false;
         this.loaderManager.carManager.fanSensor.rayvisible=false;
     }
     followCamera()
     {
      this.loaderManager.carManager.arcCam.lockedTarget = this.loaderManager.carManager.babylonCarBox
     }
     unfollowCamera()
     {
      this.loaderManager.carManager.arcCam.lockedTarget = new BABYLON.Vector3(0,0,0);
     }
}  