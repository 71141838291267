import CANNON from 'cannon';
import Common from '../Common.js' 
import WorldManager from '../WorldManager.js';
import LoaderManager from '../LoaderManager.js';
import FlameEffectGenerator from '../Effects/FlameEffectGenerator.js';
import { getJointDistance } from '../CarManager.js';
export class DemoScene{
    constructor(gameManager) {
        this.game = gameManager;
        this.sceneCommon = new Common(this.game);
        this.scene  = this.sceneCommon.createScene("demoscene");
        this.arcCam = this.sceneCommon.createCamera(this.scene);
        this.worldManager   = new WorldManager(this.scene);
        this.loaderManager  = new LoaderManager(this);
        this.loaderManager.initializeSceneAssets();
        this.game.engine.hideLoadingUI();
        this.createGround();
        setTimeout(() => {
            this.createPickObject();    
        }, 4000);
      }
      createGround(){
        const groundShape = new CANNON.Plane();
        this.groundBody   = new CANNON.Body({ mass: 0 });
        this.groundBody.position.set(0,0,0);
        this.groundBody.addShape(groundShape);
        this.groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0),-Math.PI/2)
        this.worldManager.world.add(this.groundBody);
        // this.worldManager.boxes.push({pCube:this.groundBody,vCube:this.ground});
    }
    
    createPickObject () {
        const size = .25;
        // const ballShape = new CANNON.Sphere(size);
        const shape       =  new CANNON.Box(new CANNON.Vec3(size*.5,size*.5,size*.5));
        const pickObject  =  new CANNON.Body({mass:.1,shape:shape,linearDamping:.5,angularDamping:.5,velocity:CANNON.Vec3.ZERO,
                                              angularVelocity:CANNON.Vec3.ZERO});
        pickObject.name = "pick_body";
        pickObject.position.set(5,2,0);
        pickObject.velocity.set(0,0,0);
        pickObject.angularVelocity.set(0,0,0);
        const physicmat = new CANNON.Material("ground_obj");
        pickObject.material = physicmat;
        pickObject.material.restitution=0;
        pickObject.material.friction=1;
        const pick_box = BABYLON.MeshBuilder.CreateBox("pick_box",{size:size},this.scene);
        this.worldManager.world.add(pickObject);
        this.worldManager.boxes.push({pCube: pickObject,vCube: pick_box});


        const shape2 = new CANNON.Cylinder(.15,.15,.7,4);
        const pickObject2 = new CANNON.Body({mass:.1,shape:shape2,linearDamping:.5,angularDamping:.5,velocity:CANNON.Vec3.ZERO,
        angularVelocity:CANNON.Vec3.ZERO});
        pickObject2.name = "pick_body";
        pickObject2.material = physicmat;
        pickObject2.material.restitution=0;
        pickObject2.material.friction=1;
        console.log(pickObject2.collisionFilterGroup+"       "+pickObject2.collisionFilterMask);
        // pickObject2.quaternion.setFromAxisAngle(new CANNON.Vec3(1,0,0),Math.PI/2);
        const quat = new CANNON.Quaternion();
        quat.setFromAxisAngle(new CANNON.Vec3(-1,0,0),-Math.PI/2);
        const translation = new CANNON.Vec3(0,0,0);
        pickObject2.shapes[0].transformAllPoints(translation,quat);
       
        const pick_cylinder = BABYLON.MeshBuilder.CreateCylinder("pick_cylinder",{diameter:.3,height:.7,subdivisions:4},this.scene);
        pick_cylinder.position.set(0,5,5);
        pick_cylinder.rotationQuaternion= new BABYLON.Quaternion.FromEulerAngles(0,0,0);
        pickObject2.position = (new CANNON.Vec3(pick_cylinder.position.x,pick_cylinder.position.y,pick_cylinder.position.z));
        this.worldManager.world.add(pickObject2);
        this.worldManager.boxes.push({pCube: pickObject2,vCube: pick_cylinder});

        // this.flameEffect = new FlameEffectGenerator("cc",new BABYLON.Vector2(0,5),{ height:.7,diameter:.15}
        // ,"#12e04c",.5,true,1,this.worldManager,this.scene,this.loaderManager.carManager.robotBox);     
       const friction = 1;
       const contactStiffness   = 1e7;
       const contactRelaxation  = 3;
       const frictionStiffness  = 1e5;
       const frictionRelaxation = 10;
    const jointballcontactMaterial =  new CANNON.ContactMaterial(physicmat, this.loaderManager.carManager.grabRight.contactMat, {
        friction: friction,
        restitution:0,
        contactEquationStiffness: contactStiffness,
        contactEquationRelaxation:contactRelaxation,
        frictionEquationStiffness:frictionStiffness,
        frictionEquationRelaxation:frictionRelaxation,
    });
    console.log(jointballcontactMaterial.contactEquationStiffness+"  !! contact!!      "+jointballcontactMaterial.contactEquationRelaxation);
    console.log(jointballcontactMaterial.frictionEquationStiffness+"  !! friction!!    "+jointballcontactMaterial.frictionEquationRelaxation);
    this.worldManager.world.addContactMaterial(jointballcontactMaterial);

    const jointballcontactMaterial2 =  new CANNON.ContactMaterial(physicmat, this.loaderManager.carManager.grabLeft.contactMat, {
        friction: friction,
        restitution:0,
        contactEquationStiffness: contactStiffness, 
        contactEquationRelaxation:contactRelaxation,
        frictionEquationStiffness:frictionStiffness,
        frictionEquationRelaxation:frictionRelaxation,
    });
    this.worldManager.world.addContactMaterial(jointballcontactMaterial2);
    let isPick=false;
    let joint=null;
    setInterval(() => {
        const value1 = this.loaderManager.carManager.bodiesAreInContact(this.loaderManager.carManager.grabRight.grabBody,pickObject2)
        const value2 = this.loaderManager.carManager.bodiesAreInContact(this.loaderManager.carManager.grabLeft.grabBody,pickObject2)
        if(value1 && value2){
            if(!isPick){
                console.log("!! in pick!!");
                isPick = true;
                const force = 1e7;
                joint = new CANNON.HingeConstraint(this.loaderManager.carManager.jointCeter.grabBody,pickObject2,{
                    pivotA:new CANNON.Vec3(0,0,0),
                    axisA: new CANNON.Vec3(0,1,0),
                    pivotB:new CANNON.Vec3(0,0,0),
                    axisB: new CANNON.Vec3(0,1,0),
                    maxForce:force,
                });
                joint.collideConnected=false;
                joint.enableMotor();
                joint.motorMaxForce  = force;
                joint.motorMinForce  = force;
                pickObject2.collisionFilterGroup=0;
                pickObject2.collisionFilterMask=0;
                this.worldManager.world.addConstraint(joint);
            }
        }
        if( getJointDistance()>.6 &&  isPick && joint){
            console.log("!! in relase!!");
            pickObject2.collisionFilterGroup=1;
            pickObject2.collisionFilterMask=1;
            this.worldManager.world.removeConstraint(joint);
            joint = null;
            isPick = false;
        }

    },100); 
   }
}