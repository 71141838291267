import * as BABYLON from "babylonjs";
import "babylonjs-loaders";

import CarManager from "./CarManager.js";
import * as Utils from "./utils.js";

export default class LoaderManager {
  constructor(parentScene) {
    this.parentScene = parentScene;
    this.game = parentScene.game;
    this.scene = parentScene.scene;
    this.world = parentScene.worldManager.world;

    this.assetsManager = new BABYLON.AssetsManager(this.scene);
    this.assetsManager.useDefaultLoadingScreen = true;
    this.inputs = parentScene.Inputs;
    this.fanComponent;
  }
  async loadRobot(){
    return  new Promise(resolve => {
          const robotTask = this.assetsManager.addMeshTask("Robot_Task","","/models/robot2/","Car_wheel.gltf");
          robotTask.onSuccess = (task) => {
          console.log(" Robot loaded !!! ");
          this.carManager = new CarManager(this.parentScene, {x: parseInt(0),y: parseInt(1),z: parseInt(0),},this.parentScene.sceneCommon.spotlight);
          document.getElementById("fanAnim").addEventListener("change", (e) => {
            this.carManager.fanComponent.runFanAnimation(e.target.checked);
          });
          resolve('robot load');
        };
     });
  }
  async load3dScene(){
    return new Promise(resolve =>{
      const parentScenetask =   this.assetsManager.addMeshTask("firefightingtask","","/models/scene/","scene.glb");
      parentScenetask.onSuccess = (task) => {
          console.log(" parentScene loaded !!! ");
          const carpet = this.scene.getNodeByID("Carpet");
          carpet.position.y = Utils.groundY;
          for(let i=0;i<carpet._children.length;i++){
              if(carpet._children[i].name ==="carpet"){
                let carpetMaterial = new BABYLON.PBRMaterial("carpetMaterial", this.scene);
                carpetMaterial.albedoTexture = new BABYLON.Texture("/textures/scene/carpet_texture_512.png",this.scene);
                carpetMaterial.bumpTexture    = new BABYLON.Texture("/textures/scene/carpet_texture_2_512.png",this.scene);
                carpet._children[i].material = carpetMaterial;
                carpetMaterial.roughness=1;
                carpetMaterial.metallic=.1;
              }
              if(carpet._children[i].name ==="tiles"){
                let tileMaterial = new BABYLON.PBRMaterial("tileMaterial", this.scene);
                tileMaterial.albedoTexture = new BABYLON.Texture("/textures/scene/tile_texture_3_256.png",this.scene);
                tileMaterial.bumpTexture = new BABYLON.Texture("/textures/scene/tile_texture_4_256.png",this.scene);
                carpet._children[i].material = tileMaterial;
                tileMaterial.roughness=1;
                tileMaterial.metallic=.1;
              }
          }
          const circle = this.scene.getNodeByID("Circle");
          circle.setEnabled(false);
          const candle = this.scene.getNodeByID("Candle");
          candle.setEnabled(false);
          resolve("3d scene load");
      };
    })
  }
  initializeSceneAssets() {
    //first Load
    console.log(this.scene.name);
    switch(this.game.sceneManager.currentSceneState){
           case this.game.sceneManager.sceneState.firefighting:
           case this.game.sceneManager.sceneState.demo:{
                  this.loadRobot();
                  this.load3dScene();
              }
            break;
           case this.game.sceneManager.sceneState.maze:{
                  this.loadRobot().then(()=>{
                    this.parentScene.generateTile();
                  })
                  // this.load3dScene().then(()=>{
                    let sizeV3 = new BABYLON.Vector3(Utils.groundWidth,Utils.border.height,Utils.border.depth); // Border Size
                    let leftBorder = this.parentScene.worldManager.createGroundTile("Left",new BABYLON.Vector3(0,Utils.border.height / 2,-(Utils.groundDepth / 2) + -Utils.border.depth / 2),sizeV3,1);
                    sizeV3 = new BABYLON.Vector3(Utils.groundWidth,Utils.border.height,Utils.border.depth); // Border Size
                    let rightBorder = this.parentScene.worldManager.createGroundTile("Right",new BABYLON.Vector3(0,Utils.border.height / 2,Utils.groundDepth / 2 + Utils.border.depth / 2),sizeV3,1);
                    sizeV3 = new BABYLON.Vector3(Utils.border.depth,Utils.border.height,Utils.groundDepth); // Border Size
                    let forwardBorder = this.parentScene.worldManager.createGroundTile("Forward",new BABYLON.Vector3(Utils.groundWidth / 2 + Utils.border.depth / 2,Utils.border.height / 2,0),sizeV3,1);
                    sizeV3 = new BABYLON.Vector3(Utils.border.depth,Utils.border.height,Utils.groundDepth); // Border Size
                    let backBorder = this.parentScene.worldManager.createGroundTile("BackWard",new BABYLON.Vector3(-Utils.groundWidth / 2 + Utils.border.depth / 2,Utils.border.height / 2,0),sizeV3,1);
                  // });
              }
            break;
    }
  // console.log("meshes", meshes)
   this.assetsManager.onProgress = (
      remainingCount,
      totalCount,
      lastFinishedTask
    ) => {
      this.game.engine.loadingUIText = "We are loading the scene. " +remainingCount +" out of " +totalCount +" items still need to be loaded.";};

    this.assetsManager.onFinish = (tasks) => {
      //On ALL Done
      this.loaded = true;
      console.log("!!! assetsManager finish!!!");
      switch(this.game.sceneManager.currentSceneState)
      {
           case this.game.sceneManager.sceneState.firefighting:
                this.parentScene.initCandle(this.scene);           
               break;
           case this.game.sceneManager.sceneState.linefollowing:
               break;
      }
      if(document.getElementById("loader_spiner"))
         document.getElementById("loader_spiner").style.display = "none";
      
    };
    // Start loading
    this.assetsManager.load();
  }
}