import * as BABYLON from "babylonjs";
import * as Utils from "../utils.js";
import { LightComponent } from "../Components/LightComponent.js";

// @name ---- string
// @position ---- vector3
// @direction ---- vector3
// @parent ---- Mesh
// @rayLength ---- number
// @samplingRateMs ---- number
// @isVisibleRay ---- boolean
// @onFire --- (result:{})=> void
// @scene ---- active scene
let resultObj = {};
export default class ColorSensorGenerator {
  constructor(
    name,
    position,
    direction,
    parent,
    rayLength,
    samplingRateMs,
    isVisibleRay,
    onFire,
    scene,
    
  ) {
    this.name = name;
    this.position = position;
    this.direction = direction;
    this.parent = parent;
    this.rayLength = rayLength;
    this.samplingRateMs = samplingRateMs;
    this.isVisibleRay = isVisibleRay;
    this.onFire = onFire;
    this.scene = scene;

    this.textureimage = new Image();
    this.mycanvas = document.createElement('canvas');
    this.context2d = this.mycanvas.getContext('2d');
    // this.filereader = new FileReader();

    this.materialId = undefined;
    
    this.imageData = null;
    this.sensorValue={};
    
    
    this.lightIndicator = new LightComponent(name,{width:.05,height:.07,depth:.12},new BABYLON.Color3(1,0,0),
                          new BABYLON.Vector3(position.x+.08,position.y+.14,position.z+.07),this.parent,this.scene);
    
    this.lightIndicator.lightMesh.rotation.y =  BABYLON.Angle.FromDegrees(90).radians();

    this.data=[];

    // this.getImageData = (imageURL) => new Promise((resolve, reject) => {
        
    //     let img = new Image();
    //     img.onload = () => {
    //       // Create the canvas of the correct dimensions
    //       let context2D = this.createCanvasContext2D(img.naturalWidth,img.naturalHeight);
    //       // Draw the image to it.
    //       context2D.drawImage(img, 0, 0);
    //       // Resolve the image data
    //       let imageData = context2D.getImageData(0,0,img.naturalWidth,img.naturalHeight);
    //       resolve(imageData);
    //     };
    //     img.onerror = () => reject("getImageData: " + imageURL + " could not be loaded!");
    //       img.src = imageURL;
    //   });
    // this.getImageData("/textures/Ground/Sand_003_COLOR.jpg").then((imageData) => {
    // this.imageData = imageData;
    //   // console.log(this.imageData);
    // });
    this.init();
    this.scene.registerBeforeRender(()=> {
      this.callRayCasting();
    });
  }

  init() {
    this.sensorBox = BABYLON.MeshBuilder.CreateBox(`s_${this.name}`,{ size: 1 },this.scene);
    this.sensorBox.visibility = 0.0;
    this.sensorBox.isPickable = false;
    this.sensorBox.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
    this.sensorBox.position = this.position;
    BABYLON.SceneLoader.ImportMesh("","/models/sensors/","IRSensor02.gltf",this.scene,(meshes, particleSystems, skeletons) => {
        let rootMesh = null;
        for (let i = 0; i < meshes.length; i++) {
          meshes[i].isPickable = false;
          if (meshes[i].name === "__root__") rootMesh = meshes[i];
        }
        rootMesh.parent = this.sensorBox;
        rootMesh.position.x -= 0.5;
        rootMesh.rotation = new BABYLON.Vector3(0, 1.57, 4.71);
        rootMesh.scaling.multiplyInPlace(new BABYLON.Vector3(2, 2, 2));
        // do something with the meshes and skeletons
        // particleSystems are always null for glTF assets
      }
    );
    //assign DistanceRay
    let origin = this.sensorBox.getAbsolutePosition();
    let forward = this.direction;
    forward = this.vecToLocal(forward, this.sensorBox);
    let direction = forward.subtract(origin);
    direction = BABYLON.Vector3.Normalize(direction);
    this.ray = new BABYLON.Ray(origin, direction, this.rayLength);
    this.rayLength = 0;
    this.rayHelper = BABYLON.RayHelper.CreateAndShow(this.ray,this.scene, new BABYLON.Color3(0,1,1))//new BABYLON.RayHelper(this.ray);		
        // this.rayHelper = new BABYLON.RayHelper(this.ray);
        // this.rayHelper.show(this.scene);
    this.sensorBox.parent = this.parent;
    

  }
  vecToLocal(vector, mesh) {
    var m = mesh.getWorldMatrix();
    var v = BABYLON.Vector3.TransformCoordinates(vector, m);
    return v;
  }
  callRayCasting() {
     if(this.rayHelper !== null)     
     {
        if(this.isVisibleRay) {
          this.rayHelper.hide(); 
          this.rayHelper.show(this.scene);
        }
        else
          this.rayHelper.hide(); 
    }
      let surfaceHit = this.scene.pickWithRay(this.ray);
      
      try {
        if(surfaceHit.pickedMesh){
          if(surfaceHit.pickedMesh.material !== null){
             if(surfaceHit.pickedMesh.material.getActiveTextures().length>0){
               
               // console.log(surfaceHit.pickedMesh.material.albedoTexture._texture._buffer);
                let uv = surfaceHit.getTextureCoordinates();
               // console.log(uv);
                if(surfaceHit.pickedMesh.material.name !== this.materialId){
                  
                    this.materialId = surfaceHit.pickedMesh.material.name;
                    let index = this.getDataIndex(this.materialId);
                    // console.log(index);
                    if(index === -1)
                       this.readTexturePixel(surfaceHit,uv);
                    else{
                         this.imageData = this.getDataAtIndex(index);
                        //  console.log(this.imageData);
                    }   
                }
                else{
                    this.setTexturepixel(uv);
                    // console.log("in else read pixel");
                }   
             }
             else{
               // console.log(surfaceHit.pickedMesh.material);  
               // if(surfaceHit.pickedMesh.material.diffuseColor !== undefined)
               // {
               //   this.materialId = surfaceHit.pickedMesh.material.name;
               //   let pixel = surfaceHit.pickedMesh.material.diffuseColor.clone();
               //   pixel.r *= 255;
               //   pixel.g *= 255;
               //   pixel.b *= 255;
               //   resultObj.colorR = pixel.r;
               //   resultObj.colorG = pixel.g;
               //   resultObj.colorB = pixel.b;
               //   resultObj.color = this.rgbToHex(pixel.r, pixel.g, pixel.b);
               //   console.log("  !! diffuse Color!!!    " +resultObj.color+"    "+surfaceHit.pickedMesh.name);
               //   this.onFire(resultObj);
               // }
               // else
                if(surfaceHit.pickedMesh.material.albedoColor !== undefined)
                {
  
                    this.materialId = surfaceHit.pickedMesh.material.name;
                    // console.log(surfaceHit.pickedMesh.material.albedoColor);
                    let pixel = surfaceHit.pickedMesh.material.albedoColor.clone();
                    pixel.r *= 255;
                    pixel.g *= 255;
                    pixel.b *= 255;
                    resultObj.colorR = parseInt(pixel.r);
                    resultObj.colorG = parseInt(pixel.g);
                    resultObj.colorB = parseInt(pixel.b);
                    resultObj.color  = this.rgbToHex(resultObj.colorR, resultObj.colorG,resultObj.colorB);
                    this.sensorValue.state = resultObj.state  = `Activated (${++this.rayLength})`;
                    this.convertRGBtoIR(resultObj.colorR,resultObj.colorG,resultObj.colorB);
                    resultObj.IR           = Number(this.sensorValue.IR);
                    // console.log("  !! albedoColor Color!!!    " +resultObj.color+"    "+surfaceHit.pickedMesh.name);
                    this.onFire(resultObj);
                }
               
             }
          }
        }
      } catch (error) {}
     
      // if (surfaceHit.pickedPoint) {
      //   resultObj.state = `Activated (${++this.rayLength})`;
      //   // this.uiInputs.state.innerHTML = `Activated (${++this.rayLength})`;
      //   let pixel = new BABYLON.Color3(211, 190, 171);
      //   if(surfaceHit.pickedMesh.tag === "ground") {
      //     let uv = surfaceHit.getTextureCoordinates();
      //     pixel = this.getImageDataPixelAtUV(this.imageData, uv.x, uv.y);
      //   } else{
      //           if(surfaceHit.pickedMesh.material && surfaceHit.pickedMesh.material.diffuseColor){
      //             pixel = surfaceHit.pickedMesh.material.diffuseColor.clone();
      //             pixel.r *= 255;
      //             pixel.g *= 255;
      //             pixel.b *= 255;
      //         }
      //   }
      //   resultObj.colorR = pixel.r;
      //   resultObj.colorG = pixel.g;
      //   resultObj.colorB = pixel.b;
      //   resultObj.color = this.rgbToHex(pixel.r, pixel.g, pixel.b);
      //   this.onFire(resultObj);
        
      //   // this.uiInputs.colorR.value = pixel.r;
      //   // this.uiInputs.colorG.value = pixel.g;
      //   // this.uiInputs.colorB.value = pixel.b;
      //   // this.uiInputs.color.style.backgroundColor = this.rgbToHex(
      //   //   pixel.r,
      //   //   pixel.g,
      //   //   pixel.b
      //   // );
      // }
    //   this.callRayCasting();
    // }, this.samplingRateMs);
  }
  // createCanvasContext2D(width, height) {
  //   let canvas = document.createElement("canvas");
  //   canvas.width = width;
  //   canvas.height = height;
  //   return canvas.getContext("2d");
  // }
  // getImageDataPixelAtUV(imageData, u, v) {
  //   console.log("!! x!!! "+"      "+imageData.width+"         "+imageData.height );
  //   if (u < 0 || u > 1) {
  //     console.log("U is out of bounds! Must be in range [0,1]! u = " + u);
  //   } else if (v < 0 || v > 1) {
  //     console.log("V is out of bounds! Must be in range [0,1]! v = " + v);
  //   } else {
      
  //     let x = Math.max(Math.round(u * (imageData.width - 1)), 0);
  //     let y = Math.max(Math.round((1 - v) * (imageData.height - 1)), 0); // using "1-v" to switch to XY coordinates with origin in upper left.
  //     console.log("!! x!!! "+x+" !! y!!! "+y+"      "+imageData.width+"         "+imageData.height );
  //     return this.getImageDataPixelAtIndex(imageData, y * imageData.width + x);
  //   }
  // }
  getImageDataPixel(imageData, uv) {
    // console.log("!! x!!! "+"      "+imageData.width+"         "+imageData.height);
    try {
      let tx = Math.min(this.emod(uv.x, 1) * imageData.width  | 0, imageData.width - 1);
      let ty = Math.min(this.emod(uv.y, 1) * imageData.height | 0, imageData.height - 1);
      // console.log("!! 2222x!!! "+"      "+tx+"         "+ty);
      return this.getImageDataPixelAtIndex(imageData, ty * imageData.width + tx);
    } catch (error) {}
  }
 
  emod(n, m) {
    return ((n % m) + m) % m;
  }
  getImageDataPixelAtIndex(imageData, index) {
    let i = index * 4;
    let d = imageData.data;
    return {
      r: d[i],
      g: d[i + 1],
      b: d[i + 2],
      a: d[i + 3],
    };
  }
  rgbToHex(r, g, b) {
    return (
      "#" +this.componentToHex(r) +this.componentToHex(g) +this.componentToHex(b)
    );
  }
  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    
    });
  }
  readTexturePixel(surfaceHit,uv)
  {
    try {
        // console.log(surfaceHit.pickedMesh.material.albedoTexture._texture);
        // const reader = new FileReader();
        if(surfaceHit.pickedMesh.material.albedoTexture !== undefined)
        {
            // this.filereader.readAsDataURL(surfaceHit.pickedMesh.material.albedoTexture._texture._buffer); 
            // this.filereader.onloadend = ()=> {
            //   // result includes identifier 'data:image/png;base64,' plus the base64 data
            //   mySrc = this.filereader.result;     
            //   // var image = new Image();
            //   this.textureimage.onload = ()=> {
            //     // var canvas = document.createElement('canvas');
            //       this.mycanvas.width = this.textureimage.width;
            //       this.mycanvas.height = this.textureimage.height;
            //       this.context2d.drawImage(this.textureimage, 0, 0);
            //       this.imageData = this.context2d.getImageData(0, 0, this.mycanvas.width, this.mycanvas.height);
            //       this.setTexturepixel(uv);
            // };
            // this.textureimage.src = mySrc;
            // }
            // console.log(surfaceHit.pickedMesh.material.albedoTexture._texture.url);
            let mySrc = surfaceHit.pickedMesh.material.albedoTexture._texture.url;      //URL.createObjectURL(surfaceHit.pickedMesh.material.diffuseTexture._texture.url);
              this.textureimage.onload = ()=> {
                  // console.log(this.textureimage.width+"       "+this.textureimage.height)
                  this.mycanvas.width = this.textureimage.width;
                  this.mycanvas.height = this.textureimage.height;
                  this.context2d.drawImage(this.textureimage, 0, 0);
                  this.imageData = this.context2d.getImageData(0, 0, this.mycanvas.width, this.mycanvas.height);
                  this.setTexturepixel(uv);
                  // URL.revokeObjectURL(mySrc);
                  // this.textureimage = null;
                  this.data.push({id:this.materialId,data:this.imageData})
            };
            this.textureimage.src = mySrc;
            
      }
    } catch (error) {
       console.log(error);
    }
  }
  setTexturepixel(uv)
  {
    try{
         if(this.imageData !== null)
         {
            let pixel = this.getImageDataPixel(this.imageData, uv);
          // let index = (uv.y*imageData.width + uv.x) * 4;
          // let red   = imageData.data[index];
          // let green = imageData.data[index + 1];
          // var blue  = imageData.data[index + 2];
          // // let alpha = imageData.data[index + 3];
            resultObj.colorR = pixel.r;
            resultObj.colorG = pixel.g;
            resultObj.colorB = pixel.b;
            resultObj.color = this.rgbToHex(pixel.r, pixel.g, pixel.b);
            this.sensorValue.state = resultObj.state  = `Activated (${++this.rayLength})`;
            this.convertRGBtoIR(resultObj.colorR,resultObj.colorG,resultObj.colorB);
            resultObj.IR     =  Number(this.sensorValue.IR);
            this.onFire(resultObj);
         }
    } catch (error) {}
  }
  getDataIndex(id)
  {
      for(let i=0;i<this.data.length;i++)  
      {
         if(this.data[i].id === id)
           return i;
      }
      return -1;
  }
  getDataAtIndex(id)
  {
      let data = null;
      data = this.data[id].data;
      return data;
  }
  convertRGBtoIR(r,g,b)
  {
        // const sum = 255*3;
        let irvalue = ((r*0.2125)+(g*0.7154)+(b*0.0721))
        // irvalue *=100;
        irvalue = irvalue.toFixed(0);
        this.sensorValue = {"R":r,"G":g,"B":b,"IR":irvalue};
        
  }

  
}
