export class LightComponent{

        constructor(name,size,color,position,parent,scene,hightlightLayer){
            this.scene      =  scene;
            if(name.includes("distanceSensor"))
                this.lightMesh  = BABYLON.MeshBuilder.CreateSphere(name+"indicator",{diameter:size.diameter},this.scene);
            else  
                this.lightMesh  = BABYLON.MeshBuilder.CreateBox(name+"indicator",{width:size.width,height:size.height,depth:size.depth},this.scene);
            this.lightMesh.position = position;
            this.enableMat                   =  new BABYLON.StandardMaterial(name+"indicatormat1", this.scene);
            this.enableMat.emissiveColor     =  color;

            this.disableMat                  =  new BABYLON.StandardMaterial(name+"indicatormat2", this.scene);
            this.disableMat.emissiveColor    =  new BABYLON.Color3.FromInts(225,225,225);
            
            this.lightMesh.material = this.enableMat;
            this.lightMesh.parent = parent;
            this.lightMesh.isPickable=false;

            // hightlightLayer.addIncludedOnlyMesh(this.lightMesh);
            // hightlightLayer.intensity = .2;



            // hightlightLayer.addMesh(this.lightMesh, color);
            // hightlightLayer.outerGlow = true;
            // hightlightLayer.innerGlow = true;
            // hightlightLayer.blurHorizontalSize =.1;
            // hightlightLayer.blurVerticalSize   =.1;
        }

}