import "./index.css";


import {GameManger} from './scripts/GameManager';
import SceneManager   from './scripts/SceneManager';
export let gameManager = undefined;

//Start GameManger
// GManager = new GameManger("game");       
let buildVersion=2.4;
window["startFireFighting"] = startFireFighting;
window["startMaze"]         = startMaze;
window["getVersion"]        = getVersion;
window["clearEngine"]       = clearEngine;
let sceneNo;
const sceneManager = new SceneManager();
function startFireFighting(){
    // console.log("!! 111111111!!!");
    sceneNo = sceneManager.sceneState.firefighting;
    if(gameManager !== undefined){
        if(gameManager.engine){
            console.log(gameManager.engine);
            releaseEngine();
        }
    }
    else{
        console.log("!! Fresh!!!");
        gameManager = new GameManger("game",sceneNo);
    }
    if(document.getElementById("start_game")!== null)
        document.getElementById("start_game").style.display = "none";
    if(document.getElementById("loader_spiner"))
        document.getElementById("loader_spiner").style.display = "block";
}
function startMaze(){
    // console.log("!! 111111111!!!");
    sceneNo = sceneManager.sceneState.maze;
    if(gameManager !== undefined){
        if(gameManager.engine){
            console.log(gameManager.engine);
            releaseEngine();
        }
    }
    else{
        console.log("!! Fresh!!!");
        gameManager = new GameManger("game",sceneNo);
    }
    if(document.getElementById("start_game")!== null)
        document.getElementById("start_game").style.display = "none";
    if(document.getElementById("loader_spiner"))
        document.getElementById("loader_spiner").style.display = "block";
}
function releaseEngine(){
    console.log("!! 1111relaeseEngine111111 !!!");
    gameManager.releaseWorld();
    if(gameManager.firefightingScene){
        gameManager.firefightingScene.releaseScene();
        gameManager.firefightingScene.scene.dispose();
    }
    if(gameManager.mazescene){
        gameManager.mazescene.releaseScene();
        gameManager.mazescene.scene.dispose();
    }
    gameManager.engine.dispose();
    gameManager.engine = null;
    gameManager = null;
    gameManager = new GameManger("game",sceneNo);
    console.log("!! 22222relaeseEngine2222 !!!");
}
function clearEngine(){
    console.log("!! clearEngine !!!");
    gameManager.releaseWorld();
    if(gameManager.firefightingScene){
        gameManager.firefightingScene.releaseScene();
        gameManager.firefightingScene.scene.dispose();
    }
    if(gameManager.mazescene){
        gameManager.mazescene.releaseScene();
        gameManager.mazescene.scene.dispose();
    }
    gameManager.engine.dispose();
    gameManager.engine = null;
    console.log("!! clearEngine22222222 !!!");
}
function getVersion(){
     return "v_"+buildVersion;
}