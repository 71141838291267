

export class MyRecorder{

    constructor(engine,config)
    {
        // this.startTime = Date.now();
        // this.record={
        //     data:[]
        // };
        this.recorder = null;
        this.maxTime  = 3*60;
        this.engine = engine;
        this.config = config;
        window["startRecording"] = this.startRecording.bind(this);
        window["stopRecording"]  = this.stopRecording.bind(this);
        window["setFrameRate"]   = this.setFrameRate.bind(this);
        
        // window["streamScreen"]   = this.streamScreen.bind(this);
        // this.canvas = document.querySelector('canvas');
        // this.stream = this.canvas.captureStream();
        // setTimeout(() => {
        //     this.streamScreen();
        // }, 2000);
    }
    // trackScene(robot,time)
    // {

    //     let recordTime = (time-this.startTime)/1000;
    //     this.record.data.push(
    //         {
    //             "leftEnginePower" : robot.leftEnginePower,
    //             "rightEnginePower": robot.rightEnginePower,
    //             "recordtime"      : recordTime,
    //         }
    //     )
    //     console.log(this.record);
    // }
    setFrameRate(fps)
    {
       this.config.fps = fps;
    }
    startRecording(fps,isBuffer,isDownload)
    {
        
        
        if(fps !== undefined)
        {
            if(fps>30)
                fps=30;
            if(fps<0)
                fps=20;
            this.config.fps = fps;
        }

        console.log(this.config.fps);
        if(!isBuffer)
            isDownload = false;
        
        let name = isDownload? (+ new Date())+".webm" :null;
        
        if(isBuffer)
        {
            console.log(name);
            if (BABYLON.VideoRecorder.IsSupported(this.engine)) {
                if(this.recorder === null)
                {
                    this.recorder = new BABYLON.VideoRecorder(this.engine,this.config);
                    console.log(this.config);
                }
                // window.startOptimiser();
            }
            if(this.recorder !== null)
            {
                this.recorder.startRecording(name,this.maxTime).then((videoBlob) => {   
                    console.log(videoBlob);
                    insertVideoInDB(videoBlob);
                    URL.revokeObjectURL(videoBlob);
                    this.recorder.dispose();
                    this.recorder = null;
                    // console.log(this.recorder);
                });
            }
        }
    }
    stopRecording()
    {
        if(this.recorder !== null)
        {
            this.recorder.stopRecording();
        }
    }
    // streamScreen()
    // {
    //     document.getElementById('myvideo').style.display ="block";
    //     document.getElementById('myvideo').srcObject  = this.stream;
    //     document.getElementById('myvideo').play();
    // }
    // showVideo(videoSrc)
    // {
    //     // let videoSrc =  localStorage.getItem("scenevideo");
    //     videoSrc = window.URL.createObjectURL(videoSrc);
    //     document.getElementById('myvideo').style.display ="block";
    //     document.getElementById('myvideo').srcObject  = videoSrc;
    //     document.getElementById('myvideo').play();
    //     document.getElementById('myvideo').addEventListener('ended', (event) => {
    //         document.getElementById('myvideo').style.display ="none";
    //     }); 
    // }
}


// async function convertBlobToBase64 (blob){ 
//     return await blobToBase64(blob);
// }
  
// const blobToBase64 = blob => new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onload = () => {
//         resolve(reader.result);
//     };
//     reader.onerror = error => {
//         reject(error);
//         console.log("error");
//     };
// });