// http://www.stat.phys.kyushu-u.ac.jp/~nakanisi/ThreeJS/CannonDemo/double-pendulum.html
// https://bruno-simon.com/
// https://grabcad.com/library/gripper-servo-motor-1
let angle =0;
import * as BABYLON from "babylonjs";
import * as CANNON from "cannon";
import * as Utils from "./utils.js";
import DistanceSensorGenerator from "./Helper/DistanceSensorGenerator.js";
import DistanceSensorGenerator2 from "./Helper/DistanceSensorGenerator2.js";
import FlameSensorGenerator from "./Helper/FlameSensorGenerator.js";
import ColorSensorGenerator from "./Helper/ColorSensorGenerator.js";
import FanSensor from "./Helper/FanSensor.js";
import FanComponentGenerator from "./Components/FanComponentGenerator.js";
import { LightComponent } from "./Components/LightComponent.js";
import MyJoints from "./Helper/MyJoints.js";
import {MOTORSPEED} from './Helper/MyJoints.js';
let SX=0,SY=0,SZ=0;
let jointLeftMarker,jointRightMarker;
export default class CarManager {
  constructor(sceneManager, carposition,spotlight) {
    this.game   = sceneManager.game;
    this.arcCam = sceneManager.arcCam;
    this.world  = sceneManager.worldManager.world;
    this.boxes  = sceneManager.worldManager.boxes;
    this.scene  = sceneManager.scene;
    this.loaderManager = sceneManager.loaderManager;
    this.carposition   = carposition;

    this.inputs = sceneManager.Inputs;
    this.worldManager = sceneManager.worldManager;
    this.alphaMaterial = sceneManager.alphaMaterial;

    //Model Names
    this.wheel1   = "Wheel";
    this.carModel = "Car";
    this.lights    = "Mesh8_Group8_Model";
    this.steeringModel = "steering";

    this.chassisShape;
    this.vehicle=null;
    this.text = {forceOn: 0,brakeOn: 0,};

    this.babylonWheelBodies = [];
    this.wheelBodies = [];
    this.chassisBody;
    this.cannonCar;
    //CarVal's
    this.brakeForce = Utils.maxBrakeForce;
    this.maxSteerVal = .5;
    this.maxForce = Utils.maxForce;
    // this.gravityY = Utils.GRAVITY;
    this.options = {
      radius: 0.3,
      directionLocal: new CANNON.Vec3(0,0,-1),
      suspensionStiffness: 40,
      suspensionRestLength: 0.4,
      frictionSlip: 5,
      dampingRelaxation: 2.3,
      dampingCompression: 4.5,
      maxSuspensionForce: 100000,
      rollInfluence: 0.01,
      axleLocal: new CANNON.Vec3(0,1, 0),
      chassisConnectionPointLocal: new CANNON.Vec3(1, 1, 0),
      maxSuspensionTravel: 0.4,
      customSlidingRotationalSpeed: -40,
      useCustomSlidingRotationalSpeed: true,
    };
    this.doorMat = new BABYLON.StandardMaterial("door_Mat", this.scene);
    this.doorMat.diffuseColor = BABYLON.Color3.Red();

    this.robotBody = null;
    this.babylonCarBox,this.robotRoot,this.robotBox;

    this.leftEnginePower = 0;
    this.rightEnginePower = 0;

    this.spotLight = spotlight;
    this.hemiLight = sceneManager.sceneCommon.hemiLight;
    
    
    this.damping=.1;
    this.maxDamping=1;

    this.tmpleftPower=0;
    this.tmpRightPower=0;
    this.sameDirection=0;
    this.fwdDirection=0;
    this.revDirection=0;
    this.changetout=0;  

    this.robotIndicator=[];
    // window["callloop"] = this.callloop.bind(this);
    this.loadCarModel(sceneManager);
    window["trackCamera"]        = this.trackCamera.bind(this);     
    window["setjointPickUp"]     = this.setjointPickUp.bind(this);    
    window["setGrabjoint"]       = this.setGrabjoint.bind(this);    
  }
  
  loadCarModel(sceneManager) {
    //Set Up The Model
    this.robotBody = this.scene.getTransformNodeByName(this.carModel);
    
    let bodyMat = this.scene.getMaterialByName("silver.001");
    bodyMat.albedoColor = new BABYLON.Color3.White();
    bodyMat.metallic = 0.3;
    bodyMat.roughness = 1;

    let middlePartMat = this.scene.getMaterialByName("darkwhite.001");
    middlePartMat.metallic = 1;
    middlePartMat.roughness = 0.45;

    let gonotMat = this.scene.getMaterialByName("silver");
    gonotMat.albedoColor  =  new BABYLON.Color3.White();
    gonotMat.metallic     =  0.7;
    gonotMat.roughness    =  0.65;

    //Review
    // this.robotBody.getChildMeshes(false)[0].isPickable = false;
    // let robotMat = this.robotBody.getChildMeshes(false)[0].material;
    // robotMat.metallic = 0.55;
    // robotMat.roughness = 0.4;

    this.robotBody.position =  new BABYLON.Vector3(0,0,-0.8);
    this.robotBody.rotation =  new BABYLON.Vector3(-Math.PI * 1,Math.PI * 0.5,Math.PI * 0.5);
    this.robotBody.scaling  =  new BABYLON.Vector3(20,20,-20);
    this.cannonCar          =  this.createCannonVehicle();
    try{
       this.initRoboSensor(sceneManager);  
    } catch(error){}
    // console.log("Robot Created !!");
  }
  createRobotJoint(robot_rotation){
      const position = {x:this.chassisBody.position.x,y:this.chassisBody.position.y,z:this.chassisBody.position.z};
      const force =1e9;
      this.pickLeft = new MyJoints("pick_left",this,{x:.7,y:.2,z:.1},position,2,{r:255,g:0,b:0});
      this.pickLeft.createJoint(this.chassisBody,new CANNON.Vec3(.65,-.35,0),new CANNON.Vec3(0,1,0),new CANNON.Vec3(-.35,0,0),new CANNON.Vec3(0,0,1),force,robot_rotation);
      this.pickLeft.grabBody.mass=0;
      // this.pickLeft.grabBody.collisionFilterGroup =0;
      // this.pickLeft.grabBody.collisionFilterMask =0;

      this.grabLeft = new MyJoints("grab_left",this,{x:.5,y:.2,z:.05},position,.5,{r:255,g:255,b:0});
      const size={x:.02,y:.02,z:.1};
      let shape = new CANNON.Box(new CANNON.Vec3(size.x,size.y,size.z));
      const boxL1 = BABYLON.MeshBuilder.CreateBox("handbox",{ width:size.x*2,height:size.y*2,size:size.z*2},this.scene);  
      const mat = new BABYLON.StandardMaterial("hand_mat");
      mat.diffuseColor = new BABYLON.Color3.FromInts(0,0,255);
      boxL1.material = mat;
      const posL1 = {x:0,y:-.1,z:-size.z};
      boxL1.position = new BABYLON.Vector3(posL1.x,posL1.y,posL1.z);
      boxL1.parent = this.grabLeft.grabJointMesh;
      this.grabLeft.grabBody.addShape(shape,new CANNON.Vec3(posL1.x,posL1.y,posL1.z));

      const boxL2 = boxL1.clone();  
      const posL2 = {x:0,y:.1,z:-size.z};
      boxL2.position = new BABYLON.Vector3(posL2.x,posL2.y,posL2.z);
      boxL2.parent = this.grabLeft.grabJointMesh;
      this.grabLeft.grabBody.addShape(shape,new CANNON.Vec3(posL2.x,posL2.y,posL2.z));
      
      const boxL3 = boxL1.clone();  
      const posL3 = {x:.25,y:-.1,z:-size.z};
      boxL3.position = new BABYLON.Vector3(posL3.x,posL3.y,posL3.z);
      boxL3.parent = this.grabLeft.grabJointMesh;
      this.grabLeft.grabBody.addShape(shape,new CANNON.Vec3(posL3.x,posL3.y,posL3.z));

      const boxL4 = boxL1.clone();  
      const posL4 = {x:.25,y:.1,z:-size.z};
      boxL4.position = new BABYLON.Vector3(posL4.x,posL4.y,posL4.z);
      boxL4.parent = this.grabLeft.grabJointMesh;
      this.grabLeft.grabBody.addShape(shape,new CANNON.Vec3(posL4.x,posL4.y,posL4.z));
      this.grabLeft.createJoint(this.pickLeft.grabBody,new CANNON.Vec3(.35,0,0),new CANNON.Vec3(0,1,0),new CANNON.Vec3(-.25,0,0),new CANNON.Vec3(0,1,0),force,robot_rotation);
      this.grabLeft.grabBody.mass=0;
      
      this.pickRight = new MyJoints("pick_right",this,{x:.7,y:.2,z:.1},position,2,{r:255,g:0,b:0});
      this.pickRight.createJoint(this.chassisBody,new CANNON.Vec3(.65,.35,0),new CANNON.Vec3(0,1,0),new CANNON.Vec3(-.35,0,0),new CANNON.Vec3(0,0,1),force,robot_rotation);
      this.pickRight.grabBody.mass=0;
      // this.pickRight.grabBody.collisionFilterGroup = 0;
      // this.pickRight.grabBody.collisionFilterMask  = 0;

      this.grabRight = new MyJoints("grab_right",this,{x:.5,y:.2,z:.05},position,.5,{r:255,g:255,b:0});
      const boxR1 = boxL1.clone();  
      const posR1 = {x:0,y:-.1,z:size.z}
      boxR1.position = new BABYLON.Vector3(posR1.x,posR1.y,posR1.z);
      boxR1.parent = this.grabRight.grabJointMesh;
      this.grabRight.grabBody.addShape(shape,new CANNON.Vec3(posR1.x,posR1.y,posR1.z));

      const boxR2 = boxL1.clone();  
      const posR2 = {x:0,y:.1,z:size.z}
      boxR2.position = new BABYLON.Vector3(posR2.x,posR2.y,posR2.z);
      boxR2.parent = this.grabRight.grabJointMesh;
      this.grabRight.grabBody.addShape(shape,new CANNON.Vec3(posR2.x,posR2.y,posR2.z));

      const boxR3 = boxL1.clone();  
      const posR3 = {x:.25,y:-.1,z:size.z}
      boxR3.position = new BABYLON.Vector3(posR3.x,posR3.y,posR3.z);
      boxR3.parent = this.grabRight.grabJointMesh;
      this.grabRight.grabBody.addShape(shape,new CANNON.Vec3(posR3.x,posR3.y,posR3.z));

      const boxR4 = boxL1.clone();  
      const posR4 = {x:.25,y:.1,z:size.z}
      boxR4.position = new BABYLON.Vector3(posR4.x,posR4.y,posR4.z);
      boxR4.parent = this.grabRight.grabJointMesh;
      this.grabRight.grabBody.addShape(shape,new CANNON.Vec3(posR4.x,posR4.y,posR4.z));
      this.grabRight.createJoint(this.pickRight.grabBody,new CANNON.Vec3(.35,0,0),new CANNON.Vec3(0,1,0),new CANNON.Vec3(-.25,0,0),new CANNON.Vec3(0,1,0),force,robot_rotation);
      this.grabRight.grabBody.mass=0;
      
      this.chassisBody.velocity.set(0, 0, 0);
      this.chassisBody.angularVelocity.set(0, 0, 0);
      this.chassisBody.linearDamping  = this.maxDamping;
      this.chassisBody.angularDamping = this.maxDamping;

      this.pickcenter = new MyJoints("pick_center",this,{x:.7,y:.2,z:.1},position,1,{r:255,g:128,b:255});
      this.pickcenter.createJoint(this.chassisBody,new CANNON.Vec3(.65,0,0),new CANNON.Vec3(0,1,0),new CANNON.Vec3(-.35,0,0),new CANNON.Vec3(0,0,1),force,robot_rotation);
      this.pickcenter.grabBody.mass=0;
      this.pickcenter.grabJointMesh.visibility=0;
      this.pickcenter.grabBody.collisionFilterGroup=0;
      this.pickcenter.grabBody.collisionFilterMask =0;

      this.jointCeter = new MyJoints("joint_center",this,{x:.1,y:.1,z:.1},position,.5,{r:255,g:0,b:255});
      this.jointCeter.createJoint(this.pickcenter.grabBody,new CANNON.Vec3(.68,.05,0),new CANNON.Vec3(0,1,0),new CANNON.Vec3(0,0,0),new CANNON.Vec3(0,1,0),force,robot_rotation);
      this.jointCeter.grabJointMesh.visibility=0;
      this.jointCeter.grabBody.mass=0;
      this.jointCeter.grabBody.collisionFilterGroup = 0;
      this.jointCeter.grabBody.collisionFilterMask  = 0;


      jointLeftMarker        = BABYLON.MeshBuilder.CreateSphere("joint_center",{diameter:.1});
      const mat1             = new BABYLON.StandardMaterial("mat");
      mat1.diffuseColor      = new BABYLON.Color3(0,0,0);
      jointLeftMarker.material = mat1;
      jointLeftMarker.position.x=.25;
      jointLeftMarker.visibility=0;
      
      jointRightMarker        = jointLeftMarker.clone("joint_clone2");
      jointLeftMarker.parent  = this.grabLeft.grabJointMesh;
      jointRightMarker.parent = this.grabRight.grabJointMesh;
    //  console.log(" ^^^^^ createJoint%%%% ");
  }
  createCannonVehicle(){
    const sizeXYZ = new BABYLON.Vector3(1.7,1.5,0.6);
    this.robotBox = BABYLON.MeshBuilder.CreateBox("rootbox",{ width:sizeXYZ.x,height:sizeXYZ.y,size:sizeXYZ.z},this.scene);
    this.robotBox.position.set(this.carposition.x,this.carposition.y+1,this.carposition.z);
    this.robotBox.rotation.x= Math.PI/2;
    this.robotBox.isPickable=false;
    this.robotBox.visibility=0;
    this.chassisShape = new CANNON.Box(new CANNON.Vec3(sizeXYZ.x*.5,sizeXYZ.y*.5,sizeXYZ.z*.5));
    this.chassisShape.position = new CANNON.Vec3(0,0,0);
    this.chassisShape.quaternion = new CANNON.Quaternion();
    this.chassisBody = new CANNON.Body({ mass: Utils.robtotBodyMass});
    this.chassisBody.name = "robotbody"
    this.chassisBody.addShape(this.chassisShape,this.chassisShape.position,this.chassisShape.quaternion);
    this.chassisBody.position.set(this.carposition.x,this.carposition.y+1,this.carposition.z);
    this.chassisBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1,0,0),Math.PI / 2);
    this.chassisBody.velocity.set(0, 0, 0);
    this.chassisBody.angularVelocity.set(0, 0, 0);
    this.chassisBody.linearDamping  = this.damping;
    this.chassisBody.angularDamping = this.damping;
    this.babylonCarBox = this.createBabylonChassisBody(this.chassisBody);
    this.babylonCarBox.scaling = new BABYLON.Vector3(Utils.scaleFactor,Utils.scaleFactor,Utils.scaleFactor);
    this.vehicle = new CANNON.RaycastVehicle({chassisBody: this.chassisBody});
    this.options.chassisConnectionPointLocal.set(.69, .76, 0.16);
    this.vehicle.addWheel(this.options);
    this.options.chassisConnectionPointLocal.set(.69,-.76, 0.16);
    this.vehicle.addWheel(this.options);
    this.options.chassisConnectionPointLocal.set(-.69, .76, 0.16);
    this.vehicle.addWheel(this.options);
    this.options.chassisConnectionPointLocal.set(-.69, -.76, 0.16);
    this.vehicle.addWheel(this.options);
    this.vehicle.addToWorld(this.world);
    this.worldManager.boxes.push({pCube:this.chassisBody,vCube:this.robotBox});
    this.worldManager.boxes.push({pCube:this.chassisBody,vCube:this.babylonCarBox});
    // setTimeout(() => {
      this.setRobotRotation(0);
    // }, 3000);
    // let wheelBodies = [];

    for (var i = 0; i < this.vehicle.wheelInfos.length; i++) {
      const wheel = this.vehicle.wheelInfos[i];
      const cylinderShape = new CANNON.Cylinder(wheel.radius,wheel.radius,wheel.radius,20);
      const wheelBody = new CANNON.Body({mass: Utils.weheelBodyMass,});
      wheelBody.type = CANNON.Body.KINEMATIC;

      wheelBody.collisionFilterGroup = 0; // turn off collisions
      const q = new CANNON.Quaternion();
      q.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), Math.PI / 2);
      wheelBody.addShape(cylinderShape, new CANNON.Vec3(), q);
      this.createBabylonWheelBodies(wheelBody);
      this.world.add(wheelBody);
      this.wheelBodies.push(wheelBody);
    }

    let wheelMesh = this.scene.getTransformNodeByName(this.wheel1);
    wheelMesh.scaling = new BABYLON.Vector3(13,13,13);
    // wheelbody.getChildMeshes(
    //   false
    // )[0].material.albedoColor = new BABYLON.Color3.Red();

    const w1 = wheelMesh.clone();
    w1.isVisible = false;
    w1.setEnabled(true);
    w1.name = "w1_1";
    w1.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2);

    const w2 = wheelMesh.clone();
    w2.isVisible = false;
    w2.setEnabled(true);
    w2.name = "w2_1";
    w2.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2);
    w2.scaling.x *= -1;

    const w3 = wheelMesh.clone();
    w3.isVisible = false;
    w3.setEnabled(true);
    w3.name = "w3_1";
    w3.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2);

    const w4 = wheelMesh.clone();
    w4.isVisible = false;
    w4.setEnabled(true);
    w4.name = "w4_1";
    w4.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2);
    w4.scaling.x *= -1;

    wheelMesh.setEnabled(false);
    w1.parent = this.babylonWheelBodies[0];
    w2.parent = this.babylonWheelBodies[1];
    w3.parent = this.babylonWheelBodies[2];
    w4.parent = this.babylonWheelBodies[3];
    // this.world.addEventListener("postStep", () => {
      // this.scene.getTransformNodeByName(this.wheel1).rotation.y = 3;
      // for (let i = 0; i < this.vehicle.wheelInfos.length; i++) {
      //   this.vehicle.updateWheelTransform(i);
      //   let t = this.vehicle.wheelInfos[i].worldTransform;
      //   let wheelBody = this.wheelBodies[i];
      //   wheelBody.position.copy(t.position);
      //   wheelBody.quaternion.copy(t.quaternion);
      //   if (this.babylonWheelBodies[i]) {
      //     this.babylonWheelBodies[i].position           = new BABYLON.Vector3(wheelBody.position.x,wheelBody.position.y,wheelBody.position.z);
      //     this.babylonWheelBodies[i].rotationQuaternion = new BABYLON.Quaternion(t.quaternion.x,t.quaternion.y,t.quaternion.z,t.quaternion.w);
      //   }
      // }
    // });

    this.scene.registerBeforeRender(() => {//runRenderLoop
      if(this.world){
        // for (let i = 0; i < this.worldManager.boxes.length; i++) {
        //   this.worldManager.boxes[i].vCube.position = new BABYLON.Vector3(this.worldManager.boxes[i].pCube.position.x,this.worldManager.boxes[i].pCube.position.y,this.worldManager.boxes[i].pCube.position.z);
        //   this.worldManager.boxes[i].vCube.rotationQuaternion =new BABYLON.Quaternion(
        //       this.worldManager.boxes[i].pCube.quaternion.x,
        //       this.worldManager.boxes[i].pCube.quaternion.y,
        //       this.worldManager.boxes[i].pCube.quaternion.z,
        //       this.worldManager.boxes[i].pCube.quaternion.w
        //     );
        // }
      }
      if (this.babylonCarBox){
          // this.babylonCarBox.position = new BABYLON.Vector3(this.chassisBody.position.x,this.chassisBody.position.y,this.chassisBody.position.z);
          // this.babylonCarBox.rotationQuaternion = new BABYLON.Quaternion(this.chassisBody.quaternion.x,this.chassisBody.quaternion.y,this.chassisBody.quaternion.z,this.chassisBody.quaternion.w);
          if(this.grabLeft){
              this.checkGrabJointMovement(); 
            // const aa = this.pickLeft.grabJointMesh.rotationQuaternion.toEulerAngles();
            // console.log(BABYLON.Angle.FromRadians(aa.z).degrees());
          }
          if(this.getRobotVelocity()>0){
            this.robotIndicator[0].lightMesh.material = this.robotIndicator[0].enableMat;
          }
        else{
            if(this.changetout<20)
                this.robotIndicator[0].lightMesh.material = this.robotIndicator[0].disableMat;
            else if(this.changetout>=20 && this.changetout<40)
                this.robotIndicator[0].lightMesh.material = this.robotIndicator[0].enableMat;
            this.changetout %=40;
            this.changetout++;
        }
      }
      if(this.inputs){
        this.inputs.xInput.value = this.babylonCarBox.position.x.toFixed(2);
        this.inputs.yInput.value = this.babylonCarBox.position.y.toFixed(2);
        this.inputs.zInput.value = this.babylonCarBox.position.z.toFixed(2);
        this.inputs.vInput.value = this.getRobotVelocity().toFixed(2);
      }
    });
    // this.carCtrl();
    return this.vehicle;
  }
  updatewheel(){
    for (let i = 0; i < this.vehicle.wheelInfos.length; i++) {
      this.vehicle.updateWheelTransform(i);
      let t = this.vehicle.wheelInfos[i].worldTransform;
      let wheelBody = this.wheelBodies[i];
      wheelBody.position.copy(t.position);
      wheelBody.quaternion.copy(t.quaternion);
      if (this.babylonWheelBodies[i]) {
        this.babylonWheelBodies[i].position           = new BABYLON.Vector3(wheelBody.position.x,wheelBody.position.y,wheelBody.position.z);
        this.babylonWheelBodies[i].rotationQuaternion = new BABYLON.Quaternion(t.quaternion.x,t.quaternion.y,t.quaternion.z,t.quaternion.w);
      }
    }
  }
  
  createBabylonWheelBodies(body) {
    let shape = body.shapes[0];
    let size = shape.boundingSphereRadius;
    // let wheel = BABYLON.MeshBuilder.CreateCylinder("wheel",{diameterTop:size*2,diameterBottom:size*2,height:size,subdivisions:20},this.scene);
    let wheel = new BABYLON.TransformNode("wheel",this.scene);
    wheel.scaling.set(size * 2,size * 2,size * 2);
    // wheel.rotationQuaternion = new BABYLON.Quaternion();
    wheel.receiveShadows = false;
    wheel.isVisible = true;
    //shadowGenerator.getShadowMap().renderList.push(wheel);
    this.babylonWheelBodies.push(wheel);
  }
  createBabylonChassisBody(body) {
    // for (let i = 0; i < body.shapes.length; i++) {
      // let shape = body.shapes[i];
      // let size = shape.halfExtents;
      this.robotRoot = new BABYLON.TransformNode("robotroot",this.scene);
      // this.robotRoot = BABYLON.MeshBuilder.CreateBox("robotcollidebox",{ height:size.y*2,width: size.x *2, depth: size.z*2},this.scene);
      this.robotRoot.visibility=0;    
      this.robotRoot.isPickable=false;
    // }
    const plane = BABYLON.MeshBuilder.CreatePlane("plane",  {height:.52,width:2.08,sideOrientation: BABYLON.Mesh.DOUBLESIDE},this.scene);
    plane.rotation = new BABYLON.Vector3(0,Math.PI,0);
    plane.position.z+=.15;
    plane.position.x-=.15;
    plane.isPickable=false;
    this.spotLight.excludedMeshes.push(plane);  
    this.hemiLight.excludedMeshes.push(plane);  
    let planemat = new BABYLON.StandardMaterial("namematerial", this.scene);
    planemat.diffuseTexture = new BABYLON.Texture("/textures/RoboSenseiNewLogo.png",this.scene);
    planemat.emissiveColor = new BABYLON.Color3(1,1,1);
    planemat.diffuseTexture.hasAlpha = true;
    plane.material = planemat;
    plane.parent = this.robotRoot;
    
    this.robotBody.parent = this.robotRoot;
    this.centerBox = BABYLON.MeshBuilder.CreateBox("centerBox",{ size: 1 },this.scene);
    this.centerBox.position  = new BABYLON.Vector3(0, 0, 0);
    this.centerBox.parent    = this.robotRoot;
    this.centerBox.isVisible = false;
    this.spotLight.parent = this.robotRoot;
    return this.robotRoot;
  }
  getDist(pickedPoint,myPoint) {
    return Math.abs(BABYLON.Vector3.Distance(pickedPoint, myPoint));
  }
  getRobotVelocity() {
    return Math.round(Math.sqrt(this.chassisBody.velocity.x * this.chassisBody.velocity.x + this.chassisBody.velocity.y * this.chassisBody.velocity.y+
                     this.chassisBody.velocity.z * this.chassisBody.velocity.z));
  }
  applyEnginesPowers(leftEnginePowerp, rightEnginePowerp) {
        const MAX=100;
        let leftEnginePower   = Math.round((leftEnginePowerp *this.maxForce) / MAX)*-1;
        let rightEnginePower  = Math.round((rightEnginePowerp*this.maxForce) / MAX)*-1;
        //  this.leftEngineSpeed  = Math.abs(Math.round((leftEnginePowerp  * Utils.maxVelocity) / 100));
        //  this.rightEngineSpeed = Math.abs(Math.round((rightEnginePowerp * Utils.maxVelocity) / 100));
        leftEnginePower  = this.clamp(leftEnginePower,-this.maxForce,this.maxForce);
        rightEnginePower = this.clamp(rightEnginePower,-this.maxForce,this.maxForce);
        this.checkDirection(leftEnginePower,rightEnginePower,Utils.maxBrakeForce);
        this.steeringValue =  -(leftEnginePower-rightEnginePower)/MAX;
        this.steeringValue /= 4;
        //  console.log(" !!! steer !!!! "+this.steeringValue+"         "+(leftEnginePower - rightEnginePower));
        this.steeringValue = this.clamp(this.steeringValue,-this.maxSteerVal,this.maxSteerVal); 
        let extrapower=1;
          if(Math.abs(leftEnginePower) === Math.abs(rightEnginePower) && Math.abs(this.steeringValue)>0){
              extrapower =-15; 
              this.steeringValue = 0;
          }
        this.leftEnginePower  = leftEnginePower  * extrapower;
        this.rightEnginePower = rightEnginePower * extrapower;
        this.fireEvents();
        this.applyDrive();
  }
  applyDrive(){
        this.chassisBody.linearDamping  = this.damping;
        this.chassisBody.angularDamping = this.damping;

        this.cannonCar.setBrake(0, 0);
        this.cannonCar.setBrake(0, 1); 
        this.cannonCar.setBrake(0, 2);
        this.cannonCar.setBrake(0, 3);

        this.cannonCar.setSteeringValue(this.steeringValue,0);
        this.cannonCar.setSteeringValue(this.steeringValue,1);

        this.cannonCar.applyEngineForce(this.leftEnginePower ,2);
        this.cannonCar.applyEngineForce(this.rightEnginePower,3);
  
        // console.log("!!! leftpower!!! "+this.leftEnginePower+" !!rightpower!!! "+this.rightEnginePower+"      "+extrapower+"        "+this.steeringValue)
        if(this.inputs){
          this.inputs.xInput.value = this.babylonCarBox.position.x.toFixed(2);
          this.inputs.yInput.value = this.babylonCarBox.position.y.toFixed(2);
          this.inputs.zInput.value = this.babylonCarBox.position.z.toFixed(2);
          this.inputs.vInput.value = this.getRobotVelocity().toFixed(2);
        }
    
  }
  checkDirection(leftpower,rightpower,_brake) {
    //  console.log(this.tmpleftPower+" !!! left!!!  "+leftpower+" !!! right!!  "+this.tmpRightPower+" !! !!  "+rightpower);
     if(Math.abs(leftpower)<this.tmpleftPower){
        this.chassisBody.linearDamping  = this.maxDamping;
        this.chassisBody.angularDamping = this.maxDamping;
        this.chassisBody.velocity = new CANNON.Vec3(0,0,0);
        this.sameDirection=0;
        this.fwdDirection=0;
        this.revDirection=0;
        this.cannonCar.setBrake(Utils.maxBrakeForce,2);
     }
     if(Math.abs(rightpower)<this.tmpRightPower){
        this.chassisBody.linearDamping  = this.maxDamping;
        this.chassisBody.angularDamping = this.maxDamping;
        this.chassisBody.velocity = new CANNON.Vec3(0,0,0);
        this.cannonCar.setBrake(Utils.maxBrakeForce,3);
        this.sameDirection=0;
        this.fwdDirection=0;
        this.revDirection=0;
     }
     if(Math.abs(leftpower) === Math.abs(rightpower)){
          if(this.sameDirection===0 && ((leftpower<0 && rightpower>0) || (leftpower>0 && rightpower<0))){
                this.sameDirection =1;
                this.fwdDirection  =0;
                this.revDirection  =0;
                this.applyBrake();
                // console.log( "!!! checkDirection samedirection!!! "+this.tmpleftPower+"      "+this.tmpRightPower);   
          }
          if((this.fwdDirection ===0) && (leftpower<0 && rightpower<0)){
              this.sameDirection =0;
              this.fwdDirection  =1;
              this.revDirection  =0;
              this.applyBrake();
              // console.log( "!!! checkDirection fwdDirection!!! "+this.tmpleftPower+"      "+this.tmpRightPower);   
          }
          if((this.revDirection ===0) && (leftpower>0 && rightpower>0)){
              this.sameDirection = 0;
              this.fwdDirection  = 0;
              this.revDirection  = 1;
              this.applyBrake();
              // console.log( "!!! checkDirection revDirection!!! "+this.tmpleftPower+"      "+this.tmpRightPower);   
          }  
     }
    this.tmpleftPower   = Math.abs(leftpower);
    this.tmpRightPower  = Math.abs(rightpower);
  }
  applyBrake(){
    this.chassisBody.linearDamping  =  this.maxDamping;
    this.chassisBody.angularDamping =  this.maxDamping;
    this.chassisBody.velocity       = new CANNON.Vec3(0,0,0);
    this.cannonCar.setBrake(Utils.maxBrakeForce,2);
    this.cannonCar.setBrake(Utils.maxBrakeForce,3); 
  }
  fireEvents(){
     let event = new CustomEvent(Utils.events.changemotor,{detail:{leftmotorpower:this.leftEnginePower,rightmotorpower:this.rightEnginePower}});
     document.dispatchEvent(event);
  }
  stopEngines() {
    this.applyBrake();
    this.leftEnginePower  = 0;
    this.rightEnginePower = 0;
    this.cannonCar.applyEngineForce(this.leftEnginePower ,2);
    this.cannonCar.applyEngineForce(this.rightEnginePower,3);
  }
  clamp(number, min, max) {
    return Math.max(min, Math.min(number, max));
  }
  setRobotPosition(x,y,z){
      y=1.5;
      // this.carposition = new BABYLON.Vector3(x,y,z);
      this.chassisBody.position   =  new CANNON.Vec3(x,y,z);
      // this.babylonCarBox.position  =  new BABYLON.Vector3(this.chassisBody.position.x,this.chassisBody.position.y,this.chassisBody.position.z);
  }
  setRobotRotation(angle){
    // this.chassisBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0,-1,0),BABYLON.Angle.FromDegrees(angle).radians());
    const quatX = new CANNON.Quaternion();
    const quatY = new CANNON.Quaternion();
    quatX.setFromAxisAngle(new CANNON.Vec3(-1,0,0),Math.PI/2);
    quatY.setFromAxisAngle(new CANNON.Vec3(0,1,0), BABYLON.Angle.FromDegrees(angle).radians());
    const quaternion = quatY.mult(quatX);
    quaternion.normalize();
    this.chassisBody.quaternion = quaternion;
    // this.babylonCarBox.rotationQuaternion = new BABYLON.Quaternion(this.chassisBody.quaternion.x,this.chassisBody.quaternion.y,this.chassisBody.quaternion.z,this.chassisBody.quaternion.w);
    this.chassisBody.velocity.set(0, 0, 0);
    this.chassisBody.angularVelocity.set(0, 0, 0);
    // this.createRobotJoint(angle);
  }
  turnOnRobotLight(){
      this.robotIndicator[1].lightMesh.material = this.robotIndicator[1].enableMat;
      this.robotIndicator[2].lightMesh.material = this.robotIndicator[2].enableMat;
  }
  turnOffRobotLight(){
    this.robotIndicator[1].lightMesh.material = this.robotIndicator[1].disableMat;
    this.robotIndicator[2].lightMesh.material = this.robotIndicator[2].disableMat;
  }
  releaseRobot(){
    this.scene.removeMesh(this.babylonCarBox);
    this.babylonCarBox.dispose();
    this.world.remove(this.chassisBody);
    this.world.remove(this.vehicle);
    for(let i=0;i<this.wheelBodies.length;i++){
      this.world.remove(this.wheelBodies[i]);
      this.scene.removeMesh(this.babylonWheelBodies[i]);
      this.babylonWheelBodies[i].dispose();
    }
    document.removeEventListener("trackcam_event",this.changeCamview);
  }
 initRoboSensor(sceneManager){
// fan-sensor
  // new BABYLON.Vector3(1,0,.7),
   this.arcCam.lockedTarget = this.babylonCarBox;
    this.fanSensor = new FanSensor(
      "robotSensor",
      new BABYLON.Vector3(1.3, 0, 1.17),
      new BABYLON.Vector3(1,0,0),
      this.babylonCarBox, //car body
      5,
      this.scene,
      sceneManager.flameEffect,
      false,
      this
    );
    this.fanComponent = new FanComponentGenerator(
        "fanComponent one",
      new BABYLON.Vector3(1, 0.0, 0.7),
      new BABYLON.Vector3(1, 0, 0),
      this.babylonCarBox,
      1,
      0.8,
      false,
      1, //fanspeed
      this.scene
    );
    const indicatorcolor=[new BABYLON.Color3(0,1,0),new BABYLON.Color3(1,1,0),new BABYLON.Color3(1,0,0)]
    for(let i=0;i<3;i++){
        this.robotIndicator[i] = new LightComponent("robot"+i,{width:.05,height:.07,depth:.12},indicatorcolor[i],
        new BABYLON.Vector3(1.6,-.7-i*.1,.1),this.babylonCarBox,this.scene);
        this.spotLight.excludedMeshes.push(this.robotIndicator[i].lightMesh);  
        this.hemiLight.excludedMeshes.push(this.robotIndicator[i].lightMesh);  
    }
    const dis_callback = (results) => {
      const { state, name, direction } = results;
      // console.log(" results ===>", results)
      if(this.inputs){
        this.inputs.disState.innerHTML = state;
        if (name && direction) {
          this.inputs.dis_name.value = name;
          this.inputs.dis_dir.value = direction;
        }
      }
    };
    //DistanceSensor
    this.distanceSensor = new DistanceSensorGenerator2(
      "distanceSensor",
      new BABYLON.Vector3(1.8,0,0.25),//position
      new BABYLON.Vector3(1,0,0),
      this.babylonCarBox, //car body
      40,
      100,
      false,
      dis_callback,
      this.scene,
    );
    this.spotLight.excludedMeshes.push(this.distanceSensor.lightIndicator.lightMesh);  
    this.hemiLight.excludedMeshes.push(this.distanceSensor.lightIndicator.lightMesh);  
    const flameSensor_callback = (results) => {
      const { value, name, direction, power, state } = results;
      if(this.inputs){
        this.inputs.flameSensorDis.innerHTML = value;
        this.inputs.flameSensorName.value = name;
        this.inputs.flameSensorValue.value = `${power}%`;
        }
    };
    //FlameSensor
    this.flameSensor = new FlameSensorGenerator(
      "FlameSensor One",
      new BABYLON.Vector3(1.3, 0, 1.17),
      new BABYLON.Vector3(1, 0, 0),
      this.babylonCarBox, //car body
      20,
      500,
      false,
      flameSensor_callback,
      this.scene,
    );
    this.spotLight.excludedMeshes.push(this.flameSensor.lightIndicator.lightMesh);  
    this.hemiLight.excludedMeshes.push(this.flameSensor.lightIndicator.lightMesh);  

    const leftIR_callback = (results) => {
      const { state, colorR, colorG, colorB, color,IR } = results;
      if(this.inputs){
        this.inputs.l_surState.innerHTML = state;
        this.inputs.l_surR.value = colorR;
        this.inputs.l_surG.value = colorG;
        this.inputs.l_surB.value = colorB;

        this.inputs.left_IR.value = IR;
        this.inputs.l_surImg.style.backgroundColor = color;
      }
    };
    this.leftcolorSensor = new ColorSensorGenerator(
      "LeftcolorSensor",
      new BABYLON.Vector3(1.6, -0.2, -0.1),
      new BABYLON.Vector3(0, -1, 0),
      this.babylonCarBox,
      3,
      50,
      false,
      leftIR_callback,
      this.scene,
    );
    this.spotLight.excludedMeshes.push(this.leftcolorSensor.lightIndicator.lightMesh);  
    this.hemiLight.excludedMeshes.push(this.leftcolorSensor.lightIndicator.lightMesh);  
    const rightIR_callback = (results) => {
      const { state, colorR, colorG, colorB, color,IR} = results;
      if(this.inputs){
        this.inputs.r_surState.innerHTML = state;
        this.inputs.r_surR.value = colorR;
        this.inputs.r_surG.value = colorG;
        this.inputs.r_surB.value = colorB;
        this.inputs.right_IR.value = IR;
        this.inputs.r_surImg.style.backgroundColor = color;
       }
    };

    this.rightcolorSensor = new ColorSensorGenerator(
      "RightcolorSensor",
      new BABYLON.Vector3(1.6, 0.2, -0.1),
      new BABYLON.Vector3(0, -1, 0),
      this.babylonCarBox,
      3,
      50,
      false,
      rightIR_callback,
      this.scene,
      
    );
    this.spotLight.excludedMeshes.push(this.rightcolorSensor.lightIndicator.lightMesh);  
    this.hemiLight.excludedMeshes.push(this.rightcolorSensor.lightIndicator.lightMesh);  

    this.changeCamview =  (e) => {
      if(e.detail.type.includes("robot")){
          this.arcCam.lockedTarget = this.babylonCarBox;
      }
      else{
          this.arcCam.lockedTarget = new BABYLON.Vector3(0,1,0);
      }
    }
    document.addEventListener("trackcam_event",this.changeCamview);
    document.addEventListener("keydown",(e)=>{
       const key = e.key;
        const power =20;
        const val=.1;
        // console.log("!!! key!!! "+key);
        switch(key){
          case "ArrowUp":
             this.applyEnginesPowers(power,power);  
             SY+=val;
            break;
          case "ArrowDown":
            this.applyEnginesPowers(-power,-power);  
            SY -=val;
            break;
          case "ArrowLeft":
            this.applyEnginesPowers(-power,power);  
            SX-=val;
            break;
          case "ArrowRight":
            this.applyEnginesPowers(power,-power);  
            SX+=val;
            break;
          case " ":
            this.applyEnginesPowers(0,0);  
            this.applyBrake();
            break; 
          case "1":
              SZ+=val;
          break;
          case "2":
              SZ-=val;
            break;
          case "3":
            // this.grabLeft.updateJointLeft(.2);
            // this.grabRight.updateJointRight(-.2);
            break;
          case "4":
            // this.grabLeft.updateJointLeft(-.2);
            // this.grabRight.updateJointRight(.2);
            break;
           case "5":
            //  this.pickLeft.updatePickJoint(-.2);
            //  this.pickRight.updatePickJoint(-.2);
            //  this.pickcenter.updatePickJoint(-.2);
            break ;
            case "6":
            //  this.pickLeft.updatePickJoint(.2);
            //  this.pickRight.updatePickJoint(.2);
            //  this.pickcenter.updatePickJoint(.2);
            break ;
        }
        // this.jointbodyLeft.angularVelocity = new CANNON.Vec3(0,0,SX);
        // this.scene.getMeshByName("handbox").position = new BABYLON.Vector3(SX,SY,SZ);
        // console.log("!! sx!! "+SX+" !!sy!!  "+SY+"!! sz !! "+SZ+"         ");  
        // const quaternion =this.grabRight.grabJointMesh.rotationQuaternion;
        // const euler = quaternion.toEulerAngles();
        // console.log(BABYLON.Angle.FromRadians(euler.y).degrees());
    })
    document.addEventListener("keyup",(e)=>{
        const key = e.code;
        const power =20;
        switch(key){
          case "ArrowUp":
           break;
          case "ArrowDown":
           break;
          case "ArrowLeft":
           this.applyEnginesPowers(0,0);  
           break;
          case "ArrowRight":
           this.applyEnginesPowers(0,0);  
           break;
       }
      //  this.grabLeft.updateJointLeft(0);
      //  this.grabRight.updateJointRight(0);
      //  this.pickLeft.updatePickJoint(0);
      //  this.pickRight.updatePickJoint(0);
      //  this.pickcenter.updatePickJoint(0);
    })
  }
  
  trackCamera(_type){
      this.cameraEvent = new CustomEvent("trackcam_event",{detail:{type:_type}});
      document.dispatchEvent(this.cameraEvent);
  }
  bodiesAreInContact(bodyA, bodyB){
    for(let i=0; i<this.world.contacts.length; i++){
        let c = this.world.contacts[i];
        if((c.bi === bodyA && c.bj === bodyB) || (c.bi === bodyB && c.bj === bodyA)){
            return true;
        }
    }
    return false;
 }
 setjointPickUp(direction,value){
    let speed = direction===0?-MOTORSPEED:MOTORSPEED;
    if(value ===0)
      speed=0;
    this.pickLeft.updatePickJoint(speed);
    this.pickRight.updatePickJoint(speed);
    this.pickLeft.jointRotVal  = value;
    this.pickRight.jointRotVal = value;
    const frameRate = this.scene.getEngine().getFps();
    console.log("!!! framerate!!!  "+frameRate);
    const quaternion = this.pickLeft.grabBody.quaternion;
    const target = new CANNON.Vec3();
    quaternion.toEuler(target);
    let zAngle = parseInt(BABYLON.Angle.FromRadians(target.z).degrees());
    if(zAngle>180)
      zAngle-=360;
    const interVal =setInterval(() => {
      const quaternion = this.pickLeft.grabBody.quaternion;
      const target = new CANNON.Vec3();
      quaternion.toEuler(target);
      let zAngle2 = parseInt(BABYLON.Angle.FromRadians(target.z).degrees());
      if(zAngle2>180)
        zAngle2-=360;
      if(Math.abs(zAngle2-zAngle)>=this.pickLeft.jointRotVal){
        this.pickLeft.stopJoint();
        this.pickRight.stopJoint();
        clearInterval(interVal);
      }
    },1000/frameRate);
 }
 setGrabjoint(value){
  let speed = value>0?MOTORSPEED:-MOTORSPEED;
  if(value ===0)
    speed=0;
  
  this.grabLeft.updatePickJoint(speed);
  this.grabRight.updatePickJoint(-speed);
  this.checkGrabJointMovement();
  this.grabLeft.jointRotVal  = value;
  this.grabRight.jointRotVal = value;
  const frameRate = this.scene.getEngine().getFps();
  let check = 0;
  console.log(" !!!!  "+check+"        "+this.grabLeft.jointRotVal);
  const interVal =setInterval(() => {
      check+=MOTORSPEED;
      console.log(" !!!!  "+check+"        "+this.grabLeft.jointRotVal);
      if(check>= Math.abs(value)){
        this.grabLeft.jointRotVal=0;
        this.grabLeft.stopJoint();
        this.grabRight.jointRotVal=0;
        this.grabRight.stopJoint();
        clearInterval(interVal);
      }
  },1000/frameRate);
}
checkGrabJointMovement(){
  const dis = BABYLON.Vector3.Distance(jointLeftMarker.absolutePosition,jointRightMarker.absolutePosition)
  if(this.grabLeft.motorVal>0 && dis>1.15){
     this.grabLeft.stopJoint();
   }
   else if(this.grabLeft.motorVal<0 && dis<.4){
     this.grabLeft.stopJoint();
   }
   else if(this.grabRight.motorVal<0 && dis>1.15 ){
     this.grabRight.stopJoint();
   } 
   else if(this.grabRight.motorVal>0 && dis<.4 ){
     this.grabRight.stopJoint();
   } 
}
callloop(){
     this.performAcion();
  }
  async performAcion(){
      await sleep(5000);
      this.moveFwd();
  }
  async moveFwd(){
      this.applyEnginesPowers(30,30);  
      await sleep(5000);
      this.moveBack();
  }
  async moveBack(){
      this.applyEnginesPowers(-30,-30);  
      await sleep(5000);
      this.moveFwd();
  }
  
}
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
function GetAngle(d, e) {
	if (d == 0)
		return e >= 0 ? Math.PI / 2 : -Math.PI / 2;
	else if (d > 0)
		return Math.atan(e / d);
	else
		return Math.atan(e / d) + Math.PI;

}
export function getJointDistance(){
  const dis  = BABYLON.Vector3.Distance(jointLeftMarker.absolutePosition,jointRightMarker.absolutePosition);
  return dis;
}