import { events} from "./utils";
export const NONE = 0;
export const BOARD_DISTANCE = 2;
export const candlePositionConfig = {
    "positionmap":[
        // Practice Tracks
        [   
            [{"x":9,"z":4.5    ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
                {"x":4.5,"z":-6.75,"boardno":1,"direction":{"left":1,"right":NONE,"up":1,"down":NONE}}],
            [ {robotposition:{x:4,y:0,z:8,angle:270}}]
        ],
        [   
            [{"x":11.25,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":-6.75,"z":6.75 ,"boardno":1,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":1}}],
            [{robotposition:{x:-4,y:0,z:8,angle:90}}]
        ],
        [   
            [{"x":-9,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":0,"z":6.75 ,"boardno":1,"direction":{"left":NONE,"right":1,"up":NONE,"down":NONE}}],
            [{robotposition:{x:4,y:0,z:-8,angle:135}}]
        ],
        [   
            [{"x":-11.25,"z":-6.75 ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":11.25,"z":6.75  ,"boardno":1,"direction":{"left":NONE,"right":1,"up":1,"down":NONE}}],
            [{robotposition:{x:-4,y:0,z:-8,angle:65}}]
        ],

        // Competition Tracks index 4 through 10
        [
            [{"x":-9,"z":4.5  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
                {"x":9,"z":-2.25  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":1,"down":NONE}},
                {"x":4.5,"z":6.75  ,"boardno":1,"direction":{"left":NONE,"right":1,"up":1,"down":NONE}},
                {"x":-11.25,"z":-6.75  ,"boardno":1,"direction":{"left":1,"right":NONE,"up":1,"down":1}}],
            [{robotposition:{x:0,y:0,z:0,angle:40}}]
        ],
        [  
            [{"x":-9,"z":4.5  ,"boardno":0,"direction":{"left":1,"right":1,"up":NONE,"down":NONE}},
             {"x":9,"z":-2.25  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":4.5,"z":6.75  ,"boardno":1,"direction":{"left":NONE,"right":1,"up":1,"down":1}},
             {"x":-11.25,"z":-6.75  ,"boardno":1,"direction":{"left":1,"right":NONE,"up":NONE,"down":NONE}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ],
        [   
            [{"x":-9,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":9,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":1,"down":NONE}},
             {"x":0,"z":6.75  ,"boardno":1,"direction":{"left":NONE,"right":1,"up":1,"down":NONE}},
             {"x":0,"z":-6.75  ,"boardno":1,"direction":{"left":1,"right":1,"up":1,"down":NONE}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ],
        [   
            [{"x":-9,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":1}},
             {"x":9,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":1,"up":1,"down":NONE}},
             {"x":0,"z":6.75  ,"boardno":1,"direction":{"left":NONE,"right":1,"up":1,"down":1}},
             {"x":0,"z":-6.75  ,"boardno":1,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ],
        [   
            [{"x":-9,"z":6.75  ,"boardno":0,"direction":{"left":NONE,"right":1,"up":NONE,"down":NONE}},
             {"x":9,"z":-6.75  ,"boardno":0,"direction":{"left":1,"right":NONE,"up":1,"down":NONE}},
             {"x":9,"z":6.75  ,"boardno":1,"direction":{"left":1,"right":1,"up":1,"down":NONE}},
             {"x":-9,"z":-6.75  ,"boardno":1,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ],
        [   
            [{"x":-9,"z":6.75  ,"boardno":0,"direction":{"left":1,"right":1,"up":NONE,"down":1}},
             {"x":9,"z":-6.75  ,"boardno":0,"direction":{"left":NONE,"right":1,"up":1,"down":NONE}},
             {"x":9,"z":6.75  ,"boardno":1,"direction":{"left":1,"right":NONE,"up":NONE,"down":NONE}},
             {"x":-9,"z":-6.75  ,"boardno":1,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ],
        [   
            [{"x":-4.5,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":1,"down":1}},
             {"x":-11.25,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":11.25,"z":4.5  ,"boardno":1,"direction":{"left":1,"right":1,"up":1,"down":NONE}},
             {"x":11.25,"z":-4.5  ,"boardno":1,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":1}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ],
        [   
            [{"x":-4.5,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":1}},
             {"x":-11.25,"z":0  ,"boardno":0,"direction":{"left":NONE,"right":1,"up":NONE,"down":1}},
             {"x":11.25,"z":4.5  ,"boardno":1,"direction":{"left":NONE,"right":NONE,"up":NONE,"down":NONE}},
             {"x":11.25,"z":-4.5  ,"boardno":1,"direction":{"left":1,"right":1,"up":NONE,"down":1}}],
            [{robotposition:{x:0,y:0,z:0,angle:0}}]
        ]    
    ]           
};
export default class SceneConfig{

    constructor(){
        this.candleno = 0;
        this.boardno  = 0;
        this.mapno    = 0;
        window["setCandle"]        = this.setCandle.bind(this);
        window["setRobotPosition"] = this.setRobotPosition.bind(this);
        window["setRobotRotation"] = this.setRobotRotation.bind(this);
        // this.setcandleEvent = new Event(events.setcandle_eve, {bubbles:true, cancelable:true});
    }
    setCandle(_candleno,_mapno){
        // console.log("!!  api set!! "+_candleno+"    "+_mapno+"      "+candlePositionConfig.positionmap[0].length);
        _mapno %=candlePositionConfig.positionmap.length;
        this.candleno = _candleno;
        if(this.candleno>candlePositionConfig.positionmap[_mapno][0].length)
            this.candleno =candlePositionConfig.positionmap[_mapno][0].length;
        this.mapno           = _mapno;
        this.setcandleEvent  = new CustomEvent(events.setcandle_eve,{detail:{candleno:this.candleno,mapno:this.mapno}});
        document.dispatchEvent(this.setcandleEvent);
    }
    setRobotPosition(posx,posy,posz){
        const setrobotEvent  = new CustomEvent(events.setrobotPos_eve,{detail:{x:posx,y:posy,z:posz}});
        document.dispatchEvent(setrobotEvent);
    }
    setRobotRotation(rotation){
        const setrobotEvent  = new CustomEvent(events.setrobot_angle,{detail:{angle:rotation}});
        document.dispatchEvent(setrobotEvent);
    }
}


