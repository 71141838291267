import * as BABYLON from "babylonjs";
import * as Utils from "../utils.js";
import { LightComponent } from "../Components/LightComponent.js";
// @name ---- string
// @position ---- vector3 -- which is related to the parent (Robot Car)
// @direction ---- vector3
// @parent ---- Mesh -- Robot car
// @rayLength ---- number
// @samplingRateMs ---- number -- timeOut
// @isVisibleRay ---- boolean
// @onFire --- (result:{})=> void
// @scene ---- active scene

export default class FlameSensorGenerator {
  constructor(
    name,
    position,
    direction,
    parent,
    rayLength,
    samplingRateMs,
    isVisibleRay,
    onFire,
    scene,
  ) {
    this.name = name;
    this.position = position;
    this.direction = direction;
    this.parent = parent;
    this.rayLength = rayLength;
    this.samplingRateMs = samplingRateMs;
    this.isVisibleRay = isVisibleRay;
    this.onFire = onFire;
    this.scene = scene;
    this.init();
    this.sensorValue={};
    
    this.lightIndicator = new LightComponent(name,{width:.05,height:.07,depth:.12},new BABYLON.Color3(1,0,0),
                          new BABYLON.Vector3(position.x+.02,position.y+.12,position.z-.06),this.parent,this.scene);
    this.lightIndicator.lightMesh.rotation.y =  BABYLON.Angle.FromDegrees(90).radians();
    
  }

  init() {
    this.sensorBox = BABYLON.MeshBuilder.CreateBox(`FS_${this.name}`,{ size: 1 },this.scene);
    this.sensorBox.visibility = 0.0;
    this.sensorBox.isPickable = false;
    this.sensorBox.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
    this.sensorBox.position = this.position;

    BABYLON.SceneLoader.ImportMesh("","/models/sensors/","FlameSensor.gltf",this.scene,(meshes, particleSystems, skeletons) => {
        let rootMesh = null;
        for (let i = 0; i < meshes.length; i++) {
          meshes[i].isPickable = false;
          if (meshes[i].name === "__root__") 
            rootMesh = meshes[i];
        }
        rootMesh.parent = this.sensorBox;
        rootMesh.position = new BABYLON.Vector3(-0.5, 0, -.15);
        rootMesh.rotation = new BABYLON.Vector3(0, 1.57, 4.71);
        rootMesh.scaling.multiplyInPlace(new BABYLON.Vector3( 2, -2, 2));
      }
    );
    this.rayCount = 0;
    this.sensorBox.parent = this.parent;
    this.scene.registerBeforeRender(()=> {
      this.callRayCasting();
    });
  }
  vecToLocal(vector, mesh) {
    var m = mesh.getWorldMatrix();
    var v = BABYLON.Vector3.TransformCoordinates(vector, m);
    return v;
  }
  callRayCasting() {
      let origin = this.sensorBox.getAbsolutePosition();
      let forward = this.direction;
      forward = this.vecToLocal(forward, this.sensorBox);
      let direction = forward.subtract(origin);
      direction = BABYLON.Vector3.Normalize(direction);

      if(this.isVisibleRay && this.rayHelper!== undefined)
          this.rayHelper.hide();
      this.ray = new BABYLON.Ray(origin, direction, this.rayLength);
      if(this.isVisibleRay)
        this.rayHelper = BABYLON.RayHelper.CreateAndShow(this.ray,this.scene, new BABYLON.Color3(0,0,1))//new BABYLON.RayHelper(this.ray);		
      else{
          if(this.rayHelper !== undefined)
            this.rayHelper.hide();
      }
      let distanceHit = this.scene.pickWithRay(this.ray);
      let sensorPower = 0;
      let resultObj = {
        value:`Activated (${++this.rayCount})`,
        power: sensorPower,
        picked: false,
        name:"",
        direction:0,
        state:"NA"
      };
      this.sensorValue.power     = resultObj.power;    
      this.sensorValue.name      = resultObj.name ;   
      this.sensorValue.distance  = resultObj.direction;
      this.sensorValue.state     = resultObj.state;
      this.sensorValue.value     = resultObj.value;

      if (distanceHit.pickedMesh && distanceHit.pickedMesh.tag === Utils.EffectsTags.flame) {
        // console.log("rayLength", this.rayLength)
        sensorPower           = ((this.rayLength - distanceHit.distance)/this.rayLength) * 100;
        this.sensorValue.power     = resultObj.power     = sensorPower >= 95 ? 100 :  Math.round(sensorPower);
        this.sensorValue.name      = resultObj.name      = distanceHit.pickedMesh.name;
        this.sensorValue.distance  = resultObj.direction = distanceHit.distance.toFixed(2);
        this.sensorValue.state     = resultObj.state     = distanceHit.pickedMesh.flame.isEnabled();
        resultObj.picked = true;
        if (distanceHit.pickedMesh.material)
          distanceHit.pickedMesh.material.diffuseColor = BABYLON.Color3.Red();
          // console.log("distanceHit.distance -->", distanceHit.distance+" !!! name!!! "+resultObj.name);
          // console.log("!!!IJJJJJJJJJJ Flame!!!");
      }
      this.onFire(resultObj);
      // this.callRayCasting();
    // }, this.samplingRateMs);
  }
}
