import * as BABYLON from "babylonjs";
import * as Utils from "../utils.js";

/**
 * Generate Flame Effect in the scene
 * @param name : String  defines fanComponent name
 * @param position: Vector3 defines the fanComponent position which be related to the parent (robot car)
 * @param direction : Vector3 defines fanComponent direction
 * @param parent : Mesh defines fanComponent parent (robot car)
 * @param coneLength : number cone length
 * @param diameterBottom : number clone diameterBottom
 * @param defaultState : boolean define fanComponent State
 * @param fanSpeed : number define fan rotation speed
 * @param scene : scene active scene
 */

export default class FanComponentGenerator {
  constructor(
    name,
    position,
    direction,
    parent,
    coneLength,
    diameterBottom,
    defaultState,
    fanSpeed,
    scene,
  ) {
    this.name = name;
    this.position = position;
    this.direction = direction;
    this.parent = parent;
    this.coneLength = coneLength;
    this.diameterBottom = diameterBottom;
    this.defaultState = defaultState;
    this.fanSpeed = fanSpeed;
    this.scene = scene;
    this.isFanOn = false;

    
    this.init();
  }

  init() {
    this.fanHolder = BABYLON.MeshBuilder.CreateBox(
      `FC_${this.name}`,
      { size: 1 },
      this.scene
    );
    this.fanHolder.visibility = 0.0;
    this.fanHolder.isPickable = false;
    this.fanHolder.scaling = new BABYLON.Vector3(1, 1, 1);
    this.fanHolder.position = this.position;

    // this.clone = BABYLON.MeshBuilder.CreateCylinder(
    //   `FC_${this.name}`,
    //   {
    //     diameterTop: 0.2,
    //     diameterBottom: this.diameterBottom,
    //     subdivisions: this.coneLength,
    //     height: this.coneLength,
    //   },
    //   this.scene
    // );
    // this.clone.rotation.z = 1.57;
    // this.clone.parent = this.fanHolder;
    // this.clone.position.x = this.coneLength / 2;
    // this.clone.visibility = 1.0;
    

    BABYLON.SceneLoader.ImportMesh("","/models/sensors/","Fan.gltf",this.scene,
      (meshes, particleSystems, skeletons) => {
        let rootMesh = null;
        let bladesMesh = null;

        for (let i = 0; i < meshes.length; i++) {
          meshes[i].isPickable = false;
          if (meshes[i].name === "__root__") rootMesh = meshes[i];
          else if (meshes[i].name === "Fan.001") bladesMesh = meshes[i];
        }
        rootMesh.position = new BABYLON.Vector3(1.1, -1.5, -0);
        rootMesh.rotation = new BABYLON.Vector3(0, 1.57, 0);
        rootMesh.scaling.multiplyInPlace(new BABYLON.Vector3(20, 20, 20));
        rootMesh.parent = this.fanHolder;
        // do something with the meshes and skeletons
        // particleSystems are always null for glTF assets

        bladesMesh.rotation = new BABYLON.Vector3.Zero();
        this.bladesMesh = bladesMesh;
        this.initAnimation();
      }
    );

    this.fanHolder.parent = this.parent;

    // var diffX = pickResult.pickedPoint.x - box.position.x;
    // var diffY = pickResult.pickedPoint.z - box.position.z;
    // box.rotation.y = Math.atan2(diffX,diffY);

    // let box = BABYLON.MeshBuilder.CreateBox(
    //   "www",
    //   { size: 0.2 },
    //   this.scene
    // );

    // box.position =this.fanHolder.position.add(this.direction.multiplyByFloats(1,1,1));

    // this.fanHolder.lookAt(box.position)
  }
  initAnimation() {
    var blateAnimation = new BABYLON.Animation("blateAnimation","rotation.z",100,BABYLON.Animation.ANIMATIONTYPE_FLOAT,BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
    var keyFrames = [];
    keyFrames.push({frame: 0,value: 0,});
    keyFrames.push({frame: 100,value: 2 * Math.PI,});
    blateAnimation.setKeys(keyFrames);
    // this.fanSpeed
    this.bladesMesh.animations.push(blateAnimation);
    // console.log("this.initAnimation");
  }
  runFanAnimation(state) {
    console.log("this.bladesMesh.animations");
    this.isFanOn = state;
    if (state) {
        this.scene.beginAnimation(this.bladesMesh, 0, 100, true, this.fanSpeed);
    } else {
        this.scene.stopAnimation(this.bladesMesh);
    }
  }
}
