// https://schteppe.github.io/cannon.js/
// I am having fever & facing back pain because slip on the bike. i was supposed to be will be fine on Monday but unfortunately i am not able to start work.
// that is my situation 
// dr suggested me to take rest 
import * as BABYLON from "babylonjs";
import * as CANNON from "cannon";
import { getJointDistance } from "../CarManager";
const minAngularDamping=.2;
const maxAngularDamping=.999;
export const MOTORSPEED =.2;
export default class Myjoints{
    constructor(_name,root,size,position,_mass,color){
            this.root = root;
            this.name = _name
            this.angleVal=0;
            this.connectedBody;
            this.grabJointMesh          = BABYLON.MeshBuilder.CreateBox("handbox",{ width:size.x,height:size.y,size:size.z},root.scene);  
            this.grabJointMesh.position = new BABYLON.Vector3(position.x,position.y,position.z);
            const mat                   = new BABYLON.StandardMaterial("joint_mat");
            mat.diffuseColor            = new BABYLON.Color3.FromInts(color.r,color.g,color.b);
            this.grabJointMesh.material = mat;
            const shape                 = new CANNON.Box(new CANNON.Vec3(size.x*.5,size.y*.5,size.z*.5));
            this.grabBody               = new CANNON.Body({ mass:_mass});
            this.grabBody.addShape(shape);
            this.grabBody.position = new CANNON.Vec3(this.grabJointMesh.position.x,this.grabJointMesh.position.y,this.grabJointMesh.position.z);
            this.grabBody.velocity.set(0,0,0);
            this.grabBody.angularVelocity.set(0,0,0);
            this.grabBody.linearDamping  = maxAngularDamping;
            this.grabBody.angularDamping = maxAngularDamping;
            this.contactMat = new CANNON.Material("jointbody_mat");
            this.contactMat.restitution=0;
            this.contactMat.friction=.5;
            this.grabBody.material = this.contactMat;
            this.root.worldManager.boxes.push({pCube:this.grabBody,vCube:this.grabJointMesh});
            this.grabBody.name = this.name;
            this.motorVal=0;
            this.jointRotVal=0;
    }
    createJoint(connectedBody,pivotA,axisA,pivotB,axisB,force,robot_rotation){
        this.connectedBody =connectedBody;
        this.grabJoint = new CANNON.HingeConstraint(connectedBody,this.grabBody,{
            pivotA:pivotA,
            axisA: axisA,
            pivotB:pivotB,
            axisB: axisB,
            maxForce:force,
        });
        this.grabJoint.collideConnected=false;
        this.grabJoint.enableMotor();
        this.grabJoint.setMotorSpeed(0);
        this.grabJoint.motorMaxForce  = force;
        this.grabJoint.motorMinForce  = force;
        // console.log(this.grabJoint.motorMaxForce+"         "+this.grabJoint.motorMinForce)
        this.grabJoint.update();
        const quatX = new CANNON.Quaternion();
        // if(this.name.includes("left"))
        //     quatX.setFromAxisAngle(new CANNON.Vec3(1,0,0),Math.PI/2);
        // else 
        //     quatX.setFromAxisAngle(new CANNON.Vec3(-1,0,0),Math.PI/2);
        const quatY = new CANNON.Quaternion();
        quatY.setFromAxisAngle(new CANNON.Vec3(0,1,0), BABYLON.Angle.FromDegrees(robot_rotation).radians());
        const quaternion = quatY.mult(quatX);
        quaternion.normalize();
        this.grabBody.quaternion = quaternion;
        this.root.worldManager.world.add(this.grabBody);
        this.root.worldManager.world.addConstraint(this.grabJoint);
        this.jointRotVal=0;
        this.motorVal=0;
    }
    updateJointLeft(motor){
        this.motorVal = motor;
        if(motor ===0){
            this.grabJoint.setMotorSpeed(0); 
            this.grabBody.angularDamping=.9999;
        }
        else{
            const dis = getJointDistance();
            if(motor <0 && dis<.4){ //this.angleVal<=-20
                motor =0;
                this.motorVal = motor;
                this.grabBody.angularDamping=maxAngularDamping;
            }
            else if( motor >0 && dis>1.14){
                motor =0;
                this.motorVal = motor;
                this.grabBody.angularDamping=maxAngularDamping;
            }
            else{  
                this.angleVal +=motor;
                this.grabJoint.update();
                this.grabBody.angularDamping=minAngularDamping;
            }
            this.grabJoint.setMotorSpeed(motor); 
        }
      }
    updateJointRight(motor){
        this.motorVal = motor;
        if(motor===0){
            this.grabJoint.setMotorSpeed(motor); 
            this.grabBody.angularDamping=.9999;
        }
         else{
                const dis = getJointDistance();
                if(motor <0 && dis>1.14){
                    motor=0;
                    this.motorVal = motor;
                    this.grabBody.angularDamping=maxAngularDamping;
                }
                else if(motor >0 && dis<.4){
                    motor=0;
                    this.motorVal = motor;
                    this.grabBody.angularDamping=maxAngularDamping;
                }
                else{  
                    this.angleVal +=motor;
                    this.grabBody.angularDamping=minAngularDamping;
                    this.grabJoint.update();
                }
                this.grabJoint.setMotorSpeed(motor); 
           }
      }
      stopJoint(){
        this.motorVal = 0;
        this.grabBody.angularDamping=.9999;
        this.grabJoint.setMotorSpeed(0); 
        this.grabJoint.update();
      }
      updatePickJoint(motor){
        this.motorVal = motor;
         if(motor===0){
            this.grabJoint.setMotorSpeed(0); 
            this.grabBody.angularDamping=.9999;
         }
         else{
            const quaternion1 = this.grabBody.quaternion;
            const target = new CANNON.Vec3();
            quaternion1.toEuler(target);
            let zAngle = parseInt(BABYLON.Angle.FromRadians(target.z).degrees());
            if(zAngle>180)
                zAngle-=360;
            if(motor<0 && zAngle>=50){
                motor =0;
                this.motorVal = motor;
                this.grabJoint.setMotorSpeed(0); 
                this.grabBody.angularDamping=maxAngularDamping;
            }
            else if(motor>0 && zAngle<=-15 ){
                motor =0;
                this.motorVal = motor;
                this.grabJoint.setMotorSpeed(0); 
                this.grabBody.angularDamping=maxAngularDamping;
            }
            else{
                this.grabJoint.setMotorSpeed(motor); 
                this.grabJoint.update(); 
                this.grabBody.angularDamping=minAngularDamping;
            }
         }  
      }
      
}
