// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 12;
  overflow: hidden;
}



/* Solid border */
hr.solid {
  border-top: 1px solid #bbb;
  margin-top: 15px;
  margin-bottom: 15px;
}

  #l_ui{
      padding: 20PX;
      border-style: solid;
      border-color: gray;
      border-radius: 5px;
      position: absolute;
      top : 20px;
      left : 20px;
      color: black;
      background-color: wheat;
  }
  
  #r_ui{
    padding: 30PX;
    border-style: solid;
    border-color: gray;
    border-radius: 5px;
    position: absolute;
    /* top : 20px; */
    right : 60px;
    color: black;
    background-color: #e4e4e4;
}

  .btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 25px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 37px;    
  }
  
    /* Blue */
  .info {
    border-color: #2196F3;
    color: dodgerblue;
    text-align: center;
  }
  
    .info:hover {
      background: #2196F3;
      color: white;
    }

  /* Red */
  .danger {
    border-color: #f44336;
    color: red;
    margin-top: 8px;
    text-align: center;
    width: 116px;
  }
  .danger:hover {
    background: #f44336;
    color: white;
  }

  #leftEngineInput, #rightEngineInput{
    width: 60px;

  }
  #leftEngineInput{
    margin-left: 8px;

  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-left: 50px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    overflow: hidden;
    
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .btn-primary
  {
    position: absolute;
    left: 50%;
    top:  50%;
    align-self: center;
    transform: translate(-50%, -50%);
  }
  #myvideo{
    position: absolute;
    width: 480px;
    height: 320px;
    left: 0%;
    top: 0%;
    border: #f44336;
    display: none;
  }
  #fps {
    position: absolute;
    background-color: black;
    border: 2px solid red;
    text-align: center;
    font-size: 16px;
    color: white;
    top: 15px;
    right: 10px;
    width: 60px;
    height: 20px;
}

.loader {
  position: absolute;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 2s linear infinite;
  left: 50%;
  top: 50%;
  margin-top: -32px;
  transform: translate(-50%, -50%);
  display: none;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
