import * as BABYLON from "babylonjs";
import * as BABYLONMaterials from "babylonjs-materials";
import * as Utils from "../utils.js";
import * as units from "../units.js";
import * as CANNON from "cannon";

/**
 * Generate Flame Effect in the scene
 * @param name : string  defines flameEffect name
 * @param position: vector2 defines the flameEffect position X AND Z
 * @param cylinderSize : {height : number, diameter: number  } defines clyinder size
 * @param cylinderColor : string defines the flameEffect color
 * @param flameWrraperSize : number flame Wrraper Size
 * @param flameState : boolean flame state
 * @param flameSize : number flame Size
 * @param worldManager : worldManager
 * @param onFire : (results)=>:void callback function run onFire (Future)
 * @param withPlate : boolean -- detect if you want to create it with below plate or not -- defualt is false
 * @param plateConfig? : {width:number, depth:number, lineColor:string, color: string, thickness:number} -- plate config object
 * @param scene : scene active scene
 */

export default class FlameEffectGenerator {
  constructor(
    name,
    position,
    cylinderSize,
    cylinderColor,
    flameWrraperSize,
    flameState,
    flameSize,
    worldManager,
    scene,
    robot,
  ) {
    this.name = name;
    this.position = position;
    this.cylinderSize = cylinderSize;
    this.cylinderColor = cylinderColor;
    this.flameWrraperSize = flameWrraperSize;
    this.flameState = flameState;
    this.flameSize = flameSize;
    this.worldManager = worldManager;
    this.scene = scene;
    this.flamMesh;
    this.cylinderBody;
    this.circle,
    this.robot = robot;
    this.candle;
    this.isCollide = false;
    this.init();
  }

  init() {
    if(!this.scene)
      return;
    this.scene.getNodeByID("Circle").setEnabled(true);
    this.circle = this.scene.getNodeByID("Circle").clone("newCircle");
    this.circle.position = new BABYLON.Vector3(this.position.x,Utils.groundY+.01,this.position.y);
    this.circle.scaling  = new BABYLON.Vector3(.5,.5,.5);

    this.scene.getNodeByID("Circle").setEnabled(false);

    const cylinderShape     = new CANNON.Cylinder(this.cylinderSize.diameter,this.cylinderSize.diameter,this.cylinderSize.height,20);
    // const cylinderShape    = new CANNON.Box(new CANNON.Vec3(this.cylinderSize.diameter,this.cylinderSize.height,this.cylinderSize.diameter));
    this.cylinderBody      = new CANNON.Body({mass:.1,shape: cylinderShape,linearDamping:.2,angularDamping:.2});//7.5
    this.cylinderBody.name =  `flame_${this.name}`;
    this.cylinderBody.angularVelocity = new CANNON.Vec3(0,0,0);
    // this.cylinderBody.quaternion.set(0,1,0,0);
    // this.cylinderBody.material = this.bodyMat;
    // this.cylinderBody.inertia = new CANNON.Vec3(0,0,0);
    this.bodyMat = new CANNON.Material("ground_obj");
    this.cylinderBody.material = this.bodyMat;
    this.cylinderBody.material.restitution=0;
    this.cylinderBody.material.friction=.5;

    let cylinderMat = new BABYLON.StandardMaterial("cylinderMat", this.scene);
    cylinderMat.diffuseColor = BABYLON.Color3.FromHexString(this.cylinderColor);
    this.cylinderHolder = BABYLON.MeshBuilder.CreateCylinder( this.cylinderBody.name,{height:this.cylinderSize.height,diameterTop:this.cylinderSize.diameter,diameterBottom:this.cylinderSize.diameter,subdivisions:20});
    // this.cylinderHolder = BABYLON.MeshBuilder.CreateBox( this.cylinderBody.name,{height:this.cylinderSize.height*2,width:this.cylinderSize.diameter*2,depth:this.cylinderSize.diameter*2});
    this.cylinderHolder.actionManager = new BABYLON.ActionManager(this.scene);
    this.cylinderHolder.isPickable = true;
    this.cylinderHolder.position = new BABYLON.Vector3(this.position.x,Utils.groundY+.01,this.position.y);
    this.cylinderHolder.material = cylinderMat;
    this.cylinderHolder.visibility = 0;

    this.scene.getNodeByID("Candle").setEnabled(true);
    this.candle = this.scene.getNodeByID("Candle").clone("newCandle");
    this.candle.parent     =  this.cylinderHolder;
    this.candle.name = this.cylinderHolder.name;
    this.candle.scaling    =  new BABYLON.Vector3(this.cylinderSize.diameter*10,this.cylinderSize.height*2,this.cylinderSize.diameter*10);
    
    const yy = this.cylinderHolder.geometry._extend.maximum.y;
    console.log(yy);
    this.candle.position   =  new BABYLON.Vector3(0,this.cylinderHolder.absolutePosition.y-yy,0);
    
    this.candle.isPickable =  false;
    this.scene.getNodeByID("Candle").setEnabled(false);
    
    
    // var q = new CANNON.Quaternion();
    // q.setFromEuler(Math.PI / 2, 0, 0, "XYZ");
    // this.cylinderBody.addShape(cylinderShape,new CANNON.Vec3(),q);
    const quat = new CANNON.Quaternion();
    quat.setFromAxisAngle(new CANNON.Vec3(-1,0,0),-Math.PI/2);
    const translation = new CANNON.Vec3(0,0,0);
    this.cylinderBody.shapes[0].transformAllPoints(translation,quat);
    this.cylinderBody.position.set(this.position.x,Utils.groundY,this.position.y);
    this.worldManager.world.add(this.cylinderBody);
    this.worldManager.boxes.push({pCube: this.cylinderBody,vCube: this.cylinderHolder,});

    this.cylinderBody.addEventListener("collide", (e)=>{
      if(e.body.name === "robotbody"){
          e.body.velocity = new CANNON.Vec3(Math.round(e.body.velocity.x),Math.round(e.body.velocity.y),Math.round(e.body.velocity.z));
          // let val = this.cylinderBody.mass*2;
          let val = Math.round(Math.sqrt(e.body.velocity.x * e.body.velocity.x + e.body.velocity.y * e.body.velocity.y+e.body.velocity.z * e.body.velocity.z));
          let impulse = new CANNON.Vec3(0,val*.05,0);
          if(Math.abs(e.body.velocity.x)>0){
            impulse.x = e.body.velocity.x>0?val:-val;
            impulse.x *=.05;
          }
          else{   
              let flag = parseInt(getRandomNumber(0,2));
              impulse.x =  val * (flag===0?(-getRandomNumber(1.1,1.3)):(getRandomNumber(1.1,1.3)));
              impulse.x *=.05;
          }

          if(Math.abs(e.body.velocity.z)>0){
            impulse.z = e.body.velocity.z>0?val:-val;
            impulse.z *=.05;
          }
          else{   
              let flag = parseInt(getRandomNumber(0,2));
              impulse.z = val * (flag===0?(-getRandomNumber(1.1,1.3)):(getRandomNumber(1.1,1.3)));
              impulse.z *=.05;
          }
          this.cylinderBody.applyImpulse(impulse,this.cylinderBody.position);
          this.cylinderBody.angularVelocity =  impulse.mult(1,impulse);
          this.fireCollisionEvent(this.name);
          // console.log(impulse);
      }
    });
    //////
    this.flameWrrpaer = BABYLON.MeshBuilder.CreateBox("flameWrrpaer",{ size:this.flameWrraperSize},this.scene);
    this.flameWrrpaer.name = this.cylinderHolder.name
    this.flameWrrpaer.scaling.y *= 1.5;
    this.flameWrrpaer.scaling.z *= 1.25;
    this.flameWrrpaer.scaling.x *= 1.25;
    this.flameWrrpaer.parent = this.cylinderHolder;
    console.log(this.candle.position.y);
    this.flameWrrpaer.position.y = this.candle.position.y+yy+this.flameWrrpaer.scaling.y/2//this.cylinderHolder.absolutePosition.y+this.flameWrrpaer.scaling.y/2;
    // console.log(this.flameWrrpaer.position.y+"        "+this.flameWrrpaer.scaling.y);
    this.flameWrrpaer.isPickable = true;
    this.flameWrrpaer.tag = Utils.EffectsTags.flame;
    // this.flameWrrpaer.onDetect = this.onDetect;
    this.flameWrrpaer.visibility = 0;

    this.flameWrrpaer.actionManager = new BABYLON.ActionManager(this.scene);

    if(this.scene.getMeshByName("pickedGround")!=null){
        this.flameWrrpaer.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction({
                trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
                parameter: { mesh:this.scene.getMeshByName("pickedGround"),usePreciseIntersection: true}
              },
              () => {
                  this.flameWrrpaer.setEnabled(false);
                  this.flame.setEnabled(false);
                  let dis =  BABYLON.Vector3.Distance(this.cylinderHolder.position,this.robot.position);    
                  let candleCollideEvent = new CustomEvent(Utils.events.candleDown,{detail:{id:this.name,candleFlame:false,distance:Number(units.getValueByUnitType(dis,units.UnitTypes.mm))}});
                  document.dispatchEvent(candleCollideEvent);
                  
                // this.flame.stop();
              }
          )
       );
    }

    //createFlame
    this.flamMesh = BABYLON.MeshBuilder.CreateBox("flameMesh",{size:.5},this.scene);
    this.flamMesh.visibility = 0.0;
    this.flamMesh.parent = this.cylinderHolder;
    this.flamMesh.position.y = this.flameWrrpaer.position.y;
    this.flamMesh.isPickable = false;

    this.flame = BABYLON.Mesh.CreatePlane("fireplane",1,this.scene);
    this.flame.scaling.x = 0.1;
    this.flame.scaling.y = 0.7;
    this.flame.position = new BABYLON.Vector3(0,0,0);
    this.flame.billboardMode = BABYLON.Mesh.BILLBOARDMODE_Y;  
    
    let fire = new BABYLONMaterials.FireMaterial("fire",this.scene);
    fire.diffuseTexture = new BABYLON.Texture("/textures/flame/fire.png", this.scene);
    fire.distortionTexture = new BABYLON.Texture("/textures/flame/distortion.png",this.scene);
    fire.opacityTexture = new BABYLON.Texture("/textures/flame/candleopacity.png",this.scene);
    fire.speed = 5.0;

    this.flame.material = fire;
    this.flame.parent = this.flamMesh;
    this.flameWrrpaer.flame = this.flame;

    // flameData.default.systems[0].maxLifeTime = this.flameSize;
    // this.flame = BABYLON.ParticleSystemSet.Parse(
    //   flameData.default,
    //   this.scene,
    //   false
    // ).systems[0];
    // if (this.flameState) this.flame.start();
    // this.flame.emitter = flamMesh;

    //
    

    // setTimeout(() => {
    //   console.log("this ",this)
    //   this.flameWrrpaer.flame.particleEmitterType.directionRandomizer = 1;
    // }, 5000);
  }
  // checkCollisionRobot()
  // {
  //     this.cylinderHolder.actionManager.registerAction(new BABYLON.ExecuteCodeAction({trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
  //           parameter: {mesh:this.scene.getMeshByName("robotcollidebox"),usePreciseIntersection:true}},() => {
  //           // this.fireCollisionEvent();
  //         }
  //       )
  //     );
  // }
  fireCollisionEvent(name){
      let dis =  BABYLON.Vector3.Distance(this.cylinderHolder.position,this.robot.position);    
      let candleCollideEvent = new CustomEvent(Utils.events.candleRobotCollision,{detail:{id:name,candleFlame:this.flame.isEnabled()?true:false,distance:Number(units.getValueByUnitType(dis,units.UnitTypes.mm))}});
      document.dispatchEvent(candleCollideEvent);
  }
  removeCandlefromScene(){

    this.scene.removeMesh(this.cylinderHolder);
    this.cylinderHolder.dispose();

    this.scene.removeMesh(this.candle);
    this.candle.dispose();
    

    this.scene.removeMesh(this.flameWrrpaer);
    this.flameWrrpaer.dispose();


    this.scene.removeMesh(this.flamMesh);
    this.flamMesh.dispose();

    this.scene.removeMesh(this.circle);
    this.circle.dispose();
    
    
    if(this.worldManager && this.worldManager.world)
      this.worldManager.world.remove(this.cylinderBody);


  }
}
export function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}