import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import LoaderManager from "../LoaderManager.js";
import FlameEffectGenerator from "../Effects/FlameEffectGenerator.js";
import ObstacleGenerator from "../Helper/ObstacleGenerator.js";
import WorldManager from "../WorldManager.js";
import * as Utils from "../utils.js";
import Common from '../Common.js'
import MyRobotWrapper from "../Wrapper/MyRobo.js";
import SceneConfig from '../SceneConfig';
import { candlePositionConfig } from "../SceneConfig";
import { BOARD_DISTANCE } from "../SceneConfig";
import SceneOptimiser from "../SceneOptimiser.js";
import BallonAnim from "../BallonAnim.js";

const CANDLE_STATE = { state1: "UPRIGHT", state2: "ON THE GROUND" };
class CandleInfo {
  constructor(_name) {
    this.info = {
      name: _name,
      candlesPosition: CANDLE_STATE.state1,
      isflameon: true,
      OnFlameOff: {
        IsFlameOffByFan: false,
        RobotDistanceFromCandle: undefined,
        IsRobotTouchingCandle: false
      }
    }
    this.isFlameExtinguish = false;
    this.isRobotTouch = false;
  }
}
class BoardInfo {
  constructor(name) {
    this.name = name;
    this.position = "UPRIGHT";
    this.collideRobot = false;
  }
}
export default class FireFightingScene {
  constructor(gameManager) {
    this.game = gameManager;
    this.sceneCommon = new Common(this.game);
    this.scene = this.sceneCommon.createScene("firefightingscene");
    this.arcCam = this.sceneCommon.createCamera(this.scene);
    this.isUi = false;
    this.rayState = false;
    //TilesHolder
    this.tiles = [];
    this.mysceneConfig = new SceneConfig();
    this.robotInterFace = new MyRobotWrapper();
    this.flameEffect = [];
    this.board = [];
    this.objMaterial;
    this.initScene();
    this.game.engine.hideLoadingUI();
    this.boardEvent = new Event(Utils.events.boardDown);
    this.sceneOptimiser = new SceneOptimiser(50, 500, this.scene);
    //this.sceneOptimiser.startOptimiser();
    this.candleState = [];
    this.boardState = [];
    window["getCandleState"] = this.getCandleState.bind(this);
    window["getFireFightingEvents"] = this.getFireFightingEvents.bind(this);
    // window["setMap"] = this.setMap.bind(this);

  }
  initScene() {
    //Physics World
    this.ballonObj = new BallonAnim(this.scene);
    const ballonsphere = BABYLON.MeshBuilder.CreateSphere("ballonsphere", { diameter: .5 }, this.scene); //scene is 
    let ballonmat = new BABYLON.StandardMaterial("ballonMat", this.scene);
    ballonmat.diffuseColor = new BABYLON.Color3(1, 0, 0);
    ballonsphere.material = ballonmat;
    this.ballonObj.setBallonObj(ballonsphere);

    this.worldManager = new WorldManager(this.scene, "textures/name.png");
    this.loaderManager = new LoaderManager(this);
    this.loaderManager.initializeSceneAssets();
    this.initializeEnvironMent();


    this.leftPanel = document.getElementById("l_ui");
    this.rightPanel = document.getElementById("r_ui");

    this.simulateBut = document.getElementById("simulate");
    this.stopBut = document.getElementById("stop");

    this.lInput = document.getElementById("leftEngineInput");
    this.rInput = document.getElementById("rightEngineInput");

    this.Inputs = {
      xInput: document.getElementById("pos_x"), // x input
      yInput: document.getElementById("pos_y"), // y input
      zInput: document.getElementById("pos_z"), // z input
      vInput: document.getElementById("velocity"), //Velocity Input
      //
      l_surState: document.getElementById("l_sur_state"), //sur_state Input
      l_surR: document.getElementById("l_sur_r"), //sur_color_r Input
      l_surG: document.getElementById("l_sur_g"), //sur_color_g Input
      l_surB: document.getElementById("l_sur_b"), //sur_color_b Input
      l_surImg: document.getElementById("l_sur_img"), //sur_color_b Input
      left_IR: document.getElementById("left_ir"), //sur_ir value
      //
      r_surState: document.getElementById("r_sur_state"), //sur_state Input
      r_surR: document.getElementById("r_sur_r"), //sur_color_r Input
      r_surG: document.getElementById("r_sur_g"), //sur_color_g Input
      r_surB: document.getElementById("r_sur_b"), //sur_color_b Input
      r_surImg: document.getElementById("r_sur_img"), //sur_color_b Input
      right_IR: document.getElementById("right_ir"), //sur_ir value


      //
      disState: document.getElementById("dis_state"), //sur_state Input
      dis_name: document.getElementById("dis_name"), //sur_color_r Input
      dis_dir: document.getElementById("dis_dir"), //sur_color_r Input

      flameSensorDis: document.getElementById("flameSensor_state"), //sur_state Input
      flameSensorName: document.getElementById("flameSensor_name"), //sur_color_r Input
      flameSensorValue: document.getElementById("flameSensor_value"), //sur_color_r Input
    };
    // //init LoadManager
    this.robotInterFace.setLoaderManager(this.loaderManager);
    this.addFireFightingEventListner();
    this.simulateBut.addEventListener("click", () => {
      //confirm Button
      this.loaderManager.carManager.applyEnginesPowers(this.lInput.value, this.rInput.value);
    });
    this.stopBut.addEventListener("click", () => {
      //confirm Button  
      this.loaderManager.carManager.stopEngines();
    });

    this.scene.onKeyboardObservable.add((kbInfo) => {
      switch (kbInfo.type) {
        case BABYLON.KeyboardEventTypes.KEYDOWN:
          // console.log("KEY DOWN: ", kbInfo);
          if (kbInfo.event.keyCode === 72) {
            //h
            this.leftPanel.style.display = this.isUi ? "none" : "block";
            this.rightPanel.style.display = this.isUi ? "none" : "block";
            this.isUi = !this.isUi;
          } else if (kbInfo.event.keyCode === 74) {
            //j
            // this.loaderManager.carManager.toggleRays(this.rayState);
            // this.rayState = !this.rayState;
          } else if (kbInfo.event.keyCode === 66) {
            //B
            // this.loaderManager.carManager.handleCreateBalls();
          } else if (kbInfo.event.keyCode === 32) {
            //SPACE
            // this.loaderManager.carManager.openBoxDoor();
          }
          break;
      }
    });
    // this.scene.debugLayer.show();
    // return this.scene;
  }
  initializeEnvironMent() {
    this.initVisualScene();
  }

  initVisualScene() {
    //here we can create scene elements (ground,borders,etc)
    //Ground
    let sizeV3 = new BABYLON.Vector3(Utils.groundWidth, 0.1, Utils.groundDepth); // Ground Size
    let ground = this.worldManager.createGroundTile("Ground", new BABYLON.Vector3(0, Utils.groundY, 0), sizeV3, 0);
    ground.vBody.tag = "ground";

    //create trigger ground
    var pickedGround = BABYLON.MeshBuilder.CreateBox("pickedGround", { height: sizeV3.y, width: sizeV3.x, depth: sizeV3.z }, this.scene);
    pickedGround.position = new BABYLON.Vector3(0, 0, 0);
    pickedGround.rotationQuaternion = new BABYLON.Quaternion.FromEulerAngles(0, 0, 0);
    pickedGround.visibility = 0;
    pickedGround.isPickable = false;
    //pickedGround.material = this.groundMat;

    //Create CubicTexture
    // let skyboxCubecTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("Textures/environment/autoshop_01_4k.env",/* "./environment/Panorama.env",*/this.scene);
    // skyboxCubecTexture.gammaSpace = true;
    // skyboxCubecTexture.level = 1;
    // this.scene.environmentTexture = skyboxCubecTexture;

    //Left
    sizeV3 = new BABYLON.Vector3(Utils.groundWidth, Utils.border.height, Utils.border.depth); // Border Size
    let leftBorder = this.worldManager.createGroundTile("Left", new BABYLON.Vector3(0, Utils.border.height / 2, -(Utils.groundDepth / 2) + -Utils.border.depth / 2), sizeV3, 1);

    //Right
    sizeV3 = new BABYLON.Vector3(Utils.groundWidth, Utils.border.height, Utils.border.depth); // Border Size
    let rightBorder = this.worldManager.createGroundTile("Right", new BABYLON.Vector3(0, Utils.border.height / 2, Utils.groundDepth / 2 + Utils.border.depth / 2), sizeV3, 1);

    //Forward
    sizeV3 = new BABYLON.Vector3(Utils.border.depth, Utils.border.height, Utils.groundDepth); // Border Size
    let forwardBorder = this.worldManager.createGroundTile("Forward", new BABYLON.Vector3(Utils.groundWidth / 2 + Utils.border.depth / 2, Utils.border.height / 2, 0), sizeV3, 1);
    //BackWard
    sizeV3 = new BABYLON.Vector3(Utils.border.depth, Utils.border.height, Utils.groundDepth); // Border Size
    let backBorder = this.worldManager.createGroundTile("BackWard", new BABYLON.Vector3(-Utils.groundWidth / 2 + Utils.border.depth / 2, Utils.border.height / 2, 0), sizeV3, 1);

    //   BABYLON.SceneLoader.ImportMesh("","/models/scene/","Fire_Challenge.glb",this.scene,(meshes, particleSystems, skeletons) => {
    //     // console.log("meshes", meshes)
    //     let carpet = this.scene.getNodeByID("Carpet");
    //         carpet.position.y = Utils.groundY;
    //     let circle = this.scene.getNodeByID("Circle");
    //     circle.setEnabled(false);
    //     let candle = this.scene.getNodeByID("Candle");
    //     candle.setEnabled(false);
    //     this.initCandle();     
    //   }
    // );
  }
  initCandle() {
    //event for set candle
    window.setCandle(1, 0);
  }
  addFireFightingEventListner() {
    this.positionEvent = (e) => {
      this.loaderManager.carManager.setRobotPosition(e.detail.x, e.detail.y, e.detail.z);
    }
    document.addEventListener(Utils.events.setrobotPos_eve, this.positionEvent);

    this.rotationEvent = (e) => {
      this.loaderManager.carManager.setRobotRotation(e.detail.angle);
    }
    document.addEventListener(Utils.events.setrobot_angle, this.rotationEvent);
    this.candleDownEvent = (e) => {
      this.setCandleState(e.detail.id, CANDLE_STATE.state2, e.detail.candleFlame, e.detail.distance);
    }
    document.addEventListener(Utils.events.candleDown, this.candleDownEvent, false);

    this.candleRobotCollisionEvent = (e) => {
      // console.log({"info":"candle collide with robot","candle":e.detail.candleFlame});
      for (let i = 0; i < this.candleState.length; i++) {
        if (this.candleState[i].info.name === e.detail.id && !e.detail.candleFlame) {
          this.candleState[i].isRobotTouch = true;
          break;
        }
      }
      this.setCandleState(e.detail.id, e.detail.candleFlame ? CANDLE_STATE.state1 : CANDLE_STATE.state2, e.detail.candleFlame, e.detail.distance);
    }
    document.addEventListener(Utils.events.candleRobotCollision, this.candleRobotCollisionEvent);

    this.candleBlowOffEvent = (e) => {
      // console.log({"info":e.detail.candleFlame,"distance":e.detail.distance+"mm"});
      for (let i = 0; i < this.candleState.length; i++) {
        if (this.candleState[i].info.name === e.detail.id) {
          this.candleState[i].isRobotTouch = e.detail.collide;
          break;
        }
      }
      this.setCandleState(e.detail.id, CANDLE_STATE.state1, false, e.detail.distance);
      this.ballonObj.setBallonPos(e.detail.position);
    }
    document.addEventListener(Utils.events.candleBlowOff, this.candleBlowOffEvent);

    this.boardDownEvent = (e) => {
      for (let i = 0; i < this.boardState.length; i++) {
        if (this.boardState[i].name === e.detail.name) {
          this.boardState[i].position = "GROUND";
          break;
        }
      }
    }
    document.addEventListener(Utils.events.boardDown, this.boardDownEvent);

    this.boardRobotCollisionEvent = (e) => {
      for (let i = 0; i < this.boardState.length; i++) {
        if (this.boardState[i].name === e.detail.name) {
          this.boardState[i].collideRobot = true;
          break;
        }
      }
    }
    document.addEventListener(Utils.events.boardRobotCollision, this.boardRobotCollisionEvent);

    this.changemotorEvent = (e) => {// console.log({"info":"power change","value":e.detail});
    }
    document.addEventListener(Utils.events.changemotor, this.changemotorEvent);

    let objMaterial = this.worldManager.createObjectMaterial();
    this.setCandle = (e) => {
      console.log("!!! setCandle !!!");
      if (this.scene) {
        for (let i = 0; i < this.flameEffect.length; i++)
          this.flameEffect[i].removeCandlefromScene();
        for (let b = 0; b < this.board.length; b++)
          this.board[b].removeBoardFromScene();

        // let randomMap = Math.floor(Math.random()*4);
        // randomMap=3;
        // let boardConfig  = candlePositionConfig.positionmap[this.mysceneConfig.mapno];
        const mapConfig = candlePositionConfig.positionmap[e.detail.mapno][0];
        this.flameEffect = [];
        this.board = [];
        this.candleState = [];
        this.boardState = [];
        let boardNo = 0;
        for (let i = 0; i < e.detail.candleno; i++) {
          // console.log(this.mysceneConfig.candleno+" 222222 "+this.mysceneConfig.boardno+"   "+i);
          this.flameEffect[i] = new FlameEffectGenerator("candle" + i, new BABYLON.Vector2(mapConfig[i].x, mapConfig[i].z), { height: .7, diameter: .15 }
            , "#12e04c", .5, true, 1, this.worldManager, this.scene, this.loaderManager.carManager.babylonCarBox);
          this.candleState[i] = new CandleInfo(this.flameEffect[i].name);
          const obstacleSize = { width: 1.1, height: 2, depth: .4 };
          if (mapConfig[i].direction.left === 1) {
            this.board[boardNo] = new ObstacleGenerator(i + "board" + boardNo, new BABYLON.Vector2(mapConfig[i].x, mapConfig[i].z + BOARD_DISTANCE), obstacleSize,
              0, "textures/adbanner1.jpg", this.worldManager, this.scene, this.boardEvent, objMaterial);
            this.boardState[boardNo] = new BoardInfo(this.board[boardNo].name);
            boardNo++;
          }
          if (mapConfig[i].direction.right === 1) {
            this.board[boardNo] = new ObstacleGenerator(i + "board" + boardNo, new BABYLON.Vector2(mapConfig[i].x, mapConfig[i].z - BOARD_DISTANCE), obstacleSize,
              0, "textures/adbanner1.jpg", this.worldManager, this.scene, this.boardEvent, objMaterial);
            this.boardState[boardNo] = new BoardInfo(this.board[boardNo].name);
            boardNo++;
          }
          if (mapConfig[i].direction.up === 1) {
            this.board[boardNo] = new ObstacleGenerator(i + "board" + boardNo, new BABYLON.Vector2(mapConfig[i].x - BOARD_DISTANCE, mapConfig[i].z), obstacleSize,
              1.57, "textures/adbanner1.jpg", this.worldManager, this.scene, this.boardEvent, objMaterial);
            this.boardState[boardNo] = new BoardInfo(this.board[boardNo].name);
            boardNo++;
          }
          if (mapConfig[i].direction.down === 1) {
            this.board[boardNo] = new ObstacleGenerator(i + "board" + boardNo, new BABYLON.Vector2(mapConfig[i].x + BOARD_DISTANCE, mapConfig[i].z), obstacleSize,
              1.57, "textures/adbanner1.jpg", this.worldManager, this.scene, this.boardEvent, objMaterial);
            this.boardState[boardNo] = new BoardInfo(this.board[boardNo].name);
            boardNo++;
          }
        }
        const robotpositionConfig = candlePositionConfig.positionmap[e.detail.mapno][1];
        // console.log(robotpositionConfig[0].robotposition);
        if (robotpositionConfig[0].robotposition)
          window.setRobotPosition(robotpositionConfig[0].robotposition.x, robotpositionConfig[0].robotposition.y, robotpositionConfig[0].robotposition.z);
        if (robotpositionConfig[0].robotposition.angle)
          window.setRobotRotation(robotpositionConfig[0].robotposition.angle);
        if (this.loaderManager.carManager !== undefined)
          this.loaderManager.carManager.fanSensor.setCandleForSensor(this.flameEffect);
      }
    }
    //  console.log(setCandle);
    document.addEventListener(Utils.events.setcandle_eve, this.setCandle);
  }
  setCandleState(id, state, flamestate, distance) {
    for (let i = 0; i < this.candleState.length; i++) {
      if (this.candleState[i].info.name === id) {
        this.candleState[i].info.candlesPosition = state;
        this.candleState[i].info.isflameon = flamestate;
        if (!this.candleState[i].isFlameExtinguish && !this.candleState[i].info.isflameon) {
          this.candleState[i].isFlameExtinguish = true;
          this.candleState[i].info.OnFlameOff.IsFlameOffByFan = this.candleState[i].info.candlesPosition === CANDLE_STATE.state1 ? true : false;
          this.candleState[i].info.OnFlameOff.RobotDistanceFromCandle = distance;
          this.candleState[i].info.OnFlameOff.IsRobotTouchingCandle = this.candleState[i].isRobotTouch;
        }
        break;
      }
    }
  }
  getCandleState() {
    let candleState = { info: [] };
    for (let i = 0; i < this.candleState.length; i++) {
      candleState.info.push(this.candleState[i].info);
    }
    return candleState.info;
  }
  getBoardState() {
    let boardState = { info: [] };
    for (let i = 0; i < this.boardState.length; i++) {
      boardState.info.push({ position: this.boardState[i].position, collideRobot: this.boardState[i].collideRobot });
    }
    return boardState.info;
  }
  releaseScene() {
    if(!this.scene)
         return;
    this.removeListner();
    for (let i = 0; i < this.flameEffect.length; i++)
      this.flameEffect[i].removeCandlefromScene();
    this.flameEffect = [];
    for (let b = 0; b < this.board.length; b++)
      this.board[b].removeBoardFromScene();
    this.board = [];
    for (let i = 0; i < this.scene.meshes.length; i++) {
      if (this.scene.meshes[i]) {
        // console.log(this.scene.meshes[i].name);
        if (this.scene.meshes[i])
          this.scene.meshes[i].dispose();
        this.scene.removeMesh(this.scene.meshes[i]);
        console.log(this.scene.meshes[i], "       ", i);
      }
    }
    this.loaderManager.carManager.releaseRobot();
    this.scene.stopAllAnimations();
    this.scene.cleanCachedTextureBuffer();
    this.scene.clearCachedVertexData();
    this.sceneCommon.release();
    this.game.releaseWorld();
  }
  removeListner() {
    document.removeEventListener(Utils.events.setcandle_eve, this.setCandle);
    document.removeEventListener(Utils.events.setrobotPos_eve, this.positionEvent);
    document.removeEventListener(Utils.events.setrobot_angle, this.rotationEvent);

    document.removeEventListener(Utils.events.candleDown, this.candleDownEvent);
    document.removeEventListener(Utils.events.candleRobotCollision, this.candleRobotCollisionEvent);
    document.removeEventListener(Utils.events.candleBlowOff, this.candleBlowOffEvent);
    document.removeEventListener(Utils.events.boardDown, this.boardDownEvent);
    document.removeEventListener(Utils.events.boardRobotCollision, this.boardRobotCollisionEvent);
    document.removeEventListener(Utils.events.changemotor, this.changemotorEvent);
    // this.worldManager.world.removeEventListener("postStep",this.loaderManager.carManager.postlistner);
  }
  getFireFightingEvents() {

    const candleInfo = this.getCandleState();
    const boardInfo = this.getBoardState();

    console.log({ candleInfo: candleInfo, boardInfo: boardInfo });


    //   let candleInfo=[];
    //     if(this.candleState){
    //         for(let i=0;i<this.candleState.length;i++){
    //           console.log(this.candleState[i].info.candlesPosition+"    "+i);
    //           candleInfo.push({candlesPosition:this.candleState[i].info.candlesPosition,isflameon:this.candleState[i].info.isflameon,
    //             isFlameExtinguish:this.candleState[i].isFlameExtinguish,IsFlameOffByFan:this.candleState[i].info.OnFlameOff.IsFlameOffByFan,
    //             robotDistanceFromCandle:this.candleState[i].info.OnFlameOff.RobotDistanceFromCandle,IsRobotTouchingCandle:this.candleState[i].info.OnFlameOff.IsRobotTouchingCandle});
    //         }
    //        return  candleInfo;
    //     }
  }
}