

export const MazeMap = {
  data:
    [[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1],
    [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    ]

}


export const MazeMap2 = {
  data: [
    [{ id: 0, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": true, "s": false, start: false, end: false },
    { id: 1, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": false, "s": false, start: false, end: false },
    { id: 2, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": false, "s": false, start: false, end: false },
    { id: 3, ismilestone: false, color: "#C5C5C5", "e": true, "w": true, "n": false, "s": true, start: false, end: false },
    { id: 4, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": false, "s": false, start: false, end: false },
    { id: 5, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": false, "s": true, start: false, end: false },
    { id: 6, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": false, "s": false, start: false, end: false },
    { id: 7, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": false, "s": true, start: false, end: false }],
    [{ id: 8, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": true, "s": true, start: false, end: false },
    { id: 9, ismilestone: false, color: "#C5C5C5", "e": false, "w": true, "n": true, "s": true, start: false, end: false },
    { id: 10, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 11, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 12, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 13, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 14, ismilestone: false, color: "#C5C5C5", "e": true, "w": true, "n": false, "s": true, start: false, end: false },
    { id: 15, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": true, start: false, end: false }],
    [{ id: 16, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": true, "s": true, start: false, end: false },
    { id: 17, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": true, "s": true, start: false, end: false },
    { id: 18, ismilestone: true, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 19, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": true, start: false, end: false },
    { id: 20, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 21, ismilestone: true, color: "#C5C5C5", "e": false, "w": false, "n": true, "s": false, start: false, end: false },
    { id: 22, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 23, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": true, start: false, end: false }],
    [{ id: 24, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": true, "s": true, start: false, end: false },
    { id: 25, ismilestone: true, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 26, ismilestone: false, color: "#C5C5C5", "e": true, "w": true, "n": false, "s": false, start: false, end: false },
    { id: 27, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 28, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 29, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 30, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": true, start: false, end: false },
    { id: 31, ismilestone: false, color: "#C5C5C5", "e": false, "w": false, "n": false, "s": true, start: false, end: false }],
    [{ id: 32, ismilestone: false, color: "#00ff00", "e": true, "w": false, "n": true, "s": true, start: true, end: false },
    { id: 33, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 34, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 35, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 36, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 37, ismilestone: true, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": false, start: false, end: false },
    { id: 38, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": true, start: false, end: false },
    { id: 39, ismilestone: false, color: "#C5C5C5", "e": true, "w": false, "n": false, "s": true, start: false, end: true }],
  ]
}