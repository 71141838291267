import * as BABYLON from 'babylonjs';
import FireFightingScene from './scene/FireFightingScene';
import MazeScene from  './scene/MazeScene';
import { DemoScene } from './scene/demoscene';
import SceneManager   from './SceneManager';
import { MyRecorder } from './MyRecorder';
let divFps;
export class GameManger {
    constructor(canvasId,_sceneno) {
        this.canvas = document.getElementById(canvasId);
        this.engine= new BABYLON.Engine(this.canvas, true);
        this.engine.enableOfflineSupport=true;
        this.sceneManager = new SceneManager();
        this.sceneManager.setSceneState(_sceneno);
        // this.sceneManager.setSceneState(this.sceneManager.sceneState.firefighting);
        this.worldManager = null;
        this.currentScene = null;
        switch(this.sceneManager.currentSceneState){
            case this.sceneManager.sceneState.firefighting://firefighting;
                this.firefightingScene = new FireFightingScene(this);
                this.firefightingScene.scene.freezeMaterials();
                this.worldManager = this.firefightingScene.worldManager;
                this.currentScene = null;
                this.currentScene = this.firefightingScene;
                break;  
            case this.sceneManager.sceneState.maze://maze
                this.mazescene = new MazeScene(this);
                this.currentScene = null;
                this.currentScene = this.mazescene;
                this.worldManager = this.mazescene.worldManager;
               break;  
            case this.sceneManager.sceneState.demo:
                console.log("!!demo!");
                this.demoScene    = new DemoScene(this);
                this.currentScene = null;
                this.currentScene = this.demoScene;
                this.worldManager = this.demoScene.worldManager;

               break;  
        }
        divFps = document.getElementById("fps");
        window.addEventListener("resize",  ()=> {
            this.resizeBabylonEngine();
        },false);
        window["resizeBabylonEngine"]       =  this.resizeBabylonEngine.bind(this);
        window["startFireFightingScene"]    =  this.startFireFightingScene.bind(this);
        window["restartMazeScene"]          =  this.restartMazeScene.bind(this);
        window["startRenderLoop"]           =  this.startRenderLoop.bind(this);
        window["stopRenderLoop"]            =  this.stopRenderLoop.bind(this);
        window["showFPS"]                   =  this.showFPS.bind(this);
        window["getLoadingFinish"]          =  this.getLoadingFinish.bind(this);
        
        this.startRenderLoop();
        this.myRecorder = new MyRecorder(this.engine,{fps:24,mimeType:"video/webm"});
    }
    resizeBabylonEngine(){
        if(this.engine)
        this.engine.resize();
    }
    startFireFightingScene(){
        if(this.firefightingScene && this.firefightingScene.scene){
            this.firefightingScene.scene.onDisposeObservable.add(()=>{
                console.log("onDisposeObservable");
                this.firefightingScene.scene = undefined;
                this.firefightingScene.scene = null;
                this.firefightingScene = null;
                console.log("!! remove scene!!!");
                this.sceneManager.setSceneState(this.sceneManager.sceneState.firefighting);
                this.firefightingScene = new FireFightingScene(this);
                this.worldManager = this.firefightingScene.worldManager;
                this.startRenderLoop();    
                
            });
            this.engine.stopRenderLoop();
            this.firefightingScene.releaseScene();
            this.firefightingScene.scene.dispose();
            console.log("!!!releaseScene!!!!");
        }
        else{
            this.sceneManager.setSceneState(this.sceneManager.sceneState.firefighting);
            this.worldManager = null;
            this.firefightingScene = new FireFightingScene(this);
            this.firefightingScene.scene.freezeMaterials();
            this.worldManager = this.firefightingScene.worldManager;
        }
    }
    restartMazeScene(){
        if(this.mazescene && this.mazescene.scene){
            this.mazescene.scene.onDisposeObservable.add(()=>{
                console.log("onDisposeObservable");
                this.mazescene.scene = undefined;
                this.mazescene.scene = null;
                this.mazescene = null;
                console.log("!! remove scene!!!");
                this.sceneManager.setSceneState(this.sceneManager.sceneState.maze);
                this.mazescene = new MazeScene(this);
                this.worldManager = this.mazescene.worldManager;
                this.startRenderLoop();    
            });
            this.engine.stopRenderLoop();
            this.mazescene.releaseScene();
            this.mazescene.scene.dispose();
        }
        else{
            this.worldManager = null;
            this.sceneManager.setSceneState(this.sceneManager.sceneState.maze);
            this.mazescene = new MazeScene(this);
            this.worldManager = this.mazescene.worldManager;
        }
    }
    startRenderLoop(){
        if(this.currentScene && this.currentScene.scene){
            this.currentScene.scene.registerBeforeRender(()=>{
                if(this.worldManager !== null){
                    if(this.currentScene.loaderManager.carManager)
                        this.currentScene.loaderManager.carManager.updatewheel();
                    for(let i = 0; i < this.worldManager.boxes.length; i++){
                        this.worldManager.boxes[i].vCube.position = new BABYLON.Vector3(this.worldManager.boxes[i].pCube.position.x,
                                                                    this.worldManager.boxes[i].pCube.position.y,this.worldManager.boxes[i].pCube.position.z);
                        this.worldManager.boxes[i].vCube.rotationQuaternion = new BABYLON.Quaternion(this.worldManager.boxes[i].pCube.quaternion.x,
                            this.worldManager.boxes[i].pCube.quaternion.y,this.worldManager.boxes[i].pCube.quaternion.z,this.worldManager.boxes[i].pCube.quaternion.w);
                    }
                    this.worldManager.updateWorld(this.engine.getFps());
                }
            })
        }
        this.engine.runRenderLoop( ()=> {
        //    switch(this.sceneManager.currentSceneState){
        //         case this.sceneManager.sceneState.firefighting:
        //                 if( this.firefightingScene && this.firefightingScene.scene && this.firefightingScene.loaderManager.loaded)
        //                     this.firefightingScene.scene.render();
        //             break;
        //         case this.sceneManager.sceneState.demo:
        //             if(this.demoScene && this.demoScene.scene && this.demoScene.loaderManager.loaded)
        //                 this.demoScene.scene.render();
        //             break;
        //         case this.sceneManager.sceneState.maze:   
        //                 if(this.mazescene.loaderManager.loaded)
        //                     this.mazescene.scene.render();
        //             break;
        //     }
            if(this.currentScene){
                if(this.currentScene.scene && this.currentScene.loaderManager.loaded)
                    this.currentScene.scene.render();
            }
            divFps.innerHTML = this.engine.getFps().toFixed() + " fps";
        });
    }
    stopRenderLoop(){
        this.engine.stopRenderLoop();
    }
    showFPS(){
        return this.engine.getFps().toFixed();
    }
    releaseWorld(){
        if(this.worldManager){
            this.worldManager.boxes=[];
            for(let i=0;i<this.worldManager.world.bodies.length;i++){
                if(this.worldManager.world.bodies[i]){
                    // console.log(i);
                    this.worldManager.world.remove(this.worldManager.world.bodies[i]);    
                }
            }
            this.worldManager.world=null;
            this.worldManager = null;    
        }
    }
    getLoadingFinish(){
        if(this.firefightingScene && this.firefightingScene.loaderManager)
             return  this.firefightingScene.loaderManager.loaded;
        else
             return false;      
    }
}


