//Tags
export const EffectsTags = { //All environment modes
    flame : "flame",
}

export const EnvironmentModeEnum = { //All environment modes
    standard:"standard",
    grid : "grid",
    line : "line",
}

export const EnvironmentMode = EnvironmentModeEnum.standard; //Current environment mode

//Grid
//Note !!  Square size = majorUnitFrequency * gridRatio = 5 mm !!
export const gridRatio = 0.5;
export const majorUnitFrequency = 10;
export const textHeight = 1;

//LineGround
export const linePoints = [
    new BABYLON.Vector3(10, 0, 0),
    new BABYLON.Vector3(20, 0, 0),
    new BABYLON.Vector3(30, 0, 10),
    new BABYLON.Vector3(30, 0, 20),
    new BABYLON.Vector3(30, 0, 30),
    new BABYLON.Vector3(40, 0, 40),
    new BABYLON.Vector3(30, 0, 40),
    new BABYLON.Vector3(20, 0, 30),
    new BABYLON.Vector3(10, 0, 15),
    new BABYLON.Vector3(-20, 0, 40),
    new BABYLON.Vector3(-30, 0, 30),
    new BABYLON.Vector3(-40, 0, 20),
    new BABYLON.Vector3(-25, 0, 10),
]
export const isClosedLine = true;
export const lineColor = new BABYLON.Color4(0, 1, 1, 1);
export const lineThickness = 1;
export const lineHeight = -.35;


//Scene space
export const groundWidth = 67;
export const groundDepth = 50;
export const backGroundColor = "#99CCFF";

export const numberOfCubes = 10;
export const border = {
    height: 2,
    depth: 1,
}

//Car Manager
export const maxVelocity = 60;
export const maxForce = 200;
export const maxBrakeForce = maxForce*50;
export const robtotBodyMass= 100;
export const weheelBodyMass= 10;
export const GRAVITY=-20;
//sensorsType
export const SensorTypes = { //All environment modes
    distance : "distance",
    color : "color",
}

//RatCast Manager
export const distanceRayLength = 40;
export const distanceRayTimeOut = 100000; //ms

export const surfaceRayLength = 3; //fixed
export const surfaceRayTimeOut = 100000; //ms


//BoxConfig
export const boxConfing = {
    boxPosition : new BABYLON.Vector2(0, 1), //x-z
    height : 2,
    width : 2, //x
    depth : 2, //z
    boxHeight : .5,
    doorPercentage: 20,
}
export const ballsCount = 5;

//Not Used For Now 
export const groundSize = 15;
export const groundOffsetX = 5;
export const groundOffsetZ = 5;
export const yOffset = 0;

export const events={candleDown:"candleDown_eve",boardDown:"boardDown_eve",setcandle_eve:"setcandle_eve",setrobotPos_eve:"setrobotPos_eve",candleRobotCollision:"candleRobotCollision_eve"
,candleBlowOff:"candleBlowOff_eve",boardRobotCollision:"boardRobotCollision_eve",changemotor:"changemotor_eve",setrobot_angle:"setrobot_angle_eve"};
export const scaleFactor=.5;
export const groundY=-.0;


