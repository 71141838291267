import WorldManager from "../WorldManager.js";
import Common from '../Common.js';
import * as CANNON from 'cannon';
import { MazeMap } from "../Map.js";
import { MazeMap2 } from "../Map.js";
import LoaderManager from "../LoaderManager.js";
import MyRobotWrapper from "../Wrapper/MyRobo.js";
import BallonAnim from "../BallonAnim.js";
export default class MazeScene {

    constructor(gameManager) {
        this.game = gameManager;
        this.sceneCommon = new Common(this.game);
        this.scene = this.sceneCommon.createScene("mazescene");
        this.arcCam = this.sceneCommon.createCamera(this.scene);
        this.worldManager = new WorldManager(this.scene, "textures/RoboSenseiNewLogo.png");
        this.loaderManager = new LoaderManager(this);
        this.loaderManager.initializeSceneAssets();
        this.robotInterFace = new MyRobotWrapper();
        this.robotInterFace.setLoaderManager(this.loaderManager);
        this.row = MazeMap2.data[0].length;
        this.col = MazeMap2.data.length;
        this.tileSize = 4;
        this.createGround();
        this.createSingleTile(this.tileSize);
        this.mapTile = [];
        this.mapParent = null;
        console.log(this.row + "    " + this.col);
        this.game.engine.hideLoadingUI();
        // this.generateTile();
        this.mazeInfo = [];
        this.complete = false;
        this.tileCnt = 0;
        window["getMazeInfo"] = this.getMazeInfo.bind(this);
    }
    generateTile() {
        this.tileCnt = 0;
        this.mapParent = new BABYLON.TransformNode("tileparent", this.scene);
        this.mapParent.position.set(0, 0, 0);
        this.tilecompoundBody = new CANNON.Body({ mass: 0 });
        for (let i = 0; i < MazeMap2.data.length; i++) {
            for (let j = 0; j < MazeMap2.data[i].length; j++) {
                //    console.log(MazeMap2.data[i][j]);
                this.mapTile[this.tileCnt] = this.singleTile.clone(i + "maptile" + j);
                this.mapTile[this.tileCnt].position = new BABYLON.Vector3(this.row * 1.75 - j * this.tileSize, -.025, -this.col * 1.5 + i * this.tileSize);
                this.mapTile[this.tileCnt].setEnabled(true);
                this.mapTile[this.tileCnt].setParent(this.mapParent);
                let dv = this.tileSize / 2;
                MazeMap2.data[i][j].id = this.tileCnt;
                this.mazeInfo.push({ "id": MazeMap2.data[i][j].id, "start": false, "end": false, "milestone": false });
                if (MazeMap2.data[i][j].e) {
                    this.mapTile[this.tileCnt]._children[3].setEnabled(true);
                    const boxShape = new CANNON.Box(new CANNON.Vec3(dv, .5, .1));
                    this.tilecompoundBody.addShape(boxShape, new CANNON.Vec3(this.mapTile[this.tileCnt].position.x, this.mapTile[this.tileCnt].position.y, this.mapTile[this.tileCnt].position.z + dv));
                }
                if (MazeMap2.data[i][j].w) {
                    this.mapTile[this.tileCnt]._children[4].setEnabled(true);
                    const boxShape = new CANNON.Box(new CANNON.Vec3(dv, .5, .1));
                    this.tilecompoundBody.addShape(boxShape, new CANNON.Vec3(this.mapTile[this.tileCnt].position.x, this.mapTile[this.tileCnt].position.y, this.mapTile[this.tileCnt].position.z - dv));
                }
                if (MazeMap2.data[i][j].n) {
                    this.mapTile[this.tileCnt]._children[2].setEnabled(true);
                    const boxShape = new CANNON.Box(new CANNON.Vec3(.1, .5, dv));
                    this.tilecompoundBody.addShape(boxShape, new CANNON.Vec3(this.mapTile[this.tileCnt].position.x + dv, this.mapTile[this.tileCnt].position.y, this.mapTile[this.tileCnt].position.z));
                }
                if (MazeMap2.data[i][j].s) {
                    this.mapTile[this.tileCnt]._children[1].setEnabled(true);
                    const boxShape = new CANNON.Box(new CANNON.Vec3(.1, .5, dv));
                    this.tilecompoundBody.addShape(boxShape, new CANNON.Vec3(this.mapTile[this.tileCnt].position.x - dv, this.mapTile[this.tileCnt].position.y, this.mapTile[this.tileCnt].position.z));
                }

                this.mapTile[this.tileCnt]._children[0].material = this.groundTile.material.clone("tilematerial" + this.tileCnt);
                this.mapTile[this.tileCnt]._children[0].material.diffuseColor = new BABYLON.Color3.FromHexString(MazeMap2.data[i][j].color);
                this.mapTile[this.tileCnt]._children[0].material.specularColor = new BABYLON.Color3(0, 0, 0);
                this.mapTile[this.tileCnt]._children[0].material.emissiveColor = new BABYLON.Color3(0, 0, 0);

                if (MazeMap2.data[i][j].start) {
                    // this.mapTile[this.tileCnt]._children[0].material =  this.groundTile.material.clone("startmaterial");
                    // this.mapTile[this.tileCnt]._children[0].material.diffuseColor  = new BABYLON.Color3(0,1,0);
                    // this.mapTile[this.tileCnt]._children[0].material.specularColor = new BABYLON.Color3(0,0,0);   
                    // this.mapTile[this.tileCnt]._children[0].material.emissiveColor = new BABYLON.Color3(0,0,0);
                    // let rx = -this.row-this.tileSize/2;
                    // let ry = this.col-this.tileSize/2;
                    this.loaderManager.carManager.setRobotPosition(this.mapTile[this.tileCnt].position.x, this.mapTile[this.tileCnt].position.y, this.mapTile[this.tileCnt].position.z);
                    this.loaderManager.carManager.setRobotRotation(0);
                    this.mapTile[this.tileCnt]._children[0].actionManager = new BABYLON.ActionManager(this.scene);
                    this.mapTile[this.tileCnt]._children[0].actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction({
                            trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
                            parameter: { mesh: this.loaderManager.carManager.babylonWheelBodies[0]._children[0]._children[1] }
                        },
                            () => {
                                if (this.mazeInfo[MazeMap2.data[i][j].id])
                                    this.mazeInfo[MazeMap2.data[i][j].id].start = true;
                            }
                        )
                    );
                }
                if (MazeMap2.data[i][j].ismilestone) {
                    this.mapTile[this.tileCnt]._children[0].material.diffuseColor = new BABYLON.Color3.FromHexString(random_hex_color_code());
                    this.mapTile[this.tileCnt]._children[0].actionManager = new BABYLON.ActionManager(this.scene);
                    this.mapTile[this.tileCnt]._children[0].actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction({
                            trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
                            parameter: { mesh: this.loaderManager.carManager.babylonWheelBodies[0]._children[0]._children[1] }
                        },
                            () => {
                                if (!this.complete && this.mazeInfo[MazeMap2.data[i][j].id]) {
                                    this.mazeInfo[MazeMap2.data[i][j].id].milestone = true;
                                }
                            }
                        )
                    );
                }
                if (MazeMap2.data[i][j].end) {
                    let rx = -this.row - this.tileSize / 2;
                    let ry = this.col - this.tileSize / 2;
                    const ballonPos = new BABYLON.Vector3(this.mapTile[this.tileCnt].position.x, this.mapTile[this.tileCnt].position.y, this.mapTile[this.tileCnt].position.z);
                    this.mapTile[this.tileCnt]._children[0].material = this.groundTile.material.clone("endmaterial");
                    this.mapTile[this.tileCnt]._children[0].material.diffuseColor = new BABYLON.Color3(1, 0, 0);
                    this.mapTile[this.tileCnt]._children[0].material.specularColor = new BABYLON.Color3(0, 0, 0);
                    this.mapTile[this.tileCnt]._children[0].material.emissiveColor = new BABYLON.Color3(0, 0, 0);
                    this.mapTile[this.tileCnt]._children[0].actionManager = new BABYLON.ActionManager(this.scene);
                    this.mapTile[this.tileCnt]._children[0].actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction({
                            trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
                            parameter: { mesh: this.loaderManager.carManager.babylonWheelBodies[0]._children[0]._children[1] }
                        },
                            () => {
                                if (!this.complete) {
                                    this.mazeInfo[MazeMap2.data[i][j].id].end = true;
                                    this.complete = true;
                                    this.setBallon(ballonPos);
                                }
                            }
                        )
                    );
                }
                this.tileCnt++;
            }
        }
        this.singleTile.setEnabled(false);
        this.tilecompoundBody.position.set(this.mapParent.position.x, 0, this.mapParent.position.z);
        this.worldManager.world.add(this.tilecompoundBody);
        this.worldManager.boxes.push({ pCube: this.tilecompoundBody, vCube: this.mapParent });
        this.arcCam.lockedTarget = this.mapParent;
        // this.arcCam.setTarget(this.mapParent);
        console.log(this.tileCnt);
    }
    createGround() {
        const groundShape = new CANNON.Plane();
        this.groundBody = new CANNON.Body({ mass: 0 });
        this.groundBody.position.set(0, 0, 0);
        this.groundBody.addShape(groundShape);
        this.groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2)
        this.worldManager.world.add(this.groundBody);
        // this.worldManager.boxes.push({pCube:this.groundBody,vCube:this.ground});
    }
    createSingleTile(tilesize) {
        this.singleTile = new BABYLON.TransformNode("singletile", this.scene);
        const tilemat = new BABYLON.StandardMaterial("namematerial", this.scene);
        tilemat.diffuseColor = new BABYLON.Color3(197 / 255, 197 / 255, 197 / 255);
        tilemat.specularColor = new BABYLON.Color3(0, 0, 0);
        tilemat.emissiveColor = new BABYLON.Color3(0, 0, 0);
        this.groundTile = BABYLON.MeshBuilder.CreatePlane("groundplane", { width: tilesize, height: tilesize, sideOrientation: BABYLON.Mesh.FRONTSIDE });
        this.groundTile.material = tilemat;
        this.groundTile.position.set(0, 0, 0);
        this.groundTile.rotation.x = Math.PI / 2;
        this.groundTile.parent = this.singleTile;

        const bordermat = new BABYLON.StandardMaterial("namematerial", this.scene);
        bordermat.diffuseColor = new BABYLON.Color3(44 / 255, 156 / 255, 171 / 255);
        bordermat.specularColor = new BABYLON.Color3(0, 0, 0);
        bordermat.emissiveColor = new BABYLON.Color3(0, 0, 0);

        this.vborderRight = BABYLON.MeshBuilder.CreateBox("vtileRight", { width: .2, height: 1, depth: tilesize }, this.scene);
        this.vborderRight.material = bordermat;
        this.vborderRight.position.set(-tilesize / 2, .5, 0);
        this.vborderRight.parent = this.singleTile;
        this.vborderRight.setEnabled(false);

        this.vborderLeft = this.vborderRight.clone("vtileleft", this.singleTile);
        this.vborderLeft.material = bordermat.clone("vtileleftmat");
        this.vborderLeft.position.set(tilesize / 2, .5, 0);
        this.vborderLeft.setEnabled(false);

        this.hborderDown = BABYLON.MeshBuilder.CreateBox("htileup", { width: tilesize, height: 1, depth: .2 }, this.scene);
        this.hborderDown.material = bordermat.clone("htileupmat");
        this.hborderDown.parent = this.singleTile;
        this.hborderDown.position.set(0, .5, tilesize / 2);
        this.hborderDown.setEnabled(false);

        this.hborderUp = this.hborderDown.clone("htiledown", this.singleTile);
        this.hborderUp.material = bordermat.clone("htileupmat");
        this.hborderUp.position.set(0, .5, -tilesize / 2);
        this.hborderUp.setEnabled(false);
        this.singleTile.position.set(0, 0, 0);
        // console.log(this.singleTile);
    }
    releaseScene() {
        // this.scene.removeTransformNode(this.singleTile);
        // this.singleTile.dispose();
        // this.scene.removeMesh(this.groundTile);
        // this.groundTile.dispose();
        // this.scene.removeMesh(this.vborderLeft);
        // this.vborderLeft.dispose();
        // this.scene.removeMesh(this.vborderRight);
        // this.vborderRight.dispose();
        // this.scene.removeMesh(this.hborderUp);
        // this.hborderUp.dispose();
        // this.scene.removeMesh(this.hborderDown);
        // this.hborderDown.dispose();
        // for(let i=0;i<this.mapTile.length;i++){
        //     for(let j=0;j<this.mapTile[i]._children.length;j++){
        //         this.scene.removeMesh(this.mapTile[i]._children[j]);
        //         this.mapTile[i]._children[j].dispose();
        //     }
        // }
        // this.scene.removeTransformNode(this.mapParent);
        // this.mapParent.dispose();
        if (!this.scene)
            return;
        for (let i = 0; i < this.scene.transformNodes.length; i++) {
            if (this.scene.transformNodes[i]) {
                console.log(this.scene.transformNodes[i].name);
                this.scene.transformNodes[i].dispose();
                console.log(this.scene, " !! release1! ");
                if (this.scene.transformNodes[i])
                    this.scene.removeTransformNode(this.scene.transformNodes[i]);
            }
        }
        for (let i = 0; i < this.scene.meshes.length; i++) {
            if (this.scene.meshes[i]) {
                console.log(this.scene.meshes[i].name);
                this.scene.meshes[i].dispose();
                this.scene.removeMesh(this.scene.meshes[i]);
            }
        }
        // if(this.worldManager){
        //     this.worldManager.world.bodies.forEach(body => {
        //         this.worldManager.world.remove(body);    
        //     });
        // }

        // this.worldManager.world.remove(this.groundBody);
        // this.worldManager.world.remove(this.tilecompoundBody);
        this.loaderManager.carManager.releaseRobot();
        this.game.releaseWorld();
        this.sceneCommon.release();
        this.scene.stopAllAnimations();
        this.scene.cleanCachedTextureBuffer();
        this.scene.clearCachedVertexData();
    }
    setBallon(ballonPos) {
        this.ballonObj = new BallonAnim(this.scene);
        const ballonsphere = BABYLON.MeshBuilder.CreateSphere("ballonsphere", { diameter: .5 }, this.scene); //scene is 
        let ballonmat = new BABYLON.StandardMaterial("ballonMat", this.scene);
        ballonmat.diffuseColor = new BABYLON.Color3(1, 0, 0);
        ballonsphere.material = ballonmat;
        this.ballonObj.setBallonObj(ballonsphere);
        this.ballonObj.setBallonPos(ballonPos);
    }
    getMazeInfo() {
        return this.mazeInfo;
    }
}
function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
}
const random_hex_color_code = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
};